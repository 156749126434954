import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeLast, takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";
import { ProfileService } from "../../profile.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit, OnDestroy{
  destroy$: Subject<boolean> = new Subject<boolean>();
  public notifications = new BehaviorSubject([]);
  public batch: number = 15;
  public lastKey: string = "";
  public finished: boolean = false;

  constructor(private profileService: ProfileService, private errorService: ErrorService,) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getNotifications() {
    if (this.finished) {
      return;
    }
    this.profileService
      .getNotifications(this.lastKey)
      .pipe(takeLast(1), takeUntil(this.destroy$))
      .subscribe(notifications => {
        if (notifications && notifications.length) {
          const lastItem = notifications[notifications.length - 1];
          if (lastItem) {
            this.lastKey = lastItem.uid;
          }
          const currentNotifications = this.notifications.getValue();
          const lastCurrentItem =
            currentNotifications[currentNotifications.length - 1];
          if (lastCurrentItem && lastCurrentItem.uid === this.lastKey) {
            this.finished = true;
            return;
          }
          if (notifications.length < this.batch) {
            this.finished = true;
          }
          this.notifications.next(currentNotifications.concat(notifications));
        } else {
          this.finished = true;
          const currentNotifications = this.notifications.getValue();
          this.notifications.next(currentNotifications);
        }
      }, error => {
        this.errorService.showError(error);
      });
  }

  onScroll() {
    this.getNotifications();
  }
}
