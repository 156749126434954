import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorService } from 'src/app/error/error.service';
import { FileService } from 'src/app/file/file.service';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit, OnDestroy {
  @Input("data") data: string;
  @Input("mime") mime: string;
  @Input("format") format: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  public file: Blob;
  public fileSrc: string;
  public url:string;

  constructor(private fileService: FileService, private errorService: ErrorService) { }

  ngOnInit(): void { 
    if (this.data) {
      this.fileService.getFileSource(this.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(file => {
        this.file = new Blob([file], { type: this.mime });
        this.url = window.URL.createObjectURL(this.file);
      }, error => { this.errorService.showError(error)});
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  downloadFile() {
    window.location.assign(this.url);
  }

}