import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase";

@Injectable({
  providedIn: "root"
})
export class SelectSchoolService {
  constructor(private firestore: AngularFirestore) {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
      } else {
        this.logOut();
      }
    });
  }

  public getSchools() {
    return this.firestore.collection("schools").snapshotChanges();
  }

  public getInstitutions() {
    return this.firestore.collection("institutions").snapshotChanges();
  }

  public getSchoolsByInstitution(id: string) {
    return this.firestore
      .collection("schools", ref => ref.where("uidInstitution", "==", id).orderBy("nickname"))
      .snapshotChanges();
  }
}
