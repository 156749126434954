import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { IUser } from 'src/app/models/user';
import { ProfileService } from 'src/app/profile/profile.service';
import { IEvaluateMe } from '../models/models';

@Component({
  selector: 'app-value-me',
  templateUrl: './value-me.component.html',
  styleUrls: ['./value-me.component.scss']
})
export class ValueMeComponent implements OnInit {
  public user: IUser;
  public isLoading: boolean;
  public evaluationData: IEvaluateMe;

  constructor(private authService: AuthService,     
    private profileService: ProfileService,
    private dialogRef: MatDialogRef<ValueMeComponent>,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.user = this.authService.getUser();
    this.profileService.evaluateMe().subscribe((data) => {
      this.evaluationData = data;
      this.isLoading = false;
    });
  }

  goToCategories() {
    this.router.navigate(["/how-ratings-work"]);
    this.dialogRef.close()
  }

}
