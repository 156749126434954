import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { IChipOption } from "src/app/file/file.types";

@Component({
  selector: "app-checkbox-chips-list",
  templateUrl: "./checkbox-chips-list.component.html",
  styleUrls: ["./checkbox-chips-list.component.scss"]
})
export class CheckboxChipsListComponent implements OnInit, OnChanges{
  @Input() chips: IChipOption[] = [];
  @Input() multipleChoice: boolean = false;
  @Input() leftIcon: string;
  @Input() isEmoji: boolean = false;
  @Input() preSelected: IChipOption[] = [];
  @Output() onSelectionChange: EventEmitter<
    IChipOption[] | IChipOption
  > = new EventEmitter();

  public chipsToDisplay: IChipOption[] = [];
  public allSelected: boolean = false;
  private selectedOptions: IChipOption[] = [];

  constructor() {}

  ngOnInit(): void {
    this.chipsToDisplay = this.chips || [];
    this.initValues();
    this.checkStateAllSelected();
    if (!this.multipleChoice) {
      this.checkOnlyOne();
    }
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes && changes.preSelected){
      this.preSelected = changes.preSelected.currentValue;
      this.initValues();
    }
  }

  initValues() {
    if (this.preSelected && this.preSelected.length) {
      this.chipsToDisplay = this.chipsToDisplay.map(chip => {
        chip.status = false;
        return chip;
      });
      this.preSelected.forEach((select, i) => {
        const index = this.chipsToDisplay.findIndex(e => e.idElement === select.idElement);
        this.changeChipStatus(select, index);
      });
    }
    if (this.multipleChoice && (!this.preSelected || !this.preSelected.length)) {
      this.allSelected = false;
      this.selectAll();
    }
    if (this.preSelected && this.preSelected.length === this.chips.length) {
      this.allSelected = true;
    }
  }

  changeChipStatus(selectedChip: IChipOption, index: number) {
    if(this.chipsToDisplay && this.chipsToDisplay.length) {
      if (this.multipleChoice) {
        this.chipsToDisplay[index].status = !this.chipsToDisplay[index].status;
        this.selectOrRemoveChoise(
          selectedChip,
          this.chipsToDisplay[index].status
        );
      } else {
        this.chipsToDisplay = this.chipsToDisplay.map(chip => {
          chip.status = chip.idElement === selectedChip.idElement;
          return chip;
        });
      }
    }
    this.removeDuplicates();
  }

  removeDuplicates() {
    this.chipsToDisplay = this.chipsToDisplay.filter((thing, index, self) =>
    index === self.findIndex((t) => (
      t.idElement === thing.idElement
    )));
    this.selectedOptions = this.selectedOptions.filter((thing, index, self) =>
    index === self.findIndex((t) => (
      t.idElement === thing.idElement
    )));
  }


  selectOrRemoveChoise(chip: IChipOption, add: boolean) {
    if (add) {
      this.selectedOptions.push(chip);
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        chipElement => chipElement.idElement !== chip.idElement
      );
    }
  }

  onClickChip(selectedChip: IChipOption, index: number) {
    this.changeChipStatus(selectedChip, index);
    if (this.multipleChoice) {
      this.onSelectionChange.emit(this.selectedOptions);
    } else {
      this.onSelectionChange.emit(selectedChip);
    }
    this.checkStateAllSelected();
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.chipsToDisplay = this.chipsToDisplay.map(chip => {
        chip.status = true;
        return chip;
      });
      this.selectedOptions = this.chipsToDisplay;
    } else {
      this.chipsToDisplay = this.chipsToDisplay.map(chip => {
        chip.status = false;
        return chip;
      });
      this.selectedOptions = [];
    }
    this.onSelectionChange.emit(this.selectedOptions);
  }

  checkStateAllSelected() {
    if (this.chipsToDisplay && this.chipsToDisplay[0]) {
      this.allSelected =
        this.chipsToDisplay.filter(chip => chip.status).length ===
        this.chips.length;
    }
  }

  checkOnlyOne() {
    const actives = this.chipsToDisplay.filter(chip => chip.status).length;
    if (actives > 1) {
      this.chipsToDisplay.map((chip, index) => {
        chip.status = index === 0;
      });
    }
  }
}
