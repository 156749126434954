import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "../error/error.service";
import { IVideo } from "../utility/models/models";
import { VideosService } from "../videos/videos.service";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"]
})
export class VideoComponent implements OnInit, OnDestroy {
  public isLoading: boolean = false;
  public currentVideo: IVideo;
  public error: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _route: ActivatedRoute,     
    private router: Router,
    private videosService: VideosService,
    private errorService: ErrorService,
    private title: Title
  ) {
    this.title.setTitle('Video');
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._route.params
    .pipe(takeUntil(this.destroy$))
    .subscribe((params: Params) => {
      if(params && params.uid) {
        this.currentVideo = this.videosService.getCurrentVideo();
        if(!this.currentVideo) {
          this.router.navigate(["/videos"]);
        }
        this.videosService.addView(this.currentVideo.uid)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if(res) {
            this.isLoading = false;
            this.goToMovements(this.currentVideo.duration, res.uidView);
          }
        }, error => { 
          this.error = true;
          this.isLoading = false;
          this.errorService.showError(error,10);
          this.router.navigate(["/videos"]);
        });
      } else {
        this.isLoading = false;
        this.router.navigate(["/videos"]);
      }
    }, (error) => {
      this.error = true;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  goToMovements(duration:number, uid:string) {
    const time = duration.toString().split(".");
    const min = time[0] ? Number.parseInt(time[0], 10) : 0;
    const minInSec = min * 60;
    const sec = time[1] ? Number.parseInt(time[1], 10) : 0;
    const finalTime = (minInSec + sec + 5) * 1000;
    setTimeout(() => { 
      this.videosService.editView(uid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.router.navigate(["/profile/transactions"]);
      }, error => { this.errorService.showError(error)});
    }, finalTime);
  }
}
