import { ThrowStmt } from "@angular/compiler";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { ErrorService } from "src/app/error/error.service";
import { Views } from "src/app/file/file.component";
import { FileService } from "src/app/file/file.service";
import { IUser } from "src/app/models/user";
import { ICommentFile } from "../models/models";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"]
})
export class CommentsComponent implements OnInit, OnChanges, OnDestroy {
  panelOpenState = false;
  @Input("data") dataComments: ICommentFile[];
  @Input("idFile") idFile: string;
  @Input("canAddComments") canAddComments: boolean = false;
  @Output() onPostComment = new EventEmitter<string>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  currentUser: IUser;
  public comment: string = "";

  constructor(private authService: AuthService,     
    private fileService: FileService,
    private errorService: ErrorService) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("dataComments" in changes) {
      this.dataComments = changes.dataComments.currentValue;
      this.comment = "";
    }
  }

  onComment() {
    if (this.comment.length) {
      this.onPostComment.emit(this.comment);
      this.onCancel();
    }
  }

  onCancel() {
    this.comment = "";
  }

  onClickLike(status: boolean, comment:ICommentFile, index:number) {
    if(this.dataComments[index].disliked) {
      this.dataComments[index].dislikes = this.dataComments[index].dislikes - 1;
    }
    this.dataComments[index].disliked = false;
    let reaction = {};
    if(status) {
      this.dataComments[index].liked = true;
      this.dataComments[index].likes = this.dataComments[index].likes + 1;
      reaction = {like:true, dislike:false}
    } else {
      this.dataComments[index].liked = false;
      this.dataComments[index].likes = this.dataComments[index].likes - 1;
      reaction = {like:false, dislike:false}
    }
    this.fileService.newReactionInComment(this.idFile, comment.uid, reaction)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {}, error => { this.errorService.showError(error)});
  }

  onClickDislike(status: boolean, comment:ICommentFile, index:number) {
    if(this.dataComments[index].liked) {
      this.dataComments[index].likes = this.dataComments[index].likes - 1;
    }
    this.dataComments[index].liked = false;
    let reaction = {};
    if(status) {
      this.dataComments[index].disliked = true;
      this.dataComments[index].dislikes = this.dataComments[index].dislikes + 1;
      reaction = {like:false, dislike:true}
    } else {
      this.dataComments[index].disliked = false;
      this.dataComments[index].dislikes = this.dataComments[index].dislikes - 1;
      reaction = {like:false, dislike:false}
    }
    this.fileService.newReactionInComment(this.idFile, comment.uid, reaction)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {}, error => { this.errorService.showError(error)});
  }

}
