import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { host } from "../constant";
import { IUser } from "../models/user";
import { IPackageOption } from "../utility/models/models";

@Injectable({
  providedIn: "root"
})
export class YeahcoinsService {
  constructor(private http: HttpClient) {}

  getUserData(): Observable<IUser> {
    const route = `${host}/me`;
    return this.http.get<IUser>(route);
  }

  getPackageData(): Observable<Array<IPackageOption>> {
    const route = `${host}/packages`;
    return this.http.get<Array<IPackageOption>>(route);
  }

  buyPackage(idPackage:string): Observable<any> {
    if(!idPackage) {
      return;
    }
    const route = `${host}/buy`;
    return this.http.post<any>(route,{idPackage:idPackage});
  }

  validSale(idOrder:string): Observable<any> {
    if(!idOrder) {
      return;
    }
    const route = `${host}/validate-sale/${idOrder}`;
    return this.http.get<any>(route,{});
  }

  isPaymentInProcess(idOrder:string): Observable<any> {
    if(!idOrder) {
      return;
    }
    const route = `${host}/payment-in-process/${idOrder}`;
    return this.http.get<any>(route,{});
  }

}
