import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ISearchElement } from "src/app/search-view/search.types";
import { MatTableDataSource } from "@angular/material/table";
import { IElementFile } from "../models/models";

@Component({
  selector: "app-search-items-list",
  templateUrl: "./search-items-list.component.html",
  styleUrls: ["./search-items-list.component.scss"]
})
export class SearchItemsListComponent implements OnInit, OnChanges {
  dataSource: IElementFile[];
  displayedColumns: string[] = ["name"];

  @Input("searchItems") searchItems: IElementFile[];
  @Output() onClickElement = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.searchItems) {
      this.dataSource = this.searchItems;
    }
  }

  clickElement(id: string) {
    this.onClickElement.emit(id);
  }

  getImage(type: string = "Imagen"): string {
    switch (type) {
      case "Imagen":
      case "Imagenes":
        return "/assets/img/formatIcons/IMG.png";
      case "PDF":
        return "/assets/img/formatIcons/PDF.png";
      case "DOC":
        return "/assets/img/formatIcons/DOC.png";
      case "PP":
        return "/assets/img/formatIcons/PP.png";
      case "EXCEL":
        return "/assets/img/formatIcons/EXCEL.png";
      case "ZIP":
        return "/assets/img/formatIcons/ZIP.png";
    }
  }
}
