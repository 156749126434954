import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ValueMeComponent } from '../utility/value-me/value-me.component';

@Component({
  selector: 'app-how-ratings-work',
  templateUrl: './how-ratings-work.component.html',
  styleUrls: ['./how-ratings-work.component.scss']
})
export class HowRatingsWorkComponent implements OnInit {

  constructor(public dialog: MatDialog, private title: Title) {
    this.title.setTitle('Calificaciones');
  }


  ngOnInit(): void {
  }

  valueMySelf() {
    const config = {panelClass: "value-me"};
    this.dialog.open(ValueMeComponent, config);
  }
}
