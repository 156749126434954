<h2 mat-dialog-title>Mi Categoria</h2>
<mat-dialog-content class="content-dialog">
    <div class="row-text">
        Hola {{user.nickname}}, actualmente tu categoria es 
        <span *ngIf="!evaluationData" class="main-text">{{user.category}}</span>
        <span *ngIf="evaluationData && evaluationData.currentCategory" class="main-text">
            <img alt="category" class="category-icon" src="/assets/img/categories/{{evaluationData.currentCategory.code}}.svg">
            {{evaluationData.currentCategory.title}}
        </span>
    </div>
    <div *ngIf="!isLoading && evaluationData && evaluationData.currentCategory" class="rewards">
        <div class="reward">
            <mat-icon class="reward-icon"> insert_drive_file</mat-icon>
            Cuando subas un archivo, recibiras el <span>{{evaluationData.currentCategory.percentagePerUpload}}</span>% del precio total del archivo en yeahcoins.
        </div>
        <div class="reward">
            <mat-icon class="reward-icon">cloud_download</mat-icon>
            Cuando tus archivos reciban descargas, recibiras el <span>{{evaluationData.currentCategory.percentagePerDownload}}</span>% del precio total del archivo en yeahcoins.
        </div>
    </div>
    <div *ngIf="!isLoading && evaluationData && evaluationData.nextCategory" class="next-category">
        <div class="row-text">
            Tu siguiente categoria es 
            <span class="main-text">
                <img alt="category" class="category-icon" src="/assets/img/categories/{{evaluationData.nextCategory.code}}.svg">
                {{evaluationData.nextCategory.title}}
            </span>
            , con la cual tendrás
        </div>
    </div>
    <div *ngIf="!isLoading && evaluationData && evaluationData.nextCategory" class="rewards">
        <div class="reward">
            <mat-icon class="reward-icon"> attach_money</mat-icon>
            Precios más altos en tus archivos.
        </div>
        <div class="reward">
            <mat-icon class="reward-icon"> insert_drive_file</mat-icon>
            Cuando subas un archivo, recibiras el <span>{{evaluationData.nextCategory.percentagePerUpload}}</span>% del precio total del archivo en yeahcoins.
        </div>
        <div class="reward">
            <mat-icon class="reward-icon">cloud_download</mat-icon>
            Cuando tus archivos reciban descargas, recibiras el <span>{{evaluationData.nextCategory.percentagePerDownload}}</span>% del precio total del archivo en yeahcoins.
        </div>
    </div>
    <div *ngIf="!isLoading && evaluationData && evaluationData.nextCategory" class="how-to-get">
        <div class="row-text">
            Para subir a la categoria
            <span class="main-text">
                <img alt="category" class="category-icon" src="/assets/img/categories/{{evaluationData.nextCategory.code}}.svg">
                {{evaluationData.nextCategory.title}}
            </span>
            necesitas:
        </div>
        <table>
            <tr><td colspan="2">Minimo {{evaluationData.evaluation.requiredScore}} en calificación de estrellas.</td></tr>
            <tr>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesScore, 'unreached': !evaluationData.evaluation.compliesScore}">
                    <mat-icon *ngIf="evaluationData.evaluation.compliesScore">check_circle</mat-icon> 
                    <mat-icon *ngIf="!evaluationData.evaluation.compliesScore">dangerous</mat-icon>
                </td>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesScore, 'unreached': !evaluationData.evaluation.compliesScore}">
                    <span *ngIf="evaluationData.evaluation.currentScore">Actualmente tienes {{evaluationData.evaluation.currentScore}} de calificación estrella.</span>
                    <span *ngIf="!evaluationData.evaluation.currentScore">Actualmente no tienes calificación estrella, sube algunos archivos para que puedan calificarte.</span>
                </td>
            </tr>
            <tr><td colspan="2">Minimo {{evaluationData.evaluation.requiredUploads}} archivos subidos.</td></tr>
            <tr>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesUploads, 'unreached': !evaluationData.evaluation.compliesUploads}">
                    <mat-icon *ngIf="evaluationData.evaluation.compliesUploads">check_circle</mat-icon> 
                    <mat-icon *ngIf="!evaluationData.evaluation.compliesUploads">dangerous</mat-icon> 
                </td>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesUploads, 'unreached': !evaluationData.evaluation.compliesUploads}">
                    Actualmente has subido {{evaluationData.evaluation.currentUploads}} archivos.
                </td>
            </tr>
            <tr><td colspan="2">Minimo {{evaluationData.evaluation.requiredDownloads}} descagas a tus archivos.</td></tr>
            <tr>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesDownloads, 'unreached': !evaluationData.evaluation.compliesDownloads}">
                    <mat-icon *ngIf="evaluationData.evaluation.compliesDownloads">check_circle</mat-icon> 
                    <mat-icon *ngIf="!evaluationData.evaluation.compliesDownloads">dangerous</mat-icon> 
                </td>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesDownloads, 'unreached': !evaluationData.evaluation.compliesDownloads}">
                    Actualmente tienes {{evaluationData.evaluation.currentDownloads}} descargas.
                </td>
            </tr>
            <tr><td colspan="2">Al menos {{evaluationData.evaluation.requiredMonths}} meses en yeahnotes.</td></tr>
            <tr>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesMonths, 'unreached': !evaluationData.evaluation.compliesMonths}">
                    <mat-icon *ngIf="evaluationData.evaluation.compliesMonths">check_circle</mat-icon> 
                    <mat-icon *ngIf="!evaluationData.evaluation.compliesMonths">dangerous</mat-icon> 
                </td>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesMonths, 'unreached': !evaluationData.evaluation.compliesMonths}">
                   Actualmente tienes {{evaluationData.evaluation.currentMonths}} meses en yeahnotes.
                </td>
            </tr>
            <tr><td colspan="2">Lo recomendable es no tener ningun reporte, pero como sabemos que puede haber usuarios muy exigentes, para la siguiente categoria aceptamos un máximo de {{evaluationData.evaluation.reportsAllowed}} reportes a cualquiera de tus archivos.</td></tr>
            <tr>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesReports, 'unreached': !evaluationData.evaluation.compliesReports}">
                    <mat-icon *ngIf="evaluationData.evaluation.compliesReports">check_circle</mat-icon> 
                    <mat-icon *ngIf="!evaluationData.evaluation.compliesReports">dangerous</mat-icon> 
                </td>
                <td [ngClass]="{'reached': evaluationData.evaluation.compliesReports, 'unreached': !evaluationData.evaluation.compliesReports}">
                    Actualmente tienes {{evaluationData.evaluation.currentReports}} reportes.
                </td>
            </tr>
            <tr><td class="last-td" colspan="2">La próxima recategorización se realizara en <span class="highlight">{{evaluationData.evaluation.daysToNextCutoffDate}}</span> dias. <br></td></tr>
        </table>
        <div *ngIf="evaluationData.evaluation" class="next-period">
            <span (click)="goToCategories()" class="highlight button-categories">Conocer más sobre las categorias</span>
        </div>
    </div>
    <app-loader *ngIf="isLoading" [width]="'auto'" [height]="'70px'" [color]="'green'"></app-loader>

</mat-dialog-content>