<div class="profile-header-main-container">
    <div class="container">
        <div class="m-version">
            <div class="hedaer-data">
                <div class="settings-button-c">
                    <button (click)="showSettings()" class="settings-button">
                        <mat-icon>settings</mat-icon>
                    </button>
                </div>
                <div class="data-profile">
                    <div class="img-profile">{{emoji}} </div>
                    <div class="data-element bold">{{currentUser.nickname}}</div>
                    <div class="data-element">{{currentUser.code}}</div>
                </div>
                <div class="current-yc">
                    
                    <div class="coin" routerLink="/yeahcoins">
                        <img alt="yeahcoins" class="yeahcoin-icon" src="/assets/img/yeahcoin.svg"><span>{{currentUser.yeahcoins}}</span>
                    </div>
                    <div class="category" (click)="goToCategory()">
                        <img alt="category" class="category-icon" src="/assets/img/categories/{{currentUser.category}}.svg"><span>{{currentUser.category}}</span>
                    </div>
                </div>
                <div class="currentTab">
                    <span *ngIf="currentView === 'files'">Archivos</span>
                    <span *ngIf="currentView === 'downloads'">Descargas</span>
                    <span *ngIf="currentView === 'transactions'">Movimientos</span>
                    <span *ngIf="currentView === 'notifications'">Notificaciones</span>
                </div>
            </div>
            <div class="menu" [ngClass]="{'fixeMenu': navFixed }">
                <div class="options">
                    <div class="option" [ngClass]="{'selected': currentView === 'files'}" (click)="changeView('files')">
                        <mat-icon>upload_file</mat-icon>
                    </div>
                    <div class="option" [ngClass]="{'selected': currentView === 'downloads'}" (click)="changeView('downloads')">
                        <mat-icon>sim_card_download</mat-icon>
                    </div>
                    <div class="option" [ngClass]="{'selected': currentView === 'transactions'}" (click)="changeView('transactions')">
                        <mat-icon>sync_alt</mat-icon>
                    </div>
                    <div class="option" [ngClass]="{'selected': currentView === 'notifications'}" (click)="changeView('notifications')">
                        <mat-icon>notifications</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-version">
            <div class="d-container">
                <div class="header-container-d" [ngClass]="{'ipnBG': identity === 'IPN', 'unamBG2': identity === 'UNAM'}">
                    <div class="menu-tab">
                        <div class="tab-element mat-icon-container" [ngClass]="{'selected-d': currentView === 'files'}" routerLink="/profile/files" (click)="changeView('files')">
                            <mat-icon>upload_file</mat-icon> Archivos
                        </div>
                        <div class="tab-element mat-icon-container" [ngClass]="{'selected-d': currentView === 'downloads'}" routerLink="/profile/downloads" (click)="changeView('downloads')">
                            <mat-icon>sim_card_download</mat-icon> Descargas
                        </div>
                        <div class="tab-element mat-icon-container" [ngClass]="{'selected-d': currentView === 'transactions'}" routerLink="/profile/transactions" (click)="changeView('transactions')">
                            <mat-icon>sync_alt</mat-icon> Transacciones
                        </div>
                        <div class="tab-element mat-icon-container" [ngClass]="{'selected-d': currentView === 'notifications'}" routerLink="/profile/notifications" (click)="changeView('notifications')">
                            <mat-icon>notifications</mat-icon> Notificaciones
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>