<div class="container-action-icon" *ngIf="direction === 'vertical'">
    <div class="button-renderer-icon" (click)="clickButton()" [ngClass]="{'active-icon': status}">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
    </div>
    <div *ngIf="label" class="button-renderer-text" [ngClass]="{'active-icon': status}">{{label}}</div>
</div>
<div class="container-action-icon-h" *ngIf="direction === 'horizontal'">
    <div class="button-renderer-icon" (click)="clickButton()" [ngClass]="{'active-icon': status}">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
    </div>
    <div *ngIf="label" class="button-renderer-text" [ngClass]="{'active-icon': status}">{{label}}</div>
</div>