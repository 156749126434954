import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-conditions",
  templateUrl: "./conditions.component.html",
  styleUrls: ["./conditions.component.scss"]
})
export class ConditionsComponent implements OnInit {
  
  constructor(private title: Title) {
    this.title.setTitle('Terminos y Condiciones');
  }

  ngOnInit(): void {}
}
