<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="isLoading">
    <div *ngIf="currentVideo && !isLoading && !error" class="video-container">
        <div class="video-player">
            <div class="background-black">
                <div class="video">
                    <iframe width="560" height="315" fs="0" [src]="currentVideo.urlVideo | safe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="video-properties">
            <div class="container-data">
                {{currentVideo.textNotification}}
            </div>
        </div>
    </div>
    <div *ngIf="error" class="error">
        El video no esta disponibles
    </div>
</app-internal-component>