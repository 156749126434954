import { Injectable } from "@angular/core";
import { IChipOption, IOptionCatalog } from "./file.types";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { HttpClient } from "@angular/common/http";
import { EMPTY, Observable, of } from "rxjs";
import { tap, catchError, shareReplay } from "rxjs/operators";
import { host } from "../constant";
import { v4 as uuidv4 } from "uuid";
import { IReportOption } from "../utility/models/models";

@Injectable({
  providedIn: "root"
})
export class FileService {
  private host: string = host;
  public cacheFileTypes;

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    public afStore: AngularFirestore
  ) {}

  getFileTypes(): Observable<any> {
    if (this.cacheFileTypes) {
      return this.cacheFileTypes;
    }
    return (this.cacheFileTypes = this.firestore
      .collection("fileTypes")
      .snapshotChanges()
      .pipe(
        shareReplay(1),
        catchError(err => {
          delete this.cacheFileTypes;
          return EMPTY;
        })
      ));
  }

  getFileFormats(): IOptionCatalog[] {
    const options = [
      { idElement: "PDF", label: "PDF" },
      { idElement: "DOC", label: "Word" },
      { idElement: "PP", label: "Power Point" },
      { idElement: "EXCEL", label: "Excel" },
      { idElement: "ZIP", label: "Zip" },
      { idElement: "Imagen", label: "Imagen" }
    ];
    return options;
  }

  getOrderOptions(): IOptionCatalog[] {
    const options = [
      {
        idElement: "1",
        label: "Titulo: Ascendente",
        orderByField: "title",
        orderByDir: "asc"
      },
      {
        idElement: "2",
        label: "Titulo: Descendente",
        orderByField: "title",
        orderByDir: "desc"
      },
      {
        idElement: "3",
        label: "Lo Más Descargado",
        orderByField: "downloadsNumber",
        orderByDir: "desc"
      },
      {
        idElement: "4",
        label: "Lo Más Nuevo",
        orderByField: "timestamp",
        orderByDir: "desc"
      }
    ];
    return options;
  }

  getFileRatings(): IOptionCatalog[] {
    const options = [
      { idElement: "1", label: "1" },
      { idElement: "2", label: "2" },
      { idElement: "3", label: "3" },
      { idElement: "4", label: "4" },
      { idElement: "5", label: "5" }
    ];
    return options;
  }

  getTeachers(): IOptionCatalog[] {
    const options = [
      { idElement: "1", label: "Fernando Aguilar Sanchez" },
      { idElement: "2", label: "Alberto Jesus Alcantara Mendez" },
      { idElement: "3", label: "Juan Jesus Alcaraz Torres" },
      { idElement: "4", label: "Mario Aldape Perez" },
      { idElement: "5", label: "Eduardo Alfaro Miranda" },
      { idElement: "6", label: "Rocio Almazan Farfan" },
      { idElement: "7", label: "Maribel Aragon Garcia" },
      { idElement: "8", label: "Jaqueline Arzate Gordillo" },
      { idElement: "9", label: "Roberto Avalos Hernandez" },
      { idElement: "10", label: "Marco Antonio Barranco Jimenez" },
      { idElement: "11", label: "Ricardo Barron Hernandez" },
      { idElement: "12", label: "Sandra Ivette Bautista Rosales" }
    ];
    return options;
  }

  getSubjects(): IOptionCatalog[] {
    const options = [
      { idElement: "1", label: "Analisis Vectorial" },
      { idElement: "2", label: "Calculo" },
      { idElement: "3", label: "Matematicas Discretas" },
      { idElement: "4", label: "Algoritmia y Programacion Estructurada" },
      { idElement: "5", label: "Fisica" },
      { idElement: "6", label: "Ingenieria, Etica y Sociedad" },
      { idElement: "7", label: "Ecuaciones Diferenciales" },
      { idElement: "8", label: "Algebra Lineal" },
      { idElement: "9", label: "Calculo Aplicado" },
      { idElement: "10", label: "Estructura de Datos" },
      { idElement: "11", label: "Comunicacion Oral y Escrita" },
      { idElement: "12", label: "Analisis Fundamental de Circuitos" }
    ];
    return options;
  }

  getTeacher(): IOptionCatalog[] {
    const options = [
      { idElement: "1", label: "Analisis Vectorial" },
      { idElement: "2", label: "Calculo" },
      { idElement: "3", label: "Matematicas Discretas" },
      { idElement: "4", label: "Algoritmia y Programacion Estructurada" },
      { idElement: "5", label: "Fisica" },
      { idElement: "6", label: "Ingenieria, Etica y Sociedad" },
      { idElement: "7", label: "Ecuaciones Diferenciales" },
      { idElement: "8", label: "Algebra Lineal" },
      { idElement: "9", label: "Calculo Aplicado" },
      { idElement: "10", label: "Estructura de Datos" },
      { idElement: "11", label: "Comunicacion Oral y Escrita" },
      { idElement: "12", label: "Analisis Fundamental de Circuitos" }
    ];
    return options;
  }

  getSchools(): IOptionCatalog[] {
    const options = [
      { idElement: "1", label: "CECyT 1: Gonzalo Vasquez Vela" },
      { idElement: "2", label: "CECyT 9: Juan de Dios Bátiz" },
      { idElement: "3", label: "ESCOM: Escuela Superior de Cómputo" },
      {
        idElement: "4",
        label: "FES Acatlán: Facultad de Estudios Superiores Acatlán"
      },
      {
        idElement: "5",
        label: "CCH Oriente: Colegio de Ciencias y Humanidades Oriente"
      },
      {
        idElement: "6",
        label: "ESEO: Escuela Superior de Enfermería y Obstetricia"
      },
      {
        idElement: "7",
        label:
          "ESIME: Escuela Superior de Ingeniería Mecánica y Eléctrica Culhuacan"
      },
      {
        idElement: "8",
        label:
          "ESIQIE: Escuela Superior de Ingeniería Química e Industrias Extractivas"
      },
      {
        idElement: "9",
        label: "CUCBA: Centro Universitario de Ciencias Exactas e Ingenierías"
      },
      {
        idElement: "10",
        label: "CUCS: Centro Universitario de Ciencias de la Salud"
      },
      {
        idElement: "11",
        label: "CUCSH: Centro Universitario de Ciencias Sociales y Humanidades"
      },
      { idElement: "12", label: "Bachillerato Tecnológico Agropecuario" }
    ];
    return options;
  }

  getFileSource(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: "blob" });
  }

  newExchange(idFile: string): Observable<any> {
    const route = `${host}/exchanges`;
    return this.http.post(route, { uidFile: idFile });
  }

  newComment(idFile: string, comment: string): Observable<any> {
    const route = `${host}/comments`;
    return this.http.post(route, { uidFile: idFile, comment });
  }

  newReaction(idFile: string, reaction:any): Observable<any> {
    const route = `${host}/reactions`;
    return this.http.put(route, {...{ uidFile: idFile }, ...reaction});
  }

  newReactionInComment(idFile: string, idComment:string, reaction:any): Observable<any> {
    const route = `${host}/comments/${idComment}/reactions`;
    return this.http.put(route, {...{ uidFile: idFile }, ...reaction});
  }

  getOptionReports(): Observable<Array<IReportOption>> {
    const route = `${host}/option-reports`;
    return this.http.get<Array<IReportOption>>(route);
  }

  addReport(uid: string, code:string, message?:string, path?:string): Observable<any> {
    const route = `${host}/report`;
    return this.http.post<any>(route, {"uidFile":uid, "code":code, "message":message, "path":path});
  }

  addRate(uid: string, star:number): Observable<any> {
    const route = `${host}/rating`;
    return this.http.post<any>(route, {"uidFile":uid, "star":star}); 
  }

  deleteFile(uid: string): Observable<any> {
    const route = `${host}/files/${uid}`;
    return this.http.delete<any>(route); 
  }
}
