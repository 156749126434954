<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="isLoading">
    <div class="file-container">
        <div *ngIf="view === 'mobile'" class="navbar-file">
            <div class="option-navbar-file" [ngClass]="{'active-option-navbar-file': !showingFile}" (click)="changeView('properties')">
                Propiedades
            </div>
            <div class="option-navbar-file" [ngClass]="{'active-option-navbar-file': showingFile}" (click)="changeView('file')">
                Archivo
            </div>
        </div>
        <div *ngIf="!showingFile && dataFile" class="file-container-properties">
            <div class="left-part">
                <div class="summary-container">
                    <p class="file-title">{{dataFile.title | titlecase}} </p>
                    <div class="file-data-container">
                        <div class="file-data">
                            <p class="file-data-text"><img class="format-img" src="{{getImage(dataFile.format)}}" alt="">
                                <span class="bold">Materia: </span>{{dataFile.subject.name}} <br/>
                                <span class="bold">Profesor: </span>{{dataFile.teacher.name}} <br/>
                                <span class="bold">Formato: </span>{{dataFile.type.name}} <br/>
                                <span class="bold">Precio: </span>{{dataFile.price}} yeahcoins <br/>
                                <span class="bold">Descripción: </span>El archivo fue subido <span class="lowercase">{{dataFile.timestamp}}.</span> <span class="capitalize"> {{dataFile.description}}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="action-container" *ngIf="!dataFile.obtained && !dataFile.owner">
                    <button (click)="openBottomSheet()" class="button-action">Obtener por <img class="yeahcoin-ico" src="/assets/img/yeahcoin.svg" alt=""> {{dataFile.price}} yeahcoins</button>
                </div>
                <div class="remove-action-container" *ngIf="dataFile.owner && dataFile.downloadsNumber === 0">
                    <button (click)="removeFile()" class="button-action remove-action">
                        <mat-icon>delete</mat-icon>
                        Eliminar Archivo
                    </button>
                </div>
                <div class="icons-container">
                    <div class="button-renderer">
                        <app-action-icon [icon]="'thumb_up'" class="pointer" [label]="totalLikes.toString()" [clickable]="dataFile.obtained" (onStatusChange)="onClickLike($event)" [restrictionMessage]="setRestrictionMessage('like')" [status]="likeFile"></app-action-icon>
                    </div>
                    <div class="button-renderer">
                        <app-action-icon [icon]="'thumb_down'" class="pointer" [label]="totalDislikes.toString()" [clickable]="dataFile.obtained" (onStatusChange)="onClickDislike($event)" [restrictionMessage]="setRestrictionMessage('like')" [status]="dislikeFile"></app-action-icon>
                    </div>
                    <div class="button-renderer">
                        <app-action-icon [icon]="'sim_card_download'" [label]="dataFile.downloadsNumber.toString()" [clickable]="false"></app-action-icon>
                    </div>
                    <div class="button-renderer">
                        <app-action-icon [icon]="'star_half'" [status]="rated" (click)="onClickRate()" class="pointer" [label]="'Calificar'" [clickable]="false" [restrictionMessage]="setRestrictionMessage('score')"></app-action-icon>
                    </div>
                    <div class="button-renderer">
                        <app-action-icon [icon]="'flag'" [status]="reported" (click)="onClickReport()" class="pointer" [label]="'Denunciar'" [clickable]="false" [restrictionMessage]="setRestrictionMessage('report')"></app-action-icon>
                    </div>
                </div>
                <div class="ratings-data-container">
                    <app-histogram [data]="dataFile.ratings"></app-histogram>
                </div>
                <div class="author-data-container">
                    <app-author [data]="dataFile.author"></app-author>
                </div>
                <div *ngIf="dataFile.comments" class="comments-container">
                    <app-comments [data]="dataFile.comments" [idFile]="dataFile.uid" (onPostComment)="onCommnent($event)" [canAddComments]="dataFile.obtained || dataFile.owner"></app-comments>
                </div>
                <div *ngIf="currentAd" class="commercial">
                    <a [href]="currentAd.link" target="_blank">
                        <img [src]="currentAd.img" alt="promo"/>
                    </a>
                </div>
            </div>

            <div *ngIf="view === 'desktop' && (dataFile.obtained || dataFile.owner)" class="file-body">
                <app-img-gallery *ngIf="dataFile.format === 'Imagen' || dataFile.format === 'Imagenes'" [data]="dataFile.urls ? dataFile.urls : dataFile.url"></app-img-gallery>
                <app-pdf-viewer *ngIf="dataFile.format === 'PDF'" [data]="dataFile.url"></app-pdf-viewer>
                <app-file-viewer *ngIf="dataFile.format !== 'PDF' && dataFile.format !== 'Imagen' && dataFile.format !== 'Imagenes'" [data]="dataFile.url" [format]="dataFile.format"  [mime]="dataFile.mimeValue"></app-file-viewer>
            </div>
            <div *ngIf="view === 'desktop' && !dataFile.obtained && !dataFile.owner" class="file-body">
                <app-to-buy-viewer (onClickObtain)="openBottomSheet()"></app-to-buy-viewer>
            </div>
        </div>
        <div *ngIf="showingFile && (dataFile.obtained || dataFile.owner)" class="file-container-file">
            <app-img-gallery *ngIf="dataFile.format === 'Imagen' || dataFile.format === 'Imagenes'" [data]="dataFile.urls ? dataFile.urls : dataFile.url"></app-img-gallery>
            <app-pdf-viewer *ngIf="dataFile.format === 'PDF'" [data]="dataFile.url"></app-pdf-viewer>
            <app-file-viewer *ngIf="dataFile.format !== 'PDF' && dataFile.format !== 'Imagen' && dataFile.format !== 'Imagenes'" [data]="dataFile.url" [format]="dataFile.format" [mime]="dataFile.mimeValue"></app-file-viewer>
        </div>
        <div *ngIf="showingFile && !dataFile.obtained && !dataFile.owner" class="file-container-file">
            <app-to-buy-viewer (onClickObtain)="openBottomSheet()"></app-to-buy-viewer>
        </div>
    </div>
</app-internal-component>