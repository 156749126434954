<div class="item" *ngFor="let item of notifications | async">
    <div class="body-item">
        <div class="left-part">
            <p>{{item.description}}</p>
            <p class="date">{{item.timestamp}}</p>
        </div>
    </div>
</div>
<div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()"></div>
<div *ngIf="!finished" class="item noMore">
    <app-loader [width]="'auto'" [height]="'40px'" [color]="'green'"></app-loader>
</div>
<div class="item noMore" *ngIf="finished">
    <div class="body-item">
        No hay más notificaciones por ahora.
    </div>
</div>