import { Component, OnInit, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchService } from "src/app/search-view/search.service";
import { AuthService } from "src/app/auth/auth.service";
import { ModalUpFileComponent } from "../modal-up-file/modal-up-file.component";
import { IUser } from "src/app/models/user";
import { FiltersService } from "../file-filters/filters.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";
import { MessagingService } from "../messaging/messaging.service";
import { NgSelectComponent } from "@ng-select/ng-select";

export interface ITest {
  id: string;
  name: string;
}

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Output() onShowFilters = new EventEmitter<boolean>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  searching: boolean = false;
  validValue: boolean = false;
  isShowFilters: boolean = false;
  idSelectedOption: string;
  searchParameterLabel: string;
  selectedOption: any;
  currentUser: IUser;
  currentEmoji: string;
  preValue: any;
  public blockActions: boolean = false;
  options: ITest[] = [];
  wantToChangeRoute: boolean = false;
  currentRouter: string;
  optionHash = {};

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private filtersService: FiltersService,
    private errorService: ErrorService,
    private messagingService: MessagingService,
    private searchService: SearchService
  ) {
    this.router.events.subscribe((event) => {
      const parameter = event as any;
      if(parameter && parameter.url) {
        this.currentRouter = parameter.url;
      }
    });
  }

  ngOnInit(): void {
    this.messagingService.requestPerm();
    this.messagingService.receiveMessage();
    this.filtersService.loadFirstSavedSearchOptions();
    this.currentUser = this.authService.getUser();
    this.currentEmoji = this.currentUser.emoji;
    this.searching = this.validValue;
    this.authService.onSetUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(user => {
      if (user.emoji) {
        this.currentEmoji = user.emoji;
      }
      this.messagingService.requestPerm();
      this.messagingService.receiveMessage();
    }, error => { this.errorService.showError(error)});

    this.filtersService.onFiltersUpdate()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      const currentFilters = this.authService.getFilterPreferences();
      if(currentFilters && currentFilters.origin != 'nav-bar') {
        this.selectedOption = undefined;
        this.searchParameterLabel = undefined;
        this.validValue = false;
        this.searching = false;
      }
    });

    this.route.url
    .pipe(takeUntil(this.destroy$))
    .subscribe(url => {
      this.blockActions = url && url[0] && url[0].path === "selectSchool";
    }, error => { this.errorService.showError(error)});
    this.options = this.filtersService.getSearchOptions() || [];
    this.filtersService.onSavedSearchOptionsChange().subscribe(change => {
      if (change && change.action) {
        switch (change.action) {
          case "add":
            const options = [...this.options, ...change.items];
            this.options = options.filter((thing, index, self) =>
              index === self.findIndex((t) => (  t.id === thing.id ))
            )
            break;
          case "replace":
            this.options = change.itrems ? change.itrems : [];
            break;
          default:
            break;
        }
      }
    });
    this.selectedOption = this.filtersService.getSelectedFilterOption()
      ? this.filtersService.getSelectedFilterOption()
      : null;
    if (this.selectedOption) {
      this.searchParameterLabel = this.selectedOption.label
        ? this.selectedOption.label
        : this.selectedOption;
      this.searching = true;
      this.validValue = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if(this.currentRouter && this.currentRouter !== '/searchView') {
      this.filtersService.setSelectedFilterOption(undefined);
      this.filtersService.matchFiltersAndPreferences();
    }
  }

  setChangeView() {
    event.stopPropagation();
    window.scrollTo(0, 0);
    if (!this.selectedOption) {
      this.searching = !this.searching;
      this.filtersService.setSelectedFilterOption(null);
      this.filtersService.searchByNavbar();
    } else {
      this.searching = true;
      this.validValue = true;
      this.searchParameterLabel = this.selectedOption.label
        ? this.selectedOption.label
        : this.selectedOption;
    }
  }

  newSearch() {
    event.stopPropagation();
    window.scrollTo(0, 0);
    this.searching = true;
    this.validValue = false;
  }

  showFilters(event: any) {
    event.stopPropagation();
    window.scrollTo(0, 0);
    this.isShowFilters = !this.isShowFilters;
    this.onShowFilters.emit(this.isShowFilters);
  }

  filter() {
    event.stopPropagation();
    window.scrollTo(0, 0);
    this.isShowFilters = false;
    this.router.navigate(["/searchView"]);
  }

  searchElements() {
    this.isShowFilters = false;
    this.onShowFilters.emit(false);
    this.router.navigate(["/searchView"]);
  }

  closeFilters() {
    this.isShowFilters = false;
    this.onShowFilters.emit(false);
  }

  logout() {
    this.authService.logOut();
  }

  openFileDialog() {
    const config = {
      height: "100%",
      width: "100%",
      panelClass: "upload-file-modal"
    };
    const dialogRef = this.dialog.open(ModalUpFileComponent, config);
    dialogRef.afterClosed()
    .pipe(takeUntil(this.destroy$))
    .subscribe(result => {
      if (result && result.uid) {
        this.router.navigate(["/file/" + result.uid]);
      }
    }, error => { this.errorService.showError(error)});
  }

  onOptionSelected(selected: any) {
    if(!this.wantToChangeRoute) {
      if(selected === undefined && this.preValue && this.preValue.id && this.preValue.type === 'school') {
        // 
      } else {
        this.filtersService.searchByNavbar(selected);
      }
      this.preValue = selected;
      this.searching = true;
      this.validValue = true;
      this.searchParameterLabel = selected && selected.label ? selected.label : selected;
      this.router.navigate(["/searchView"]);
    }
  }

}
