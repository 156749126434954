import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { AuthService } from "../auth/auth.service";
import { IUser } from "../models/user";
import { emailsAccepted, RecoverPasswordValidatorsMessages } from "./login.validations";
import {
  LoginValidators,
  LoginValidatorsMessages,
  SignUpValidators,
  SignUpValidatorsMessages,
  RecoverPasswordValidators
} from "./login.validations";
import { DialogService } from "../utility/dialog/dialog.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy{

  destroy$: Subject<boolean> = new Subject<boolean>();
  public loginForm = new FormGroup({});
  public signUpForm = new FormGroup({});
  public recoverPassForm = new FormGroup({});
  public currentView: string = "login";
  public isLoading: boolean = false;
  public loginValidatorsMessages = LoginValidatorsMessages;
  public signUpValidatorsMessages = SignUpValidatorsMessages;
  public recoverPasswordValidatorsMessages = RecoverPasswordValidatorsMessages;
  public optionViews = new Set(["login", "signUp", "recoverPass"]);
  public emailSent: boolean = false;
  public isAcceptedMailFlag: boolean;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    public dialogService: DialogService,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Login');
    this.auth.getFireBaseUser().then(fbUser => {
      if (fbUser !== null) {
        const currentLocalUser = this.auth.getUser();
        if (currentLocalUser && currentLocalUser.uid === fbUser.uid) {
          this.router.navigate(["/searchView"]);
        }
      }
    });
    this.loginForm = this.formBuilder.group(LoginValidators, {
      updateOn: "blur"
    });
    this.signUpForm = this.formBuilder.group(SignUpValidators, {
      updateOn: "blur"
    });
    this.recoverPassForm = this.formBuilder.group(RecoverPasswordValidators, {
      updateOn: "blur"
    });
  }

  ngOnInit(): void {
    this.auth.onError().subscribe(() => {
      this.isLoading = false;
    });

    this.signUpForm.get('email').valueChanges.subscribe(val => {
      this.isAcceptedMailFlag = this.isAcceptedMail(val);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  login() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.isLoading = true;
      const formValue = this.loginForm.value;
      this.auth.login(formValue.email, formValue.password);
    }
  }

  signUp() {
    this.signUpForm.markAllAsTouched();
    if (this.signUpForm.valid) {
      this.isLoading = true;
      const formValue = this.signUpForm.value;
      const email = formValue.email && formValue.email.trim() ? formValue.email.trim() : null;
      const nickname = formValue.nickname && formValue.nickname.trim() ? formValue.nickname.trim() : null;
      if(email && nickname) {
        this.auth.signUp(email, formValue.password, nickname);
      } else {
        this.isLoading = false;
      }      
    }
  }

  recoverPassword() {
    this.recoverPassForm.markAllAsTouched();
    if (this.recoverPassForm.valid) {
      this.isLoading = true;
      const formValue = this.recoverPassForm.value;
      this.auth.recoverPassword(formValue.email).pipe(takeUntil(this.destroy$)).subscribe(
        resp => {
          this.isLoading = false;
          this.emailSent = true;
        },
        error => {
          this.isLoading = false;
          this.dialogService.manageError(error);
        }
      );
    }
  }

  changeFormView(newView: string) {
    if (this.optionViews.has(newView)) {
      this.currentView = newView;
      this.loginForm.markAsUntouched();
      this.signUpForm.markAsUntouched();
      this.recoverPassForm.markAsUntouched();
    }
  }

  enterWithGoogle() {
    this.auth.loginGoogleUser();
  }

  enterWithFacebook() {
    this.auth.loginFacebookUser();
  }
  
  isAcceptedMail(email:string = '') {
    const arrayMail =  email.split("@");
    if(!arrayMail || !arrayMail[1]) {
      return false;
    } 
    const domain = arrayMail[1];
    if(!emailsAccepted.includes(domain)) {
      return false;
    }
    return true;
  }
}
