<div class="bs-pay-container" *ngIf="!loading">
    <div class="pay-header">
        <span>Obtener Documento</span>
        <span class="close" (click)="onCloseBSPay()">                            
            <mat-icon>close</mat-icon>
        </span>
    </div>
    <div *ngIf="data" class="pay-fileData-title ellipsis">
        {{data.title}}
    </div>
    <div class="pay-date ellipsis">
        Fecha de transacción: {{dateToShow}}
    </div>
    <div class="pay-summary">
        <div class="points">
            <div class="label-point">Puntos Requeridos:</div>
            <div class="number-points">{{price}} yeahcoins</div>
        </div>
        <div class="points">
            <div class="label-point">Puntos Actuales:</div>
            <div class="number-points">{{credit}} yeahcoins</div>
        </div>
        <div *ngIf="credit - price >= 0" class="points">
            <div class="label-point">Puntos Restantes:</div>
            <div class="number-points success">{{credit - price}} yeahcoins</div>
        </div>
        <div *ngIf="credit - price < 0" class="points">
            <div class="label-point">Puntos Faltantes:</div>
            <div class="number-points danger">{{(credit - price) * - 1}} yeahcoins</div>
        </div>
        <div *ngIf="credit - price < 0" class="legend">¡Ups! parece que te hacen falta {{(credit - price) * -1}} yeahcoins.</div>
    </div>
    <div class="pay-buttons">
        <button *ngIf="credit - price >= 0" (click)="obtainFile()" class="button-action">
            <span>Obtener por <img class="yeahcoin-ico" src="/assets/img/yeahcoin.svg" alt=""> {{price}} yeahcoins</span>
        </button>
        <button *ngIf="credit - price < 0" (click)="getMoreCoins()" class="button-action">
            <span >Obtener más <img class="yeahcoin-ico" src="/assets/img/yeahcoin.svg" alt=""> yeahcoins</span>
        </button>
    </div>
</div>
<div *ngIf="loading" class="loader">
    <app-loader [color]="'green'"></app-loader>
</div>