import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { IVideo } from '../utility/models/models';
import { VideosService } from './videos.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit, OnDestroy{

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public videosService: VideosService,     
    private router: Router,
    private errorService: ErrorService,
    private title: Title
  ) {
    this.title.setTitle('Videos');
  }

  public isLoading: boolean;
  public videos: IVideo[];

  ngOnInit(): void {
    this.isLoading = true;
    this.videosService.getVideos()
    .pipe(takeUntil(this.destroy$))
    .subscribe(videos => {
      this.isLoading = false;
      if(videos) {
        this.videos = videos;
      }
    }, error => { 
      this.isLoading = false;
      this.errorService.showError(error)
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  watchVideo(selectedVideo: IVideo) {
    if(selectedVideo) {
      this.videosService.setCurrentVideo(selectedVideo);
      this.router.navigate(["/video/" + selectedVideo.uid]);
    }
  }  

}