import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { SearchService } from "src/app/search-view/search.service";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  FileTitleValidators,
  FileTitleValidatorsMessages
} from "./modal-up-file.validations";
import { IChipOption } from "src/app/file/file.types";
import { FiltersService } from "../file-filters/filters.service";
import { FileService } from "src/app/file/file.service";
import { AuthService } from "src/app/auth/auth.service";
import { IUser } from "src/app/models/user";
import {
  IFullSubject,
  IFullSchool,
  IFullCareer,
  ILiteTeacher,
  INewFile
} from "../models/models";
import {
  ModalUpFileService,
  TYPES_TO_VALIDATE,
  validFileTypes,
  validImagesTypes
} from "./modal-up-file.service";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";

export enum VIEWS_UP_FILE {
  FILE = "FILE",
  TITLE = "TITLE",
  DATA = "DATA"
}

export interface IFileToValid {
  file: File;
  hasValidType: boolean;
  hasValidSize: boolean;
}
@Component({
  selector: "app-modal-up-file",
  templateUrl: "./modal-up-file.component.html",
  styleUrls: ["./modal-up-file.component.scss"]
})
export class ModalUpFileComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  public fileTitleForm = new FormGroup({});
  public fileTitleFormValidatorsMessages = FileTitleValidatorsMessages;

  public dataForm = new FormGroup({});
  public dataFormValidatorsMessages = FileTitleValidatorsMessages;

  public schoolSelected: IFullSchool;
  public careerSelected: IFullCareer;
  public subjectSelected: IFullSubject;
  public teacherSelected: ILiteTeacher;
  public typeSelected: IChipOption;
  public schools = [];
  public careers = [];
  public subjects = [];
  public teachers = [];

  public orderOptions: IChipOption[] = [];
  public typeOptions: IChipOption[] = [];
  public startOptions: IChipOption[] = [];

  public currentUser: IUser;

  public isDataTouched: boolean;
  public isValidCurrentSubject: boolean;
  public isValidCurrentTeacher: boolean;

  public canEditSchool: boolean = false;
  public canEditCareer: boolean = false;
  public schoolToShow: string;
  public careerToShow: string;

  filesToUpload: File[] = [];
  filesToShow: IFileToValid[] = [];
  filesToUploadValid: boolean;
  isLoading: boolean;
  currentView: string = VIEWS_UP_FILE.FILE;
  constructor(
    private formBuilder: FormBuilder,
    protected readonly fileService: FileService,
    private filtersService: FiltersService,
    private authService: AuthService,
    private modalUpFileService: ModalUpFileService,
    private dialogRef: MatDialogRef<ModalUpFileComponent>,
    private errorService: ErrorService
  ) {
    this.currentUser = this.authService.getUser();
    this.getSchools();
    this.fileTitleForm = this.formBuilder.group(FileTitleValidators, {
      updateOn: "blur"
    });
    this.fileTitleForm.controls["price"].setValue(60);
    this.dataForm = this.formBuilder.group(FileTitleValidators, {
      updateOn: "blur"
    });
    this.setOrderOptions();
    this.setTypeOptions();
    this.setStartOptions();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  handleFileInput(files: FileList) {
    Array.from(files).forEach(fileElement => {
      this.filesToShow.push({
        file: fileElement,
        hasValidSize: false,
        hasValidType: false
      });
    });
    if (this.filesToShow.length > 1) {
      this.filesToShow.map(fileTS => {
        fileTS.hasValidSize = this.hasValidSize(fileTS.file);
        fileTS.hasValidType = this.hasValidType(
          TYPES_TO_VALIDATE.IMG,
          fileTS.file
        );
      });
    } else if (this.filesToShow.length > 0) {
      this.filesToShow[0].hasValidSize = this.hasValidSize(
        this.filesToShow[0].file
      );
      this.filesToShow[0].hasValidType = this.hasValidType(
        TYPES_TO_VALIDATE.ALL,
        this.filesToShow[0].file
      );
    }
    this.madeFilesToUpload();
  }

  deleteAttachment(index) {
    this.filesToShow.splice(index, 1);
    this.madeFilesToUpload();
  }

  hasValidType(type: string = TYPES_TO_VALIDATE.ALL, file: File): boolean {
    if (file) {
      switch (type) {
        case TYPES_TO_VALIDATE.IMG:
          if (validImagesTypes.includes(file.type)) {
            return true;
          }
          return false;
        case TYPES_TO_VALIDATE.ALL:
          if (validFileTypes.includes(file.type)) {
            return true;
          }
          return false;
        default:
          return false;
      }
    }
    return false;
  }

  hasValidSize(file: File): boolean {
    if (file && file.size && file.size < 4949278.72) {
      return true;
    }
    return false;
  }
  
  madeFilesToUpload() {
    const approveItems = [];
    this.filesToShow.forEach(itemF => {
      if (itemF.hasValidType && itemF.hasValidSize) {
        approveItems.push(itemF);
      }
    });
    if (
      approveItems.length &&
      this.filesToShow.length === approveItems.length
    ) {
      this.filesToUpload = this.filesToShow.map(fts => fts.file);
      this.filesToUploadValid = true;
    } else {
      this.filesToUploadValid = false;
    }
  }

  next() {
    switch (this.currentView) {
      case VIEWS_UP_FILE.FILE:
        this.currentView = VIEWS_UP_FILE.TITLE;
        break;
      case VIEWS_UP_FILE.TITLE:
        this.currentView = VIEWS_UP_FILE.DATA;
        break;
      default:
        break;
    }
  }

  preview() {
    switch (this.currentView) {
      case VIEWS_UP_FILE.DATA:
        this.currentView = VIEWS_UP_FILE.TITLE;
        break;
      case VIEWS_UP_FILE.TITLE:
        this.currentView = VIEWS_UP_FILE.FILE;
        break;
      default:
        break;
    }
  }

  onTitleFormSubmit() {
    this.fileTitleForm.markAllAsTouched();
    if (this.fileTitleForm.valid) {
      this.next();
    }
  }

  setOrderOptions() {
    this.orderOptions = this.fileService.getOrderOptions().map(option => {
      const filterOption = option as IChipOption;
      filterOption.status = true;
      return filterOption;
    });
  }

  setTypeOptions() {
    this.typeOptions = [];
    this.fileService
      .getFileTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe(items => {
        items.forEach((fileData: any) => {
          const fileType = fileData.payload.doc.data();
          const option: IChipOption = {
            idElement: fileType.uid || "",
            label: fileType.name || "",
            status: true
          };
          this.typeOptions.push(option);
        });
        this.typeSelected = this.typeOptions[0];
      }, error => { this.errorService.showError(error)});
  }

  setStartOptions() {
    this.startOptions = this.fileService.getFileRatings().map(option => {
      const filterOption = option as IChipOption;
      filterOption.status = true;
      return filterOption;
    });
  }

  changeCanEditSchool() {
    this.canEditSchool = !this.canEditSchool;
  }

  onTypeSelected(event) {
    this.typeSelected = event;
  }

  getSchools() {
    this.filtersService.getSchools()
    .pipe(takeUntil(this.destroy$))
    .subscribe(catsSnapshot => {
      this.schools = [];
      catsSnapshot.forEach((institution: any) => {
        const data = institution.payload.doc.data();
        this.schools.push({
          ...data,
          ...{ label: `${data.nickname} ${data.name}` }
        });
      });
      if (this.currentUser.school) {
        this.schoolSelected = this.schools.find(
          school => school.uid === this.currentUser.school
        );
        if (this.schoolSelected) {
          this.schoolToShow = `${this.schoolSelected.nickname}: ${this.schoolSelected.name}`;
          this.onSchoolSelected(this.schoolSelected);
        }
        if (this.currentUser.career) {
          this.careerSelected = this.careers.find(
            career => career.uid === this.currentUser.career
          );
          if (this.careerSelected) {
            this.careerToShow = `${this.careerSelected.name}`;
          }
        }
      }
    }, error => { this.errorService.showError(error)});
  }

  onSchoolSelected(school: IFullSchool) {
    const careers = school ? school.careers : undefined;
    this.getCareers(careers);
    this.getTeachers(school.uid);
    this.getSubjects(school.uid);
    this.careerSelected = undefined;
    this.subjectSelected = undefined;
    this.teacherSelected = undefined;
  }

  getSubjects(uid: string) {
    this.filtersService.getSubjectsBySchoolServer(uid)
    .pipe(takeUntil(this.destroy$))
    .subscribe(catsSnapshot => {
      this.subjects = [];
      catsSnapshot.forEach((teacher: any) => {
        this.subjects.push(teacher);
      });
    }, error => { this.errorService.showError(error)});
  }

  getCareers(careers: string[] = []) {
    this.filtersService.getCareers(careers)
    .pipe(takeUntil(this.destroy$))
    .subscribe(catsSnapshot => {
      this.careers = [];
      catsSnapshot.forEach((institution: any) => {
        const data = institution.data();
        this.careers.push(data);
      });
    }, error => { this.errorService.showError(error)});
  }

  getTeachers(uid: string) {
    this.filtersService.getTeachersBySchool(uid)
    .pipe(takeUntil(this.destroy$))
    .subscribe(catsSnapshot => {
      this.teachers = [];
      catsSnapshot.forEach((teacher: any) => {
        this.teachers.push(teacher.payload.doc.data());
      });
    }, error => { this.errorService.showError(error)});
  }

  validData() {
    this.isDataTouched = true;
    if (
      this.schoolSelected &&
      this.careerSelected &&
      this.subjectSelected &&
      this.teacherSelected && 
      this.isValidCurrentTeacher &&
      this.isValidCurrentSubject
    ) {
      this.handleUploadFile();
    }
  }

  onSubjectSelected(subject: IFullSubject) {
    if (subject && !subject.uid) {
      const namePieces = subject.name.trim().split(" ");
      const namePattern = /(^[A-ZÁÉÍÓÚÑa-z0-9ñáéíóú]{3,})?$/;
      const validElements = [];
      namePieces.forEach(piece => {
        if (namePattern.test(piece)) {
          validElements.push(piece);
        }
      });
      if (validElements.length === namePieces.length) {
        this.isValidCurrentSubject = true;
      } else {
        this.isValidCurrentSubject = false;
      }
    } else {
      this.isValidCurrentSubject = true;
    }
  }

  onTeacherSelected(teacher: ILiteTeacher) {
    if (teacher && !teacher.uid) {
      const formatedText = teacher.teacher.trim().toLocaleLowerCase();
      if(formatedText === 'academia') {
        this.isValidCurrentTeacher = true;
      } else {
        const namePieces = teacher.teacher.trim().split(" ");
        if (namePieces.length >= 3) {
          const namePattern = /(^[A-ZÁÉÍÓÚÑ]{1}([a-zñáéíóú]+){2,})?$/;
          const validElements = [];
          namePieces.forEach(piece => {
            if (namePattern.test(piece)) {
              validElements.push(piece);
            }
          });
          if (validElements.length === namePieces.length) {
            this.isValidCurrentTeacher = true;
          } else {
            this.isValidCurrentTeacher = false;
          }
        } else {
          this.isValidCurrentTeacher = false;
        }
      }
    } else {
      this.isValidCurrentTeacher = true;
    }
  }

  // Upload File Flow

  handleUploadFile() {
    this.isLoading = true;
    const dataToUpdate = this.joinData();
    this.modalUpFileService
      .addFile(dataToUpdate, this.filesToUpload)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.isLoading = false;
        this.dialogRef.close(result);
      }, error => { 
        this.isLoading = false;
        this.dialogRef.close();
        this.errorService.showError(error,10)
      });
  }

  joinData(): INewFile {
    let data: INewFile = {
      title: this.fileTitleForm.get("title").value,
      uidSubject: this.subjectSelected.uid || null,
      uidCareer: this.careerSelected.uid,
      uidSchool: this.schoolSelected.uid,
      uidTeacher: this.teacherSelected.uid || null,
      uidType: this.typeSelected.idElement,
      price: this.fileTitleForm.get("price").value,
      description: this.fileTitleForm.get("description").value
    };
    if(!this.subjectSelected.uid) {
      data = {...data, ...{subject: this.subjectSelected.name}} 
    }
    if(!this.teacherSelected.uid) {
      const formatedText = this.teacherSelected.teacher.trim().toLocaleLowerCase();
      if(formatedText === 'academia') {
        data = {...data, ...{teacher: 'Academia'}} 
      } else {
        data = {...data, ...{teacher: this.teacherSelected.teacher}} 
      }
    }
    return data;
  }
}
