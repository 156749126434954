<div class="container">
    <div class="login">
        <div class="logo-central"><img src="/assets/img/logo.svg" alt=""></div>
        <p class="slogan">Compartamos el conocimiento.</p>
        <div class="form-login">
            <div>
                <img src="/assets/img/checked.png" alt="">
                <p class="label--button">¡Correo Enviado!</p>
                <p class="font-normal">Enviamos un correo a tu cuenta: <span class="highlighted-text">{{mailToVerify}}</span> para verificarla.</p>
                <p *ngIf="error" class="font-normal error">{{error}}</p>
                <p class="font-normal">¿No recibiste el correo?, <span (click)="sendVerifyEmail()" class="highlighted-text">envíalo de nuevo.</span></p>
                <button class="button--block" (click)="checkValidation()">¿Ya lo validaste?</button>
            </div>
        </div>
    </div>
</div>
<app-full-loader *ngIf="isLoading"></app-full-loader>