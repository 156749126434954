import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { from, combineLatest, of, Subject, Observable } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { IFileFiltersData, IFullSchool } from "../models/models";
import { IUser } from "../../models/user";
import { host } from "src/app/constant";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class FiltersService {
  private filtersData: IFileFiltersData;
  private savedSchools: any[] = [];
  private savedCareers: any[] = [];
  private savedSubjects: any[] = [];
  private savedTeachers: any[] = [];
  private savedSearchOptions: any[] = [];
  private onSavedSearchOptionsChange$ = new Subject<any>();
  private onFiltersUpdate$ = new Subject<IFileFiltersData>();
  private selectedFilterOption: any;
  private schoolGroupsSaved: string[] = [];

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    this.authService.onSetUser.subscribe(user => {
      const oldUser: IUser = this.authService.getOldUser();
      if(oldUser.institution === user.institution) {
        user.institution = null;
      }
      if(oldUser.school === user.school) {
        user.school = null;
      }
      if(oldUser.career === user.career) {
        user.career = null;
      }
      this.loadSavedSearchOptions(user);
    });
  }

  public getSelectedFilterOption() {
    return this.selectedFilterOption;
  }

  public setSelectedFilterOption(option: any) {
    this.selectedFilterOption = option;
  }

  public loadFirstSavedSearchOptions() {
    if (!this.savedSearchOptions || !this.savedSearchOptions.length) {
      this.loadSavedSearchOptions();
    }
  }

  public onFiltersUpdate() {
    return this.onFiltersUpdate$;
  }

  public loadFirstData() {
    this.onFiltersUpdate$.next(this.filtersData);
  }

  public searchByNavbar(element?: any) {
    const currentFilterData = this.filtersData;
    if (element && element.type) {
      this.setSelectedFilterOption(element);
      switch (element.type) {
        case "teacher":
          const teacherData: IFileFiltersData = {
            uidSubject: null,
            format: null,
            type: null,
            score: null,
            uidCareer: null,
            uidTeacher: element.id,
            uidSchool: element.uidSchool,
          };
          const schoolSelectedT = this.savedSchools.find(s => s.uid === element.uidSchool);
          let filterPreferences = {
            teacher: {
              uid: element.id,
              name: element.label
            },
            school: {
              uid: schoolSelectedT.uid || element.uid,
              name: schoolSelectedT.name || element.label,
              nickname: schoolSelectedT.nickname || '',
            },
            career: undefined,
            subject: undefined,
            score: undefined,
            types: undefined,
            formats: undefined,
            search: undefined,
            origin: 'nav-bar'
          }
          let newFilterPreferences = {...this.authService.getFilterPreferences(), ...filterPreferences}
          this.authService.setFilterPreferences(newFilterPreferences);
          this.setFilters({ ...currentFilterData, ...teacherData });
          break;
        case "subject":
          const subjectData: IFileFiltersData = {
            uidSubject: element.id,
            uidSchool: element.uidSchool,
            format: null,
            type: null,
            score: null,
            uidCareer: null,
            uidTeacher: null
          };
          const schoolSelected = this.savedSchools.find(s => s.uid === element.uidSchool);
          let filterPreferences1 = {
            subject: {
              uid: element.id,
              name: element.label
            },
            school: {
              uid: schoolSelected.uid || element.uid,
              name: schoolSelected.name || element.label,
              nickname: schoolSelected.nickname || '',
            },
            career: undefined,
            teacher: undefined,
            score: undefined,
            types: undefined,
            formats: undefined,
            search: undefined,
            origin: 'nav-bar'
          }
          let newFilterPreferences1 = {...this.authService.getFilterPreferences(), ...filterPreferences1}
          this.authService.setFilterPreferences(newFilterPreferences1);
          this.setFilters({ ...currentFilterData, ...subjectData });
          break;
        case "school":
          const schoolData: IFileFiltersData = {
            uidSubject: null,
            format: null,
            type: null,
            score: null,
            uidCareer: null,
            uidTeacher: null,
            uidSchool: element.id
          };
          const newSchool = this.savedSchools.find(s => s.uid === element.id);
          let filterPreferences2 = {
            subject: undefined,
            career: undefined,
            teacher: undefined,
            score: undefined,
            types: undefined,
            formats: undefined,
            search: undefined,
            school: {
              uid: newSchool.uid || element.uid,
              name: newSchool.name || element.label,
              nickname: newSchool.nickname || '',
            },
            origin: 'nav-bar'
          }
          let newFilterPreferences2 = {...this.authService.getFilterPreferences(), ...filterPreferences2}
          this.authService.setFilterPreferences(newFilterPreferences2);
          this.setFilters({ ...currentFilterData, ...schoolData });
          this.loadSavedSearchOptions();
          break;
        default:
          break;
      }
    } else if (element && element.label) {
      let filterPreferences1 = {
        subject: undefined,
        career: undefined,
        teacher: undefined,
        score: undefined,
        types: undefined,
        formats: undefined,
        search: element.label,
        origin: 'nav-bar'
      }
      let newFilterPreferences1 = {...this.authService.getFilterPreferences(), ...filterPreferences1}
      this.authService.setFilterPreferences(newFilterPreferences1);
      const keywordFilter: IFileFiltersData = {
        search: element.label
      }
      this.setFilters({ ...currentFilterData, ...keywordFilter });
    } else if (!element) {
      const currentPreferences = this.authService.getFilterPreferences();
      let school = null;
      if(currentPreferences && currentPreferences.school && currentPreferences.school.uid) {
        school = currentPreferences.school;
      }
      const nullData: IFileFiltersData = {
        uidSubject: null,
        uidCareer: null,
        format: null,
        type: null,
        score: null,
        uidTeacher: null,
        uidSchool: school.uid,
      };
      let filterPreferences = {
        teacher: undefined,
        career: undefined,
        subject: undefined,
        score: undefined,
        types: undefined,
        formats: undefined,
        search: undefined,
        origin: 'nav-bar',
        school: school
      }
      let newFilterPreferences = {currentPreferences, ...filterPreferences}
      this.authService.setFilterPreferences(newFilterPreferences);
      this.setFilters(nullData);
    }
  }

  getAndSetNewSchoolOptions(school:any) {
    if(!school && !school.uid) {
      return;
    }



    //this.setSavedSearchOptions(school, this.schools, this.teachers, this.subjects);
  }

  public cleanFilters() {
    const currentFilterData = this.filtersData;
    const teacherData: IFileFiltersData = {
      uidSubject: null,
      format: null,
      type: null,
      score: null,
      uidCareer: null,
      uidTeacher: null,
      search: null
    };
    let filterPreferences = {
      teacher: undefined,
      career: undefined,
      subject: undefined,
      score: undefined,
      types: undefined,
      formats: undefined,
      search: undefined,
      origin: 'service'
    }
    let newFilterPreferences = {...this.authService.getFilterPreferences(), ...filterPreferences}
    this.authService.setFilterPreferences(newFilterPreferences);
    this.setFilters({ ...currentFilterData, ...teacherData });
  }

  public loadSavedSearchOptions(currentUser?: IUser) {
    const user = currentUser ? currentUser : this.authService.getUser();
    const filterPreferences = this.authService.getFilterPreferences();
    const idSchool = filterPreferences && filterPreferences.school && filterPreferences.school.uid ? filterPreferences.school.uid : user.school;
    if (!user) {
      return;
    }

    if(this.schoolGroupsSaved && this.schoolGroupsSaved.includes(idSchool)) {
      return;
    }
    this.schoolGroupsSaved.push(idSchool);

    if (user.institution) {
      this.getSchools().subscribe(catsSnapshot => {
        this.savedSchools = [];
        let newSchools = [];
        catsSnapshot.forEach((institution: any) => {
          const data = institution.payload.doc.data();
          this.savedSchools.push({
            ...data,
            ...{ label: `${data.nickname} ${data.name}` }
          });
          newSchools.push({
            label: `${data.nickname} ${data.name}`,
            id: data.uid,
            type: "school"
          });
        });
        newSchools = newSchools.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)))
        this.savedSearchOptions = [...this.savedSearchOptions, ...newSchools];
        this.onSavedSearchOptionsChange$.next({
          action: "add",
          items: newSchools
        });
      });
    }

    if (idSchool) {
      this.getTeachersBySchool(idSchool).subscribe(catsSnapshot => {
        let newTeachers = [];
        catsSnapshot.forEach((teacher: any) => {
          const element = teacher.payload.doc.data();
          this.savedTeachers.push(element);
          newTeachers.push({
            label: element.teacher,
            id: element.uid,
            type: "teacher",
            uidSchool: idSchool
          });
        });
        this.savedTeachers = this.savedTeachers.filter((thing, index, self) => index === self.findIndex((t) => (t.uid === thing.uid)))
        newTeachers = newTeachers.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)))
        this.savedSearchOptions = [...this.savedSearchOptions, ...newTeachers];
        this.onSavedSearchOptionsChange$.next({
          action: "add",
          items: newTeachers
        });
      });
    }

    if (idSchool) {
      this.getSubjectsBySchoolServer(idSchool).subscribe(subjects => {
        this.savedSubjects = subjects || [];
        let newSubjects = [];
        this.savedSubjects.forEach(ss => {
          newSubjects.push({ label: ss.name, id: ss.uid, type: "subject", uidSchool: idSchool});
        });
        this.savedSubjects = this.savedSubjects.filter((thing, index, self) => index === self.findIndex((t) => (t.uid === thing.uid)))
        newSubjects = newSubjects.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)))
        this.savedSearchOptions = [...this.savedSearchOptions, ...newSubjects];
        this.onSavedSearchOptionsChange$.next({
          action: "add",
          items: newSubjects
        });
      });
    }
    
  }

  public onSavedSearchOptionsChange() {
    return this.onSavedSearchOptionsChange$;
  }

  public setSavedSearchOptions(currentSchool:IFullSchool, schools: any, teachers: any, subjects: any) {
    if(!currentSchool || !currentSchool.uid) {
      return;
    }

    if(this.schoolGroupsSaved.includes(currentSchool.uid)) {
      return;
    }
    this.schoolGroupsSaved.push(currentSchool.uid);

    this.onSavedSearchOptionsChange$.next({
      action: "replace",
      items: []
    });

    const newSchools = [];
    if (schools) {
      this.savedSchools = [];
      schools.forEach(elm => {
        this.savedSchools.push({
          ...elm,
          ...{ label: `${elm.nickname}: ${elm.name}` }
        });
        newSchools.push({
          label: `${elm.nickname}: ${elm.name}`,
          id: elm.uid,
          type: "school"
        });
      });
    }

    const newTeachers = [];
    if (teachers) {
      this.savedTeachers = [];
      teachers.forEach(elm => {
        this.savedTeachers.push(elm);
        newTeachers.push({
          label: elm.teacher,
          id: elm.uid,
          type: "teacher",
          uidSchool: currentSchool.uid
        });
      });
    }

    const newSubjects = [];
    if (subjects) {
      this.savedSubjects = [];
      subjects.forEach(elm => {
        this.savedSubjects.push(elm);
        newSubjects.push({
          label: elm.name, 
          id: elm.uid, 
          type: "subject", 
          uidSchool: currentSchool.uid});
      });
    }

    const newElements = [...newSchools, ...newTeachers, ...newSubjects];
    const finalElements = [...this.savedSearchOptions, ...newElements];
    this.savedSearchOptions = finalElements.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id && t.type === thing.type)));
    this.onSavedSearchOptionsChange$.next({
      action: "add",
      items: this.savedSearchOptions
    });
  }

  public getSchools() {
    return this.firestore.collection("schools").snapshotChanges();
  }

  public getCareers(uids?: string[]) {
    if (this.savedCareers) {
      let themChanged: boolean = false;
      uids.forEach(u => {
        const foundIt = this.savedCareers.find(sc => sc.uid === u);
        if (!foundIt) {
          themChanged = true;
        }
      });
      if (!themChanged) {
        of(this.savedCareers);
      }
    }
    const reads = uids.map(id =>
      this.firestore
        .collection("career")
        .doc(id)
        .ref.get()
    );
    return combineLatest(reads);
  }

  public getSubjects(uidCareer?: string) {
    if (uidCareer) {
      return this.firestore
        .collection("career")
        .doc(uidCareer)
        .snapshotChanges();
    }
  }

  public getTeachersBySchool(id: string) {
    return this.firestore
      .collection("teachers", ref => ref.where("uidSchool", "==", id))
      .snapshotChanges();
  }

  public getTeachersBySchoolServer(id: string): Observable<Array<any>>  {
    const route = `${host}/school/${id}/teachers`;
    return this.http.get<Array<any>>(route);
  }

  public getSubjectsBySchoolServer(id: string): Observable<Array<any>>  {
    const route = `${host}/school/${id}/subjects`;
    return this.http.get<Array<any>>(route);
  }

  public getCurrentFilters(): IFileFiltersData {
    if(!this.filtersData) {
      this.buildFiltersByCache();
    }
    return this.filtersData;
  }

  private buildFiltersByCache() {
    const filtersInCache = this.authService.getFilterPreferences();
    let filterData:IFileFiltersData = {};
    filterData.uidSchool
    if(filtersInCache) {
      if(filtersInCache.order) {
        filterData = {
          orderByField: filtersInCache.order.orderByField, 
          orderByDir: filtersInCache.order.orderByDir
        }
      }
      if(filtersInCache.school && filtersInCache.school.uid) {
        filterData = {...filterData, ...{uidSchool:filtersInCache.school.uid}}
      }
      if(filtersInCache.career && filtersInCache.career.uid) {
        filterData = {...filterData, ...{uidCareer:filtersInCache.career.uid}}
      }
      if(filtersInCache.subject && filtersInCache.subject.uid) {
        filterData = {...filterData, ...{uidSubject: filtersInCache.subject.uid}};
      }
      if(filtersInCache.teacher && filtersInCache.teacher.uid) {
        filterData = {...filterData, ...{uidTeacher: filtersInCache.teacher.uid}};
      }
      if(filtersInCache.search && filtersInCache.search.length) {
        filterData = {...filterData, ...{search: filtersInCache.search}};
      }
      if(filtersInCache.score && filtersInCache.score != 1) {
        filterData = {...filterData, ...{score: filtersInCache.score}};
      }
      if(filtersInCache.formats && filtersInCache.formats.length) {
        const formatsToAdd = [];
        filtersInCache.formats.forEach(elm => {
          const chipData = {
            status: true,
            idElement: elm.uid,
            label: elm.name
          }
          formatsToAdd.push(chipData);
        });
        if(formatsToAdd && formatsToAdd.length) {
          filterData = {...filterData, ...{format: formatsToAdd}};
        }
      }
      if(filtersInCache.types && filtersInCache.types.length) {
        const typesToAdd = [];
        filtersInCache.types.forEach(elm => {
          const chipData = {
            status: true,
            idElement: elm.uid,
            label: elm.name
          }
          typesToAdd.push(chipData);
        });
        if(typesToAdd && typesToAdd.length) {
          filterData = {...filterData, ...{type: typesToAdd}};
        }
      }
    }
    this.filtersData = filterData;
  }

  public matchFiltersAndPreferences() {
    const filters = this.authService.getFilterPreferences();
    let dataFilters: IFileFiltersData = {};
    if(filters && filters.school && filters.school.uid) {
      dataFilters = {...dataFilters, ...{uidSchool: filters.school.uid}};
    }
    if(filters && filters.order && filters.order.orderByDir && filters.order.orderByField) {
      dataFilters = {...dataFilters, ...{orderByField: filters.order.orderByField, orderByDir: filters.order.orderByDir}};
    }
    this.setFilters(dataFilters);
  }

  public setFilters(data: IFileFiltersData) {
    this.filtersData = data;
    this.onFiltersUpdate$.next(data);
  }

  public getSearchOptions() {
    return this.savedSearchOptions;
  }
}
