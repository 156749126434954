import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogConfirmPurchaseComponent } from "../utility/dialogs/dialog-confirm-purchase/dialog-confirm-purchase.component";
import {
  MatBottomSheet,
  MatBottomSheetConfig
} from "@angular/material/bottom-sheet";
import { BottomSheetPayComponent } from "../utility/bottoms-sheet/bottom-sheet-pay/bottom-sheet-pay.component";
import { SearchService } from "../search-view/search.service";
import { IAd, ICommentFile, IFullFile } from "../utility/models/models";
import { DialogButtonsComponent } from "../utility/dialogs/dialog-buttons/dialog-buttons.component";
import { FileService } from "./file.service";
import { AuthService } from "../auth/auth.service";
import { Title } from "@angular/platform-browser";
import { YeahcoinsService } from "../yeahcoins/yeahcoins.service";
import { RateFileComponent } from "../utility/rate-file/rate-file.component";
import { ReportFileComponent } from "../utility/report-file/report-file.component";
import { ErrorService } from "../error/error.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export enum Views {
  mobile = "mobile",
  desktop = "desktop"
}

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"]
})
export class FileComponent implements OnInit, OnDestroy {
  @ViewChild("myImage", { static: false }) image: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  public localAds: Array<IAd> = [
    {link:'https://wa.link/b91oz2',img:'/assets/img/ads/AETGo.gif', name:'Aetgo'},
    {link:'https://www.ican-ada.com/form/jvq3RKP8ZXbhSOTod57h',img:'/assets/img/ads/ICAN.gif', name: 'ICANADA'},
    // {link:'https://rollercoin.com/?r=knkj46cx', img:'//rollercoin.com/static/img/referral/banners/ref_300100.gif', name:'RollerCoin'}
  ];

  currentAd:IAd;
  isLoading: boolean = true;
  showingFile: boolean = false;
  dataFile: IFullFile;
  source: any;
  fileLoaded: Blob;
  likeFile: boolean = false;
  dislikeFile: boolean = false;
  totalLikes: number;
  totalDislikes: number;
  public innerWidth: any;
  public view: string = Views.mobile;
  public rated:boolean = false;
  public reported: boolean = false;

  constructor(
    private title: Title,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    public dialog: MatDialog,
    private fileService: FileService,
    private authService: AuthService,
    private errorService: ErrorService,
  ) {
    this.innerWidth = window.innerWidth;
    this.buildView();
  }

  ngOnInit(): void {
    this.currentAd = this.getAd();
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const uidFile = params && params.uid ? params.uid : "";
      this.searchService.getFileById(uidFile).pipe(takeUntil(this.destroy$)).subscribe(dataFile => {
        if (dataFile) {
          this.title.setTitle(dataFile.title);
          this.dataFile = dataFile;
          this.totalLikes = dataFile.likes;
          this.totalDislikes = dataFile.dislikes;
          this.likeFile = dataFile.liked;
          this.dislikeFile = dataFile.disliked;
          this.isLoading = false;
          this.reported = dataFile.reported ? true : false;
          this.rated = dataFile.rate ? true : false;
        }
      }, err => {
        this.isLoading = false; this.errorService.showError(err);
        this.router.navigate(["/profile"]);
      });
    }, err => {
      this.isLoading = false; this.errorService.showError(err);
      this.router.navigate(["/profile"]);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.buildView();
  }

  buildView() {
    if (this.innerWidth >= 830) {
      this.view = Views.desktop;
    } else {
      this.view = Views.mobile;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogConfirmPurchaseComponent);
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe();
  }

  openBottomSheet(): void {
    const data: MatBottomSheetConfig = {
      data: {
        title: this.dataFile.title,
        price: this.dataFile.price
      }
    };
    const currentBS = this._bottomSheet.open(BottomSheetPayComponent, data);
    currentBS.afterDismissed().pipe(takeUntil(this.destroy$)).subscribe(dataOnClose => {
      if (dataOnClose === "obtain") {
        this.isLoading = true;
        this.fileService
          .newExchange(this.dataFile.uid)
          .pipe(takeUntil(this.destroy$))
          .subscribe(dataExchange => {
            if (dataExchange && dataExchange.cost) {
              const currentUser = this.authService.getUser();
              const newCredit = currentUser.yeahcoins - dataExchange.cost;
              const newUser = { ...currentUser, ...{ yeahcoins: newCredit } };
              this.authService.setUser(newUser);
              this.router.navigate(["/profile/downloads"]);
            }
          }, err => {this.isLoading = false; this.errorService.showError(err);});
      } else if (dataOnClose === "coins") {
        this.router.navigate(["/yeahcoins"]);
      }
    }, err => {this.isLoading = false; this.errorService.showError(err);});
  }

  closeBottomSheet(): void {
    this._bottomSheet.dismiss();
  }

  changeView(view: string) {
    this.showingFile = view === "file";
  }

  onClickLike(status: boolean) {
    if(this.dislikeFile) {
      this.totalDislikes = this.totalDislikes - 1;
    }
    this.dislikeFile = false
    let reaction = {};
    if(status) {
      this.likeFile = true;
      this.totalLikes = this.totalLikes + 1;
      reaction = {like:true, dislike:false}
    } else {
      this.likeFile = false;
      this.totalLikes = this.totalLikes - 1;
      reaction = {like:false, dislike:false}
    }
    this.fileService.newReaction(this.dataFile.uid, reaction).pipe(takeUntil(this.destroy$))
    .subscribe(() => {}, err => this.errorService.showError(err));
  }

  onClickDislike(status: boolean) {
    if(this.likeFile) {
      this.totalLikes = this.totalLikes - 1;
    }
    this.likeFile = false
    let reaction = {};
    if(status) {
      this.dislikeFile = true;
      this.totalDislikes = this.totalDislikes + 1;
      reaction = {like:false, dislike:true}
    } else {
      this.dislikeFile = false;
      this.totalDislikes = this.totalDislikes - 1;
      reaction = {like:false, dislike:false}
    }
    this.fileService.newReaction(this.dataFile.uid, reaction).pipe(takeUntil(this.destroy$))
    .subscribe(() => {}, err => this.errorService.showError(err));
  }

  onClickReport() {
    if (this.dataFile.obtained && !this.reported) {
      const options: MatDialogConfig = {
        data: {
          uidFile: this.dataFile.uid
        }
      };
      const reportDialog = this.dialog.open(ReportFileComponent, options);
      reportDialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res) => {
        if(res) {
          this.reported = true;
        }
      }, err => this.errorService.showError(err));
    }
  }

  onClickRate() {
    if (this.dataFile.obtained && !this.rated) {
      const options: MatDialogConfig = {
        data: {
          uidFile: this.dataFile.uid
        }
      };
      const rateDialog = this.dialog.open(RateFileComponent, options);
      rateDialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res) => {
        if(res) {
          this.rated = true;
        }
      }, err => this.errorService.showError(err));
    }
  }

  getImage(type: string = "Imagen"): string {
    switch (type) {
      case "Imagen":
      case "Imagenes":
        return "/assets/img/formatIcons/IMG.png";
      case "PDF":
        return "/assets/img/formatIcons/PDF.png";
      case "DOC":
        return "/assets/img/formatIcons/DOC.png";
      case "PP":
        return "/assets/img/formatIcons/PP.png";
      case "EXCEL":
        return "/assets/img/formatIcons/EXCEL.png";
      case "ZIP":
        return "/assets/img/formatIcons/ZIP.png";
    }
  }

  onCommnent(comment: string) {
    if (comment) {
      this.fileService
        .newComment(this.dataFile.uid, comment)
        .pipe(takeUntil(this.destroy$))
        .subscribe(objectComment => {
          const commentAdded = objectComment as ICommentFile;
          this.dataFile.comments.push(commentAdded);
        }, err => this.errorService.showError(err));
    }
  }

  setRestrictionMessage(button: string) {
    if (!this.dataFile) {
      return;
    }
    switch (button) {
      case "like":
      case "score":
        if (this.dataFile.owner) {
          return "No puedes valorar tu propio archivo";
        } else if (!this.dataFile.obtained) {
          return "Para valorarlo primero debes obtener este archivo";
        }
      case "report":
        if (this.dataFile.owner) {
          return "No puedes reportar tu propio archivo";
        } else if (!this.dataFile.obtained) {
          return "Para reportar primero debes obtener este archivo";
        }
      default:
        return null;
    }
  }

  removeFile() {
    if(this.dataFile.uid) {
      this.fileService.deleteFile(this.dataFile.uid).subscribe((res) => {
        this.router.navigate(["/profile"]);
      });
    }
  }

  getAd(): IAd {
    const random = Math.floor(Math.random() * this.localAds.length);
    return this.localAds[random];
  }
}
