import { Component, OnInit } from "@angular/core";
import { ITest } from "../../navbar/navbar.component";

@Component({
  selector: "app-dialog-filter-options",
  templateUrl: "./dialog-filter-options.component.html",
  styleUrls: ["./dialog-filter-options.component.scss"]
})
export class DialogFilterOptionsComponent implements OnInit {
  options: ITest[] = [
    {
      id: "5a15b13c36e7a7f00cf0d7cb",
      name: "Karyn Wright"
    },
    {
      id: "5a15b13c2340978ec3d2c0ea",
      name: "Rochelle Estes"
    },
    {
      id: "5a15b13c663ea0af9ad0dae8",
      name: "Mendoza Ruiz"
    }
  ];

  constructor() {}

  ngOnInit(): void {}
}
