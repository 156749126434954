import { Component, OnInit, Input } from "@angular/core";
import { FiltersService } from "../file-filters/filters.service";
import { IFileFiltersData } from "../models/models";

@Component({
  selector: "app-internal-component",
  templateUrl: "./internal-component.component.html",
  styleUrls: ["./internal-component.component.scss"]
})
export class InternalComponentComponent implements OnInit {
  @Input("showFooter") showFooter: boolean;
  @Input("showHeader") showHeader: boolean;
  @Input("isLoading") isLoading: boolean;

  isShowingFilters: boolean;

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {}

  showFilters(status: boolean) {
    this.showHeader = !status;
    this.isShowingFilters = status;
  }

  closeFilters() {
    this.showHeader = true;
    this.isShowingFilters = false;
  }

  onClickSearch(filters: IFileFiltersData) {
    this.showHeader = true;
    this.isShowingFilters = false;
    this.filtersService.setFilters(filters);
  }
}
