import { Component, Input, OnInit } from "@angular/core";
import { IRating } from "../models/models";

@Component({
  selector: "app-histogram",
  templateUrl: "./histogram.component.html",
  styleUrls: ["./histogram.component.scss"]
})
export class HistogramComponent implements OnInit {
  @Input("data") ratings: IRating;
  public fullStars = [];
  public restStars = [];

  constructor() {}

  ngOnInit(): void {
    const countFullStars = Math.floor(this.ratings.average);
    this.fullStars = Array(countFullStars)
      .fill(0)
      .map((x, i) => i);
    this.restStars = Array(5 - countFullStars)
      .fill(0)
      .map((x, i) => i);
  }
}
