<h2 mat-dialog-title>Filtros de Busqueda</h2>
<mat-dialog-content>
    <label>Escuelas</label>
    <ng-select placeholder="Todas las escuelas" [items]="options" bindLabel="name" bindValue="name"></ng-select>
    <label>Profesor</label>
    <ng-select placeholder="Todos los profesores" [items]="options" bindLabel="name" bindValue="name"></ng-select>
    <label>Materia</label>
    <ng-select placeholder="Todas las materias" [items]="options" bindLabel="name" bindValue="name"></ng-select>
    <label>Tipo</label>
    <ng-select placeholder="Todos los archivos" [items]="options" bindLabel="name" bindValue="name"></ng-select>
    <label>Formato</label>
    <ng-select placeholder="Todos los formatos" [items]="options" bindLabel="name" bindValue="name"></ng-select>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="searchButton">Buscar</button>
  </mat-dialog-actions>