import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AUTH_ERRORS } from "src/app/models/authErrors";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";

export interface IMessage {
  type?: string;
  title?: string;
  message: string;
}

@Injectable({
  providedIn: "root"
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  manageError(error: any) {
    switch (error.code) {
      case AUTH_ERRORS.WRONG_PASSWORD:
        this.showErrorMessage("Contraseña Incorrecta");
        break;
      case AUTH_ERRORS.EMAIL_ALREADY_IN_USE:
        this.showErrorMessage(
          "El correo ya esta en uso, prueba con otro o cambia tu contraseña"
        );
        break;
      case AUTH_ERRORS.USER_NOT_FOUND:
        this.showErrorMessage("El usuario no existe o fue eliminado");
        break;
      case AUTH_ERRORS.USER_CANCELLED:
        this.showErrorMessage("El usuario fue temporalmente bloqueado");
        break;
      case AUTH_ERRORS.USER_CANCELLED:
        this.showErrorMessage("El usuario fue temporalmente bloqueado");
        break;
      default:
        break;
    }
  }

  showErrorMessage(message: string) {
    const messageData: IMessage = {
      type: "error",
      title: "",
      message
    };
    this.showErrorDialog(messageData);
  }

  showErrorDialog(config: IMessage) {
    return this.dialog.open(ErrorDialogComponent, {
      data: config
    });
  }
}
