import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeLast, takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";
import { ProfileService } from "../../profile.service";

@Component({
  selector: "app-movements",
  templateUrl: "./movements.component.html",
  styleUrls: ["./movements.component.scss"]
})
export class MovementsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public transactions = new BehaviorSubject([]);
  public batch: number = 15;
  public lastKey: string = "";
  public finished: boolean = false;

  constructor(private profileService: ProfileService, private errorService: ErrorService) {}

  ngOnInit(): void {
    this.getMovements();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getMovements() {
    if (this.finished) {
      return;
    }
    this.profileService
      .getMovements(this.lastKey)
      .pipe(takeLast(1), takeUntil(this.destroy$))
      .subscribe(movements => {
        if (movements && movements.length) {
          const lastItem = movements[movements.length - 1];
          if (lastItem) {
            this.lastKey = lastItem.uid;
          }
          const currentMovements = this.transactions.getValue();
          const lastCurrentItem = currentMovements[currentMovements.length - 1];
          if (lastCurrentItem && lastCurrentItem.uid === this.lastKey) {
            this.finished = true;
            return;
          }
          if (movements.length < this.batch) {
            this.finished = true;
          }
          this.transactions.next(currentMovements.concat(movements));
        } else {
          this.finished = true;
          const currentMovements = this.transactions.getValue();
          this.transactions.next(currentMovements);
        }
      }, error => {
        this.errorService.showError(error);
      });
  }

  onScroll() {
    this.getMovements();
  }
}
