import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { ErrorService } from "../error/error.service";
import { Views } from "../file/file.component";
import { IUser } from "../models/user";
import { ValueMeComponent } from "../utility/value-me/value-me.component";
import { PROFILE_VIEWS } from "./components/header-profile/header-profile.component";
import { ProfileService } from "./profile.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private authService: AuthService,
    private profileService: ProfileService,
    private errorService: ErrorService,
    public dialog: MatDialog,
    private title: Title
  ) {
    this.title.setTitle('Mi Perfil');
    const defaultView = this._route.snapshot.paramMap.get("view");
    this.innerWidth = window.innerWidth;
    this.buildView();
  }

  destroy$: Subject<boolean> = new Subject<boolean>();

  isLoading: boolean = false;
  settings: boolean = false;
  searchItems = [];
  currentView: string = PROFILE_VIEWS.FILES;
  public innerWidth: any;
  public view: string = Views.desktop;
  public currentEmoji: string;
  public currentUser: IUser;
  public viewRouteParameter: string;

  files = [];
  downloads = [];
  // transactions = [];
  notifications = [];

  ngOnInit(): void {
    this.currentUser = this.authService.getUser();
    this.getCurrentEmoji();
    this._route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.viewRouteParameter = null;
      if (params && params.view) {
        this.viewRouteParameter = params.view;
        switch(params.view) {
          case "settings": 
            this.currentView = "settings";
            this.checkView();
            break;
          case "downloads": 
            this.currentView = "downloads";
            break;
          case "transactions": 
            this.currentView = "transactions";
            break;
          default:
            break;
        }
      }
    }, err => this.errorService.showError(err));
    this.profileService.getMe().pipe(takeUntil(this.destroy$)).subscribe(dataUser => {
      const user: IUser = dataUser;
      this.authService.setUser(user);
      this.currentUser = this.authService.getUser();
    }, err => this.errorService.showError(err));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.buildView();
    this.checkView();
  }

  buildView() {
    if (this.innerWidth >= 830) {
      this.view = Views.desktop;
    } else {
      this.view = Views.mobile;
    }
  }

  getCurrentEmoji() {
    this.currentEmoji = this.currentUser.emoji || "😀";
    this.authService.onSetUser.pipe(takeUntil(this.destroy$)).subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.currentEmoji = user.emoji;
      }
    }, err => this.errorService.showError(err));
  }

  checkView() {
    this.settings = false;
    if(this.currentView === 'settings') {
      switch (this.view) {
        case Views.desktop:
          this.showDesktopSettings();
          break;
        case Views.mobile:
          this.showSettings();
          break;
      }
    }
  }

  onViewChange(view: string) {
    this.currentView = view;
  }

  showSettings() {
    this.settings = true;
    this.currentView = 'settings'
  }

  closeSettings() {
    this.currentView = 'files'
    this.settings = false;
  }

  showDesktopSettings() {
    this.currentView = "settings";
    this.profileService.restartSettings();
  }

  searchMovement(movementId: string) {
    if (movementId) {
      this.currentView = "transactions";
    }
  }

  goToFile(idFile:string) {
    if(idFile) {
      this.router.navigate(["/file/"+idFile]);
    }
  }

  valueMySelf() {
    const config = {panelClass: "value-me"};
    const dialogRef = this.dialog.open(ValueMeComponent, config);
    dialogRef.afterClosed()
    .pipe(takeUntil(this.destroy$))
    .subscribe();
  }
}
