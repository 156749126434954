<div class="item" *ngFor="let item of downloads | async" (click)="goToFile(item)">
    <div class="body-item">
        <div class="flex-container">
            <div class="img-container">
                <img class="format-img" src="{{getImage(item)}}" alt="">
            </div>
            <div class="data-container">
                <div class="file-name ellipsis">{{item.file.title}} </div>
                <div class="file-subject ellipsis">{{item.file.subject}} </div>
                <div class="file-teacher ellipsis">{{item.file.teacher}} </div>
            </div>
        </div>
        <div class="footer">
            <div class="author">
                <span>De {{item.file.author}}</span><span class="date">Descargado {{item.timestamp}}</span>
            </div>
        </div>
    </div>
</div>
<div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()"></div>
<div *ngIf="!finished" class="item noMore">
    <app-loader [width]="'auto'" [height]="'40px'" [color]="'green'"></app-loader>
</div>
<div class="item noMore" *ngIf="finished">
    <div class="body-item">
        No hay más descargas por ahora.
    </div>
</div>