<div class="header">
    <h2>Calificar archivo</h2>
</div>
<div class="body">
    <div class="stars">
        <div class="rating" *ngIf="!currentRate">
            <span (click)="selectStar(5)">☆</span>
            <span (click)="selectStar(4)">☆</span>
            <span (click)="selectStar(3)">☆</span>
            <span (click)="selectStar(2)">☆</span>
            <span (click)="selectStar(1)">☆</span>
        </div>
        <div *ngIf="currentRate" class="selected-rating">
            <span *ngFor="let star of stars" (click)="selectStar(star.id)">
               <span *ngIf="!star.selected">☆</span> 
               <span *ngIf="star.selected">★</span>
            </span>
        </div>
    </div>
</div>
<div class="footer">
    <button class="rateButton" (click)="sendRate()">
        <span *ngIf="!loader">Enviar</span>
        <app-loader *ngIf="loader" [width]="'auto'" [height]="'20px'"></app-loader>
    </button>
</div>