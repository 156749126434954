import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-to-buy-viewer",
  templateUrl: "./to-buy-viewer.component.html",
  styleUrls: ["./to-buy-viewer.component.scss"]
})
export class ToBuyViewerComponent implements OnInit {
  @Output() onClickObtain = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  clickObtain() {
    this.onClickObtain.emit();
  }
}
