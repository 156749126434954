<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="isLoading">
    <div class="content-search-view">
        <div class="left-filters">
            <app-file-filters [mobileVersion]="false" (onClickSearch)="onClickSearch($event)"></app-file-filters>
        </div>
        <div class="right-content">
            <app-chips></app-chips>
            <app-search-items-list [searchItems]="searchItems" (onClickElement)="onClickElement($event)"></app-search-items-list>
            <div *ngIf="!finished" class="loading">
                <app-loader [width]="'auto'" [height]="'40px'" [color]="'green'"></app-loader>
            </div>
            <div *ngIf="finished && searchItems && searchItems.length" class="noMore">
                Son todos los archivos por el momento.
            </div>
            <div *ngIf="finished && (!searchItems || !searchItems.length)" class="noMore reload-data">
                <span class="emoji">😥</span>
                <span class="no-data">No se encontraron resultados.</span>
                <button class="clear-data" (click)="cleanFilters()">Limpiar los filtros y buscar de nuevo</button>
            </div>
            <div infiniteScroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="400" (scrolled)="onScroll()"></div>
        </div>
    </div>
</app-internal-component>