import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"]
})
export class VerifyEmailComponent implements OnInit {
  public mailToVerify: string = "";
  public error:string;
  public isLoading = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private title: Title
    ) {
      this.title.setTitle('Verificar email');
    }

  ngOnInit(): void {
    if (this.authService.getUser() != null) {
      this.mailToVerify = this.authService.getUser().email;
    }

  }

  sendVerifyEmail() {
    this.isLoading = true;
    this.authService
      .sendVerificationEmail()
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => (this.isLoading = false));
  }

  checkValidation() {
    this.isLoading = true;
    setTimeout(() => {
      this.authService.isEmailVerified().then(isVerified => {
        if(isVerified) {
          this.isLoading = false;
          this.router.navigate(["/searchView"]);
        } else {
          this.isLoading = false;
          this.error = "El correo sigue pendiente de verificación, revisa tu bandeja de correo."
        }
      })
    }, 1000);
  }
}
