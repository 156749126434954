import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";
import { FileService } from "src/app/file/file.service";
import { FullImgComponent } from "../full-img/full-img.component";

@Component({
  selector: "app-img-gallery",
  templateUrl: "./img-gallery.component.html",
  styleUrls: ["./img-gallery.component.scss"]
})
export class ImgGalleryComponent implements OnInit, OnDestroy {
  @Input("data") data: string[] | string;
  @ViewChild("myImage", { static: false }) image: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  public routes: string[] = [];
  public images: Blob[] = [];
  public currentImage: number = 0;
  public currentObject: string;

  constructor(private fileService: FileService, public dialog: MatDialog, private errorService: ErrorService) {}

  ngOnInit(): void {
    if (Array.isArray(this.data)) {
      this.routes = this.data;
    } else if (this.data) {
      this.routes.push(this.data);
    }
    this.getImages();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getImages() {
    const observers = this.routes.map(route =>
      this.fileService.getFileSource(route)
    );
    forkJoin(observers)
    .pipe(takeUntil(this.destroy$))
    .subscribe(result => {
      this.images = result;
      this.showImage();
    }, error => { this.errorService.showError(error)});
  }

  showImage(index: number = 0) {
    this.image.nativeElement.src = window.URL.createObjectURL(
      this.images[this.currentImage]
    );
    this.currentObject = window.URL.createObjectURL(
      this.images[this.currentImage]
    );
  }

  nextImage() {
    const newIndex = this.currentImage + 1;
    if (this.images.length > newIndex) {
      this.currentImage = newIndex;
      this.showImage(this.currentImage);
    } else {
      this.currentImage = 0;
      this.showImage(this.currentImage);
    }
  }

  previewImage() {
    if (1 <= this.currentImage) {
      this.currentImage = this.currentImage - 1;
      this.showImage(this.currentImage);
    } else {
      this.currentImage = this.images.length - 1;
      this.showImage(this.currentImage);
    }
  }

  openFileDialog() {
    if (this.images) {
      const config = {
        height: "100%",
        width: "100%",
        panelClass: "full-screen-modal",
        data: {
          currentIndex: this.currentImage,
          images: this.images
        }
      };
      const dialogRef = this.dialog.open(FullImgComponent, config);
      dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {}, error => { this.errorService.showError(error)});
    }
  }

  download() {
    window.open(this.currentObject);
  }
}
