import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { repeat, takeUntil } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { ErrorService } from "../error/error.service";
import { IUser } from "../models/user";
import { ModalUpFileComponent } from "../utility/modal-up-file/modal-up-file.component";
import { IPackageOption } from "../utility/models/models";
import { PayModalComponent } from "../utility/pay-modal/pay-modal.component";
import { YeahcoinsService } from "./yeahcoins.service";
@Component({
  selector: "app-yeahcoins",
  templateUrl: "./yeahcoins.component.html",
  styleUrls: ["./yeahcoins.component.scss"]
})
export class YeahcoinsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public user: IUser;
  public packages: Array<IPackageOption>;
  public isLoading:boolean = false;
  public selectedPackage: IPackageOption;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private yeahcoinsService: YeahcoinsService,
    private errorService: ErrorService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle('Yeahcoins');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.route.queryParams
      .subscribe(params => {
        if(params && params.merchant_order_id) {
          switch (params.status) {
            case 'approved':
              this.showMessage('approved');
              break;
            case 'in_process':
              this.showMessage('in_process');
              break;
            case 'pending':
              this.showMessage('pending');
              break;
            case 'rejected':
              const config: MatDialogConfig = {data: {type: "rejected"}};
              this.dialog.open(PayModalComponent, config);
              break;
          }
        }
      }
    );
    this.yeahcoinsService.getUserData()
    .pipe(takeUntil(this.destroy$))
    .subscribe(resp => {
      if (resp) {
        this.user = resp;
      }
    });
    this.yeahcoinsService.getPackageData().subscribe((options) => {
      this.packages = options;
    });
  }

  openFileDialog() {
    const dialogRef = this.dialog.open(ModalUpFileComponent);
    dialogRef.afterClosed()
    .pipe(takeUntil(this.destroy$))
    .subscribe(result => {
      if (result && result.uid) {
        this.router.navigate(["/file/" + result.uid]);
      }
    }, error => { this.errorService.showError(error)});
  }

  selectPackage(option:IPackageOption) {
    if(option && option.uid) {
      this.selectedPackage = option;
      this.yeahcoinsService.buyPackage(option.uid).subscribe(resp => {
        if(resp && resp.id) {
          var script = document.createElement("script");
            script.src = "https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js";
          script.type = "text/javascript";
          script.dataset.preferenceId = resp.id;
          document.getElementById("button-checkout").innerHTML = "";
          document.querySelector("#button-checkout").appendChild(script);
        }
      });
    }
  }

  showMessage(type:string) {
    this.isLoading = true;
    setTimeout(() => {
      const config: MatDialogConfig = {
        data: {
          type: type,
        }
      };
      this.isLoading = false;
      const dialogRef = this.dialog.open(PayModalComponent, config);
    }, 8000);
  }

  validPayment(idPayment:string) {
    this.isLoading = true;
    this.yeahcoinsService.validSale(idPayment).subscribe((data) => {
      this.isLoading = false;
      const config: MatDialogConfig = {
        data: {
          type: "approved",
        }
      };
      const dialogRef = this.dialog.open(PayModalComponent, config);
      dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if(result) {
          this.router.navigate(["/profile/transactions"]);
        }
      });
    }, err => {
      this.isLoading = false;
      const config: MatDialogConfig = {data: {type: "possibly-rejected"}};
      this.dialog.open(PayModalComponent, config);
      console.log(err);
    });
  }

  isPaymentInProcess(idPayment:string) {
    this.isLoading = true;
    this.yeahcoinsService.isPaymentInProcess(idPayment).subscribe((data) => {
      this.isLoading = false;
      const config: MatDialogConfig = {
        data: {
          type: "in_process",
        }
      };
      const dialogRef = this.dialog.open(PayModalComponent, config);
      dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if(result) {
          this.router.navigate(["/profile/transactions"]);
        }
      });
    }, err => {
      this.isLoading = false;
      const config: MatDialogConfig = {data: {type: "possibly-rejected"}};
      this.dialog.open(PayModalComponent, config);
      console.log(err);
    });
  }

  removeSelectedPackage() {
    this.selectedPackage = null;
  }

  buy(option:IPackageOption) {
    if(option && option.uid) {
      this.isLoading = true;
      this.yeahcoinsService.buyPackage(option.uid).subscribe(resp => {
        if(resp && resp.id) {
          var script = document.createElement("script");
            script.src = "https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js";
          script.type = "text/javascript";
          script.dataset.preferenceId = resp.id;
          document.getElementById("button-checkout").innerHTML = "";
          document.querySelector("#button-checkout").appendChild(script);
        }
      });
    }
  }
}
