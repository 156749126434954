import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from 'src/app/file/file.service';
import { IReportOption } from '../models/models';

@Component({
  selector: 'app-report-file',
  templateUrl: './report-file.component.html',
  styleUrls: ['./report-file.component.scss']
})
export class ReportFileComponent implements OnInit {

  public loader:boolean;
  public reportOptions: IReportOption[];
  public selectedOption: IReportOption;
  public error:string;
  public description:string;
  public path:string;
  dialogConfig: MatDialogConfig;

  constructor(private fileService:FileService, private dialogRef: MatDialogRef<ReportFileComponent>,  @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.fileService.getOptionReports().subscribe(res => {
      if(res) {
        this.reportOptions = res;
      }
    });
  }

  selectCheck(index:number, previewState?:boolean) {
    this.selectedOption = null;
    this.reportOptions.map(opt => opt.selected = null);
    if(!previewState) {
      this.reportOptions[index].selected = true;
      this.selectedOption = this.reportOptions[index];
    }
  }

  sendReport() {
    this.error = null;

    if(!this.selectedOption) {
      this.error = "Debes seleccionar una opción";
      return;
    }

    if(this.selectedOption && this.selectedOption.code !== '604 Repeated content') {
      if(!this.description || !this.description.length) {
        this.error = "Debes describir el problema.";
        return;
      }
      const pattern = /^[a-zA-Z0-9áéíóúý'ÁÉÍÓÚñÑ ',.:^\n\r]*$/;
      if(this.description && !pattern.test(this.description)) {
        this.error = "La descripción no puede tener caracteres especiales.";
        return;
      }
    }

    if(this.selectedOption && this.selectedOption.code === '604 Repeated content') {
      if(!this.path || !this.path.length) {
        this.error = "Debes ingresar la ruta del archivo original";
        return;
      }
      const regex = /^[a-zA-Z0-9@áéíñóúüÁÉÍÑÓÚÜñÑ]*$/g;
      if(this.path.match(regex)) {
        this.error = "La ruta debe tener el siguiente formato: www.yeahnotes.com/file/XXXX-XXXX-XXXXX-XXXXXX";
        return;
      }
    }
   
    this.fileService.addReport(this.data.uidFile,
      this.selectedOption.code, 
      this.description,
      this.path)
    .subscribe((res) => {
      this.dialogRef.close(true);
    }, err=> { 
      console.log(err);
      this.dialogRef.close();
    });
    
  }

}
