// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCxcDsNPNhuEVvrs8nyq0ugThJpC3TtNiI",
    authDomain: "yeahnotes-a8414.firebaseapp.com",
    databaseURL: "https://yeahnotes-a8414.firebaseio.com",
    projectId: "yeahnotes-a8414",
    storageBucket: "yeahnotes-a8414.appspot.com",
    messagingSenderId: "771447889948",
    appId: "1:771447889948:web:5b2fe6c3a95767d4567fd9",
    measurementId: "G-DK8KP5YSPC"
  },
  host: "https://us-central1-yeahnotes-a8414.cloudfunctions.net/app"
};

export const environmentTest = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBmFFhTpPekouY-UbzX5LH4ZcUwJurTBsg",
    authDomain: "yeahnotes-develop.firebaseapp.com",
    databaseURL: "https://yeahnotes-develop-default-rtdb.firebaseio.com",
    projectId: "yeahnotes-develop",
    storageBucket: "yeahnotes-develop.appspot.com",
    messagingSenderId: "544650275988",
    appId: "1:544650275988:web:0c4862e6b4ec60726f1881",
    measurementId: "G-C1EGT7D5JR"
  },
  host: "https://us-central1-yeahnotes-develop.cloudfunctions.net/app"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
