<div class="header">
    <h2>Reportar archivo</h2>
</div>
<div class="body" *ngIf="reportOptions">
    <label class="container" *ngFor="let option of reportOptions; let i = index;">{{option.message}}
        <input type="checkbox" checked="{{option.selected}}" (click)="selectCheck(i, option.selected)">
        <span class="checkmark"></span>
    </label>
    <span *ngIf="selectedOption && selectedOption.code !== '604 Repeated content'" class="label">Mensaje:</span>
    <textarea *ngIf="selectedOption && selectedOption.code !== '604 Repeated content'" name="description" class="textarea--block" [(ngModel)]="description" cols="30" rows="10"></textarea>
    <span *ngIf="selectedOption && selectedOption.code === '604 Repeated content'" class="label">Ruta del archivo original:</span>
    <input *ngIf="selectedOption && selectedOption.code === '604 Repeated content'" class="input-report" [(ngModel)]="path" type="text" placeholder="www.yeahnotes.com/file/XXXX-XXXX-XXXXX-XXXXXX">
</div>
<div *ngIf="error" class="warning">
    {{error}}
</div>
<div class="footer" *ngIf="reportOptions">
    <button class="reportButton" (click)="sendReport()">
        <span *ngIf="!loader">Reportar</span>
        <app-loader *ngIf="loader" [width]="'auto'" [height]="'20px'"></app-loader>
    </button>
</div>