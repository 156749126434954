<div class="comments-body">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Comentarios
                </mat-panel-title>
                <mat-panel-description>
                    {{dataComments.length}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="comment-simplebox" *ngIf="canAddComments">
                <div class="comment-simplebox-icon">
                    {{currentUser.emoji}}
                </div>
                <div class="comment-simplebox-input">
                    <mat-form-field>
                        <mat-label>Añade un comentario sobre el documento</mat-label>
                        <textarea matInput [(ngModel)]="comment"></textarea>
                    </mat-form-field>
                    <button mat-button (click)="onCancel()">Cancelar</button>
                    <button mat-button (click)="onComment()">Comentar</button>
                </div>
            </div>
            <div class="comment-simplebox" *ngIf="!canAddComments">
                Solo pueden comentar los usuarios que han adquirido el documento.
            </div>
            <div *ngFor="let comment of dataComments; let i = index" class="comment-thread-renderer">
                <div class="comment-simplebox-icon">
                    {{comment.emoji}}
                </div>
                <div class="comment-renderer">
                    <span class="comment-author">{{comment.author}}</span>
                    <p class="comment-text">{{comment.comment}}</p>
                    <div class="comment-footer">
                        <div class="time">
                            <span class="comment-published-time">{{comment.timestamp}}</span>
                        </div>
                        <div class="reactions"> 
                            <app-action-icon [icon]="'thumb_up'" [label]="comment.likes.toString()" [status]="comment.liked" [clickable]="true" (onStatusChange)="onClickLike($event, comment, i)" [restrictionMessage]="'No puedes valorar tu propio archivo'" [direction]="'horizontal'"></app-action-icon>
                            <app-action-icon [icon]="'thumb_down'" [label]="comment.dislikes.toString()" [status]="comment.disliked" [clickable]="true" (onStatusChange)="onClickDislike($event, comment, i)" [restrictionMessage]="'No puedes valorar tu propio archivo'" [direction]="'horizontal'"></app-action-icon>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>