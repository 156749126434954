import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError, from, of } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
import * as firebase from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import { ErrorService } from "../error/error.service";

@Injectable({
  providedIn: "root"
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(public afAuth: AngularFireAuth,
    private errorService: ErrorService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const token = await this.getToken().toPromise();
    const autorizationToken = "Bearer " + token;
    const reqClone = req.clone({
      setHeaders: {
        Authorization: autorizationToken
      }
    });
    return next
      .handle(reqClone)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  handleError(httpError: HttpErrorResponse) {
    //this.errorService.showError(httpError.error);
    console.warn(httpError.error);
    return throwError(httpError.error);
  }

  getToken(): Observable<any> {
    return this.afAuth.authState.pipe(
      take(1),
      switchMap(user => {
        if (user) {
          return from(user.getIdToken());
        }
        return of(null);
      })
    );
  }
}
