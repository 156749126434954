import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeLast, takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";
import { ProfileService } from "../../profile.service";

@Component({
  selector: "app-files",
  templateUrl: "./files.component.html",
  styleUrls: ["./files.component.scss"]
})
export class FilesComponent implements OnInit, OnDestroy {
  @Output() onGoToFile = new EventEmitter<string>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  public files = new BehaviorSubject([]);
  public batch: number = 15;
  public lastKey: string = "";
  public finished: boolean = false;

  constructor(private profileService: ProfileService,     
    private errorService: ErrorService,
    ) {}

  ngOnInit(): void {
    this.getFiles();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getFiles() {
    if (this.finished) {
      return;
    }
    this.profileService
      .getMyFiles(this.lastKey)
      .pipe(takeLast(1), takeUntil(this.destroy$))
      .subscribe(files => {
        if (files && files.length) {
          const lastItem = files[files.length - 1];
          if (lastItem) {
            this.lastKey = lastItem.uid;
          }
          const currentFiles = this.files.getValue();
          const lastCurrentItem = currentFiles[currentFiles.length - 1];
          if (lastCurrentItem && lastCurrentItem.uid === this.lastKey) {
            this.finished = true;
            return;
          }
          if (files.length < this.batch) {
            this.finished = true;
          }
          this.files.next(currentFiles.concat(files));
        } else {
          this.finished = true;
          const currentFiles = this.files.getValue();
          this.files.next(currentFiles);
        }
      }, error => {
        this.errorService.showError(error);
      });
  }

  goToFile(file:any) {
    if(file && file.uid) {
      this.onGoToFile.emit(file.uid);
    }
  }

  onScroll() {
    this.getFiles();
  }

  getImage(item): string {
    if (item) {
      const type = item.format || "Image";
      switch (type) {
        case "Imagen":
        case "Imagenes":
          return "/assets/img/formatIcons/IMG.png";
        case "PDF":
          return "/assets/img/formatIcons/PDF.png";
        case "DOC":
          return "/assets/img/formatIcons/DOC.png";
        case "PP":
          return "/assets/img/formatIcons/PP.png";
        case "EXCEL":
          return "/assets/img/formatIcons/EXCEL.png";
        case "ZIP":
          return "/assets/img/formatIcons/ZIP.png";
      }
    }
  }
}
