import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeLast, takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";
import { ProfileService } from "../../profile.service";

@Component({
  selector: "app-downloads",
  templateUrl: "./downloads.component.html",
  styleUrls: ["./downloads.component.scss"]
})
export class DownloadsComponent implements OnInit, OnDestroy{
  @Output() onGoToFile = new EventEmitter<string>();
  public downloads = new BehaviorSubject([]);
  public batch: number = 15;
  public lastKey: string = "";
  public finished: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private profileService: ProfileService, private errorService: ErrorService) {}

  ngOnInit(): void {
    this.getDownloads();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getDownloads() {
    if (this.finished) {
      return;
    }
    this.profileService
      .getDownloads(this.lastKey)
      .pipe(takeLast(1), takeUntil(this.destroy$))
      .subscribe(downloads => {
        if (downloads && downloads.length) {
          const lastItem = downloads[downloads.length - 1];
          if (lastItem) {
            this.lastKey = lastItem.uid;
          }
          const currentDownloads = this.downloads.getValue();
          const lastCurrentItem = currentDownloads[currentDownloads.length - 1];
          if (lastCurrentItem && lastCurrentItem.uid === this.lastKey) {
            this.finished = true;
            return;
          }
          if (downloads.length < this.batch) {
            this.finished = true;
          }
          this.downloads.next(currentDownloads.concat(downloads));
        } else {
          this.finished = true;
          const currentDownloads = this.downloads.getValue();
          this.downloads.next(currentDownloads);
        }
      }, err => this.errorService.showError(err));
  }

  onScroll() {
    this.getDownloads();
  }

  getImage(item): string {
    if (item && item.file) {
      const type = item.file.format || "Image";
      switch (type) {
        case "Imagen":
        case "Imagenes":
          return "/assets/img/formatIcons/IMG.png";
        case "PDF":
          return "/assets/img/formatIcons/PDF.png";
        case "DOC":
          return "/assets/img/formatIcons/DOC.png";
        case "PP":
          return "/assets/img/formatIcons/PP.png";
        case "EXCEL":
          return "/assets/img/formatIcons/EXCEL.png";
        case "ZIP":
          return "/assets/img/formatIcons/ZIP.png";
      }
    }
  }

  goToFile(file:any) {
    if(file && file.uidFile) {
      this.onGoToFile.emit(file.uidFile);
    }
  }
}
