<div class="container">
    <div class="login">
        <div class="logo-central"><img src="/assets/img/logo.svg" alt=""></div>
        <p class="slogan">Compartamos el conocimiento.</p>
        <div class="form-login" *ngIf="currentView === 'login'">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
                <input type="text" (change)="loginForm.get('email').markAsTouched()" [class.bad-value]="loginForm.get('email').invalid && loginForm.get('email').touched" class="input--block" formControlName='email' placeholder="Correo Electrónico">
                <p *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched" class="message-bad-value">{{loginValidatorsMessages.email.required}}</p>
                <p *ngIf="loginForm.get('email').hasError('pattern') && !loginForm.get('email').hasError('required') && loginForm.get('email').touched" class="message-bad-value">{{loginValidatorsMessages.email.pattern}}</p>
                <input type="password" (change)="loginForm.get('password').markAsTouched()" [class.bad-value]="loginForm.get('password').invalid && loginForm.get('email').touched" class="input--block" formControlName='password' placeholder="Contraseña">
                <p *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched" class="message-bad-value">{{loginValidatorsMessages.password.required}}</p>
                <p *ngIf="loginForm.get('password').hasError('minlength') && !loginForm.get('password').hasError('required') && loginForm.get('password').touched" class="message-bad-value">{{loginValidatorsMessages.password.minLength}}</p>
                <button type="submit" class="button--block mat-icon-container">
                    <mat-icon>login</mat-icon> Iniciar Sesión
                </button>
            </form>
            <div class="social-media-buttons">
                <button class="button--block facebook-button mat-icon-container" (click)="enterWithFacebook()">
                    <mat-icon>facebook</mat-icon>
                    Entrar con Facebook
                </button>
                <button class="button--block google-button mat-icon-container" (click)="enterWithGoogle()">
                    <mat-icon>public</mat-icon>
                    Entrar con Google
                </button><br>
            </div>
            <p class="label--button-sm one-rem mat-icon-container" (click)="changeFormView('recoverPass')">
                <mat-icon>vpn_key</mat-icon> Olvide mi contraseña
            </p>
            <p class="label--button" (click)="changeFormView('signUp')">¡Quiero Registrarme!</p>
        </div>
        <div class="form-login newUser" *ngIf="currentView === 'signUp'">
            <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
                <input type="text" (change)="signUpForm.get('nickname').markAsTouched()" [class.bad-value]="signUpForm.get('nickname').invalid && signUpForm.get('nickname').touched" formControlName='nickname' class="input--block" placeholder="Nickname *">
                <p *ngIf="signUpForm.get('nickname').hasError('required') && signUpForm.get('nickname').touched" class="message-bad-value">{{signUpValidatorsMessages.nickname.required}}</p>
                <p *ngIf="signUpForm.get('nickname').hasError('minlength') && signUpForm.get('nickname').touched" class="message-bad-value">{{signUpValidatorsMessages.nickname.minLength}}</p>
                <p *ngIf="signUpForm.get('nickname').hasError('maxlength') && signUpForm.get('nickname').touched" class="message-bad-value">{{signUpValidatorsMessages.nickname.maxLength}}</p>

                <input type="text" (change)="signUpForm.get('email').markAsTouched()" [class.bad-value]="(signUpForm.get('email').invalid || !isAcceptedMailFlag) && signUpForm.get('email').touched" formControlName='email' class="input--block" placeholder="Correo Electrónico *">
                <p *ngIf="signUpForm.get('email').hasError('required') && signUpForm.get('email').touched" class="message-bad-value">{{signUpValidatorsMessages.email.required}}</p>
                <p *ngIf="signUpForm.get('email').hasError('pattern') && !signUpForm.get('email').hasError('required') && signUpForm.get('email').touched" class="message-bad-value">{{signUpValidatorsMessages.email.pattern}}</p>
                <p *ngIf="!isAcceptedMailFlag && !signUpForm.get('email').hasError('required') && signUpForm.get('email').touched" class="message-bad-value">{{signUpValidatorsMessages.email.custom}}</p>

                <input type="password" (change)="signUpForm.get('password').markAsTouched()" [class.bad-value]="signUpForm.get('password').invalid && signUpForm.get('password').touched" formControlName='password' class="input--block" placeholder="Contraseña *">
                <p *ngIf="signUpForm.get('password').hasError('required') && signUpForm.get('password').touched" class="message-bad-value">{{signUpValidatorsMessages.password.required}}</p>
                <p *ngIf="signUpForm.get('password').hasError('minlength') && !signUpForm.get('password').hasError('required') && signUpForm.get('password').touched" class="message-bad-value">{{signUpValidatorsMessages.password.minLength}}</p>
                <p *ngIf="signUpForm.get('nickname').hasError('maxlength') && !signUpForm.get('password').hasError('required') && signUpForm.get('nickname').touched" class="message-bad-value">{{signUpValidatorsMessages.password.maxLength}}</p>
                <button type="submit" class="button--block mat-icon-container"><mat-icon>how_to_reg</mat-icon> Registrarme</button>
            </form>
            <div class="social-media-buttons">
                <button class="button--block facebook-button mat-icon-container" (click)="enterWithFacebook()">
                    <mat-icon>facebook</mat-icon>
                    Entrar con Facebook
                </button>
                <button class="button--block google-button mat-icon-container" (click)="enterWithGoogle()">
                    <mat-icon>public</mat-icon>
                    Entrar con Google
                </button><br>
            </div>
            <p class="label--button-sm one-rem" routerLink="/conditions" routerLinkActive="active">
                <mat-icon>check_circle</mat-icon> Al registrarte en YeahNotes aceptas nuestros terminos y condiciones.</p>
            <p class="label--button" (click)="changeFormView('login')">¡Ya tengo cuenta!</p>
        </div>
        <div class="form-login" *ngIf="currentView === 'recoverPass'">
            <div *ngIf="!emailSent">
                <p class="label--button">Recuperar Contraseña</p>
                <form [formGroup]="recoverPassForm" (ngSubmit)="recoverPassword()">
                    <input type="text" (change)="recoverPassForm.get('email').markAsTouched()" [class.bad-value]="recoverPassForm.get('email').invalid && recoverPassForm.get('email').touched" class="input--block" formControlName='email' placeholder="Correo Electrónico">
                    <p *ngIf="recoverPassForm.get('email').hasError('required') && recoverPassForm.get('email').touched" class="message-bad-value">{{recoverPasswordValidatorsMessages.email.required}}</p>
                    <p *ngIf="recoverPassForm.get('email').hasError('pattern') && !recoverPassForm.get('email').hasError('required') && recoverPassForm.get('email').touched" class="message-bad-value">{{recoverPasswordValidatorsMessages.email.pattern}}</p>
                    <button type="submit" class="button--block mat-icon-container">
                        <mat-icon>send</mat-icon> Enviar Correo
                    </button><br>
                </form>
                <p class="label--button-sm one-rem mat-icon-container" (click)="changeFormView('login')">
                    <mat-icon>login</mat-icon> Iniciar Sesión
                </p>
            </div>
            <div *ngIf="emailSent">
                <img src="/assets/img/checked.png" alt="">
                <p class="label--button">¡Correo Enviado!</p>
                <p class="font-normal">Enviamos un correo a tu cuenta para restablecer tu contraseña.</p>
                <p class="font-normal">¿No recibiste el correo?, <span (click)="recoverPassword()" class="link-text">envíalo de nuevo.</span></p>
                <button class="button--block" (click)="changeFormView('login')">Iniciar Sesión</button>
            </div>
        </div>
    </div>
</div>
<app-full-loader *ngIf="isLoading"></app-full-loader>