<header [ngClass]="{'searching': searching && !validValue }">
    <div class="logo" routerLink="/searchView" *ngIf="!searching"><img src="/assets/img/logo.svg" alt=""></div>
    <div class="logo-sm" routerLink="/searchView" *ngIf="searching && validValue"><img src="/assets/img/logo-sm.svg" alt=""></div>
    <div class="logo hidden" routerLink="/searchView" *ngIf="searching && validValue"><img src="/assets/img/logo.svg" alt=""></div>
    <div *ngIf="searching && !validValue" class="search-view">
        <button class="header-button" (click)="setChangeView()"><mat-icon>navigate_before</mat-icon></button>
        <ng-select id="ngSelectComponentMobile" class="custom" [(ngModel)]="selectedOption" [items]="options" [addTag]="true" addTagText="" [loading]="false" [markFirst]="false" [dropdownPosition]="'bottom'" loadingText="Buscando" editableSearchTerm="true" placeholder="Buscar en YeahNotes"
            bindLabel="label" (change)="onOptionSelected($event)"></ng-select>
    </div>
    <div *ngIf="searching && validValue" class="search-bar mobile-v">
        <div class="search-bar-text" (click)="newSearch()">
            <label class="parameter-label">{{searchParameterLabel}}</label>
            <div class="showFilters">
                <mat-icon (click)="showFilters($event)">tune</mat-icon>
            </div>
        </div>
    </div>

    <div class="input-search" *ngIf="!blockActions">
        <ng-select id="ngSelectComponentWeb" class="yn-search-input" [(ngModel)]="selectedOption" [items]="options" [addTag]="true" addTagText="" [loading]="false" [markFirst]="false" [dropdownPosition]="'bottom'" loadingText="Buscando" editableSearchTerm="true" placeholder="Buscar en YeahNotes"
            bindLabel="label" (change)="onOptionSelected($event)"></ng-select>
    </div>

    <div class="button-actions" *ngIf="!blockActions">
        <button *ngIf="!searching && !idSelectedOption" class="header-button" (click)="setChangeView()"><mat-icon>search</mat-icon></button>
        <div *ngIf="!searching || (searching && validValue)" class="btn-ellipsis">
            <button class="hide-profile" (click)="openFileDialog()">
                <mat-icon>cloud_upload</mat-icon>
            </button>
            <button class="hide-profile" routerLink="/yeahcoins">
                <img class="yeahcoin-s" src="/assets/img/yeahcoin.svg" alt="">
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="hide-profile">
                {{currentEmoji}}
            </button>
            <button class="ellipsis-buttton" mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon class="white-icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button class="options-m" mat-menu-item (click)="showFilters($event)">
                    <mat-icon>filter_alt</mat-icon>
                    <span> Filtros de Busqueda</span>
                </button>
                <button class="options-m" mat-menu-item (click)="openFileDialog()">
                    <mat-icon>upload_file</mat-icon>
                    <span> Subir Archivo</span>
                </button>
                <button class="options-m" mat-menu-item routerLink="/yeahcoins">
                    <mat-icon>attach_money</mat-icon>
                    <span> Obtener YeahCoins</span>
                </button>
                <button mat-menu-item routerLink="/profile">
                    <mat-icon>person</mat-icon>
                    <span> Mi Perfil</span>
                </button>
                <button mat-menu-item routerLink="/videos">
                    <mat-icon>play_arrow</mat-icon>
                    <span> Ver un video</span>
                </button>
                <button mat-menu-item [routerLink]="['/profile', 'settings']">
                    <mat-icon>settings</mat-icon>
                    <span> Ajustes</span>
                </button>
                <button (click)="logout()" mat-menu-item>
                    <mat-icon>logout</mat-icon>
                    <span> Cerrar Sesión</span>
                </button>
            </mat-menu>
        </div>
    </div>
</header>