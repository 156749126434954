import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  AngularFireStorage,
  AngularFireUploadTask,
  AngularFireStorageReference
} from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { host } from "../constant";
import {
  IElementFile,
  IFileFiltersData,
  IFullFile
} from "../utility/models/models";
import { Observable } from "rxjs";
import * as firebase from "firebase";
import { FiltersService } from "../utility/file-filters/filters.service";
import { AuthService } from "../auth/auth.service";
import { MessagingService } from "../utility/messaging/messaging.service";

@Injectable({
  providedIn: "root"
})
export class SearchService {
  public searchParameter: string;
  private currentFileRef: AngularFireStorageReference;
  private host: string = host;

  constructor(
    private firestore: AngularFirestore,
    private angularFireStorage: AngularFireStorage,
    private http: HttpClient,
    private filtersService: FiltersService,
    private authService: AuthService,
  ) {}

  public getSearchParameter(): string {
    return this.searchParameter; 
  }

  public setSearchParameter(value: string) {
    this.searchParameter = value;
  }

  public getFilesByFilters(
    filters?: IFileFiltersData,
    lastKey?: string
  ): Observable<IElementFile[]> {
    if (!filters) {
      filters = this.filtersService.getCurrentFilters();
    }
    let route: string = "";
    const filtersString = [];

    // Order Filters
    const orderByField =
      filters && filters.orderByField ? filters.orderByField : "timestamp";
    const orderByDir =
      filters && filters.orderByDir ? filters.orderByDir : "desc";
    const orderPart = `?orderByField=${orderByField}&orderByDir=${orderByDir}`;
    route = `${host}/files${orderPart}`;

    // Content Filters
    if (filters) {
      if (filters.format) {
        filters.format.forEach(element => {
          filtersString.push(`&format=${element.idElement}`);
        });
      }
      if (filters.score) {
        filtersString.push(`&score=${filters.score}`);
      }
      if (filters.type) {
        filters.type.forEach(element => {
          filtersString.push(`&uidType=${element.idElement}`);
        });
      }
      if (filters.uidCareer) {
        filtersString.push(`&uidCareer=${filters.uidCareer}`);
      }
      if (filters.uidSubject) {
        filtersString.push(`&uidSubject=${filters.uidSubject}`);
      }
      if (filters.uidTeacher) {
        filtersString.push(`&uidTeacher=${filters.uidTeacher}`);
      }

      if (filters.uidSchool) {
        filtersString.push(`&uidSchool=${filters.uidSchool}`);
      }

      if(filters.search) {
        filtersString.push(`&search=${filters.search}`);
      }
    }

    if (!filtersString.length) {
      const schoolID = this.authService.getUser().school;
      filtersString.push(`&uidSchool=${schoolID}`);
    }

    if (lastKey) {
      filtersString.push(`&last=${lastKey}`);
    }

    let filtersPart: string = "";
    filtersString.forEach(part => (filtersPart = filtersPart + part));
    route = `${route}${filtersPart}`;

    return this.http.get<IElementFile[]>(route);
  }

  public getFileById(uid: string = ""): Observable<IFullFile> {
    const route = `${host}/files/${uid}`;
    return this.http.get<IFullFile>(route);
  }

  public getCurrentFileRef(): AngularFireStorageReference {
    return this.currentFileRef;
  }

  public uploadFile(file: File): AngularFireUploadTask {
    const filePath = `files/userId/${file.name}`;
    const ref = this.angularFireStorage.ref(filePath);
    const fileRef = this.angularFireStorage.ref(filePath);
    this.currentFileRef = fileRef;
    return this.angularFireStorage.upload(filePath, file);
  }
}
