import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit, OnChanges {

  @Output() onValueChange = new EventEmitter<number>();
  @Input("preselectedStar") preselectedStar: number = 1;

  starOptions = [
    {value:1, status:false},
    {value:2, status:false},
    {value:3, status:false},
    {value:4, status:false},
    {value:5, status:false},
  ];

  constructor() { }

  ngOnInit(): void {
    const preStar = this.preselectedStar || 1;
    this.loadFirstStars(preStar);
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes && changes.preselectedStar && changes.preselectedStar.currentValue) {
      this.preselectedStar = changes.preselectedStar.currentValue;
      this.loadFirstStars(this.preselectedStar);
    }
  }

  loadFirstStars(index:number) {
    const stars = this.starOptions.map(elm => {
      elm.status = elm.value >= index ? true : false; 
      return elm;
    });
    this.starOptions = stars;
  }

  onClickStar(star) {
    if(star && star.value) {
      const stars = this.starOptions.map(elm => {
        elm.status = elm.value >= star.value ? true : false; 
        return elm;
      });
      this.starOptions = stars;
      this.onValueChange.emit(star.value);
    }
  }

}
