<div *ngIf="!isLoading && currentView === 'FILE'" class="file">
    <h2 mat-dialog-title>Subir Archivo</h2>
    <mat-dialog-content>
        <div>
            <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="handleFileInput($event)">
                <mat-icon class="icon-file">upload_file</mat-icon>
                <p class="file-text">Arrastra y suelta los archivos aqui para subirlos</p>
                <label class="okButton">Seleccionar Archivo</label>
                <input hidden type="file" #fileInput (change)="handleFileInput($event.target.files)">
            </div>
            <div class="files-content" *ngFor="let element of filesToShow;let i= index">
                <div class="files-list" [ngClass]="{'invalidFile': !element.hasValidType || !element.hasValidSize}">
                    <div class="file-data">
                        <p> {{ element.file.name }} </p>
                        <p> {{ element.file.size | sizeFile}} </p>
                    </div>
                    <button class="delete-file" (click)="deleteAttachment(i)">
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                </div>
                <div class="file-errors">
                    <div *ngIf="!element.hasValidType" class="error">* <span class="bold">{{ element.file.name }}</span> tiene un formato invalido, no aceptado o esta dañado. Si quieres agregar multiples archivos a la vez todos deben ser imagenes.</div>
                    <div *ngIf="!element.hasValidSize" class="error">* <span class="bold">{{ element.file.name }}</span> tiene un tamaño demasiado grande. El limite permitido es de 5MB, porfavor convierte tu archivo a una versión comprimida.</div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button *ngIf="filesToUploadValid" class="okButton" color="primary" (click)="next()" mat-button>Continuar <mat-icon>arrow_forward</mat-icon></button>
        <button color="warn" mat-button mat-dialog-close><mat-icon>close</mat-icon> Cancelar</button>
    </mat-dialog-actions>
</div>

<div *ngIf="!isLoading && currentView === 'TITLE'" class="title">
    <h2 mat-dialog-title>Subir Archivo</h2>
    <mat-dialog-content>
        <div class="title-section">
            <form [formGroup]="fileTitleForm">
                <input type="text" (change)="fileTitleForm.get('title').markAsTouched()" [class.bad-value]="fileTitleForm.get('title').invalid && fileTitleForm.get('title').touched" class="input--block" formControlName='title' placeholder="Titulo del Archivo">
                <p *ngIf="fileTitleForm.get('title').hasError('required') && fileTitleForm.get('title').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.title.required}}</p>
                <p *ngIf="fileTitleForm.get('title').hasError('pattern') && !fileTitleForm.get('title').hasError('required') && fileTitleForm.get('title').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.title.pattern}}</p>
                <p *ngIf="fileTitleForm.get('title').hasError('minlength') && !fileTitleForm.get('title').hasError('required') && fileTitleForm.get('title').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.title.minLength}}</p>
                <p *ngIf="fileTitleForm.get('title').hasError('maxlength') && !fileTitleForm.get('title').hasError('required') && fileTitleForm.get('title').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.title.maxLength}}</p>
                <span class="desc">Precio:</span>
                <input type="number" (change)="fileTitleForm.get('price').markAsTouched()" [class.bad-value]="fileTitleForm.get('price').invalid && fileTitleForm.get('price').touched" class="input--block" formControlName='price' maxlength="4" placeholder="Precio en yeahcoins">
                <p *ngIf="fileTitleForm.get('price').hasError('required') && fileTitleForm.get('price').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.price.required}}</p>
                <p *ngIf="fileTitleForm.get('price').hasError('pattern') && !fileTitleForm.get('price').hasError('required') && fileTitleForm.get('price').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.price.pattern}}</p>
                <p *ngIf="fileTitleForm.get('price').hasError('min') && !fileTitleForm.get('price').hasError('required') && fileTitleForm.get('price').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.price.min}}</p>
                <p *ngIf="fileTitleForm.get('price').hasError('max') && !fileTitleForm.get('price').hasError('required') && fileTitleForm.get('price').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.price.max}}</p>
                <span class="desc">Descripción:</span>
                <textarea name="description" class="textarea--block" (change)="fileTitleForm.get('description').markAsTouched()" id="description" formControlName='description' cols="30" rows="10"></textarea>
                <p *ngIf="fileTitleForm.get('description').hasError('required') && fileTitleForm.get('description').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.description.required}}</p>
                <p *ngIf="fileTitleForm.get('description').hasError('pattern') && !fileTitleForm.get('description').hasError('required') && fileTitleForm.get('description').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.description.pattern}}</p>
                <p *ngIf="fileTitleForm.get('description').hasError('minlength') && !fileTitleForm.get('description').hasError('required') && fileTitleForm.get('description').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.description.minLength}}</p>
                <p *ngIf="fileTitleForm.get('description').hasError('maxlength') && !fileTitleForm.get('description').hasError('required') && fileTitleForm.get('description').touched" class="message-bad-value">{{fileTitleFormValidatorsMessages.description.maxLength}}</p>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button color="accent" (click)="preview()" mat-button><mat-icon>arrow_back</mat-icon> Regresar</button>
        <button form="titleForm" (click)="onTitleFormSubmit()" color="primary" mat-button>Continuar <mat-icon>arrow_forward</mat-icon></button>
        <button color="warn" mat-button mat-dialog-close><mat-icon>close</mat-icon> Cancelar</button>
    </mat-dialog-actions>
</div>

<div *ngIf="!isLoading && currentView === 'DATA'" class="data">
    <h2 mat-dialog-title>Subir Archivo</h2>
    <mat-dialog-content>
        <div class="title-section">
            <div class="filter-section">
                <h3 class="no-mg">Escuela: </h3>
                <ng-select *ngIf="canEditSchool" placeholder="Mi escuela" class="custom" [items]="schools" [(ngModel)]="schoolSelected" (ngModelChange)="onSchoolSelected($event)" bindLabel="label"></ng-select>
                <div *ngIf="!canEditSchool" class="row">
                    <p>{{schoolToShow}}</p>
                    <button class="edit-button" (click)="changeCanEditSchool()"><mat-icon>mode_edit</mat-icon></button>
                </div>
                <div *ngIf="isDataTouched && !schoolSelected" class="error-message">
                    <p>Debes indicar la escuela a la que pertenece.</p>
                </div>
            </div>
            <div class="filter-section">
                <h3 class="no-mg">Carrera: </h3>
                <ng-select placeholder="Todas las carreras" class="custom" [items]="careers" [(ngModel)]="careerSelected" notFoundText="Carrera no encontrada" bindLabel="name"></ng-select>
                <div *ngIf="isDataTouched && !careerSelected" class="error-message">
                    <p>Debes indicar la carrera a la que pertenece.</p>
                </div>
            </div>
            <div class="filter-section">
                <h3 class="no-mg">Materia: </h3>
                <ng-select placeholder="Todas las materias" class="custom" [items]="subjects" [(ngModel)]="subjectSelected" (ngModelChange)="onSubjectSelected($event)" [addTag]="true" addTagText="Agregar Materia:" bindLabel="name"></ng-select>
                <div *ngIf="isDataTouched && !subjectSelected" class="error-message">
                    <p>Debes indicar la materia del archivo.</p>
                </div>
                <div *ngIf="subjectSelected && !isValidCurrentSubject" class="error-message">
                    <p>Materia Invalida, no se aceptan caracteres especiales.</p>
                </div>
            </div>
            <div class="filter-section">
                <h3 class="no-mg">Profesor: </h3>
                <ng-select placeholder="Todos los profesores" class="custom" [items]="teachers" [(ngModel)]="teacherSelected" (ngModelChange)="onTeacherSelected($event)" [addTag]="true" addTagText="Agregar Docente:" bindLabel="teacher"></ng-select>
                <div *ngIf="isDataTouched && !teacherSelected" class="error-message">
                    <p>Debes indicar el profesor del archivo.</p>
                </div>
                <div *ngIf="teacherSelected && !isValidCurrentTeacher" class="error-message">
                    <p>Nombre invalido: Debe tener al menos un nombre y dos apellidos iniciando con mayuscula sin abreviaciones.</p>
                </div>
            </div>
            <div class="filter-section">
                <h3 class="no-mg">Tipo: </h3>
                <app-checkbox-chips-list [chips]="typeOptions" [multipleChoice]="false" (onSelectionChange)="onTypeSelected($event)"></app-checkbox-chips-list>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button color="accent" (click)="preview()" mat-button><mat-icon>arrow_back</mat-icon> Regresar</button>
        <button form="titleForm" (click)="validData()" color="primary" mat-button>Subir Archivo <mat-icon>upload_file</mat-icon></button>
        <button color="warn" mat-button mat-dialog-close><mat-icon>close</mat-icon>  Cancelar</button>
    </mat-dialog-actions>
</div>

<div *ngIf="isLoading" class="data">
    <h2 mat-dialog-title>Subiendo Archivo...</h2>
    <mat-dialog-content>
        <div *ngIf="isLoading" class="loader">
            <app-loader [width]="120" [height]="120" [color]="'green'"></app-loader>
            <div class="legend">Está acción puede demorar varios segundos, no actualices ni cierres la pagina.</div>
        </div>
    </mat-dialog-content>
</div>