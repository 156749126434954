import { Component, HostListener, Input, OnInit } from "@angular/core";
import { IAutorCatalogue } from "../models/models";
import { Views } from "src/app/file/file.component";

@Component({
  selector: "app-author",
  templateUrl: "./author.component.html",
  styleUrls: ["./author.component.scss"]
})
export class AuthorComponent implements OnInit {
  @Input("data") dataAuthor: IAutorCatalogue;
  public view: string = Views.mobile;
  public innerWidth: any;
  public fullStars = [];
  public restStars = [];

  constructor() {
    this.innerWidth = window.innerWidth;
    this.buildView();
  }

  ngOnInit(): void {
    const countFullStars = Math.floor(+this.dataAuthor.score);
    this.fullStars = Array(countFullStars)
      .fill(0)
      .map((x, i) => i);
    this.restStars = Array(5 - countFullStars)
      .fill(0)
      .map((x, i) => i);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.buildView();
  }

  buildView() {
    if (this.innerWidth >= 830) {
      this.view = Views.desktop;
    } else {
      this.view = Views.mobile;
    }
  }
}
