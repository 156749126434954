<h2 mat-dialog-title *ngIf="title">{{title}}</h2>
<mat-dialog-content>
    <div *ngIf="textBody" class="text-dialog">
        {{textBody}}
    </div>
    <div class="buttons">
        <button (click)="onButtonClicked(option.value)" class="btn-modal" *ngFor="let option of buttonOptions" [style.color]="option.color" [style.background]="option.background">
            {{option.label}}
        </button>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="textButton">
    <button class="searchButton">{{textButton}}</button>
</mat-dialog-actions>