<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="isLoading">
    <div class="content">
        <div class="content-header">
            <h2 *ngIf="!institutionSelected">Institución</h2>
            <h2 *ngIf="institutionSelected">Escuelas</h2>
        </div>
        <div class="content-body">
            <div *ngIf="institutionSelected && schools" class="btn-container">
                <button (click)="removeSelection()" class="btn mat-icon-container">
                    <mat-icon>navigate_before</mat-icon> Regresar a las instituciones
                </button>
            </div>
            <div *ngIf="institutionSelected && schools" class="search-box">
                <input type="text" class="input-search" [(ngModel)]="searchParameter" (keyup)="changeSchoolToShow()" placeholder="Buscar Escuela">
            </div>
            <div *ngIf="institutions && !institutionSelected" class="elements">
                <div *ngFor="let institution of institutions" class="element" (click)="selectInstitution(institution)">
                    <img src="{{institution.img}}">
                    <span class="element-name-i">{{institution.nickname}}</span>
                </div>
            </div>
            <div *ngIf="institutionSelected && schools" class="sm-elements">
                <div *ngFor="let school of schoolsToShow;" class="sm-element">
                    <div *ngIf="!schoolSelected || schoolSelected.uid !== school.uid" (click)="selectSchool(school)" class="sm-body">
                        <img class="img-school" src="/assets/img/schools/{{school.img}}">
                        <div class="right">
                            <span class="element-name">{{school.nickname}}</span>
                            <span class="element-nickname">{{school.name}}</span>
                        </div>
                    </div>
                    <div *ngIf="schoolSelected && schoolSelected.uid === school.uid" class="careers-contaimer">
                        <label class="instructions-career">Selecciona una carrera</label>
                        <div *ngIf="careers && careers.length" class="careers">
                            <ng-select placeholder="Carreras" class="custom" [items]="careers" [(ngModel)]="careerSelected" bindLabel="name"></ng-select>
                            <button class="accept-button" (click)="saveSchoolPreferences()">Seleccionar</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!schoolsToShow.length && schools.length" class="empty">
                    Por el momento no hay escuelas con esa descripción.
                </div>
                <div *ngIf="!schools.length" class="empty">
                    Por el momento no hay escuelas registradas de esta institución.
                </div>
            </div>
        </div>
        <div class="footer">
            <a class="contact" target="_blank" href="https://www.facebook.com/yeahnotes">
                ¿Tu escuela o institución no está? ¡Contáctanos!
            </a>
        </div>
    </div>
</app-internal-component>