<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="isLoading">
    <div class="body-content-yc">
        <div class="yc-section card-container">
            <div class="left">
                <div class="card">
                    <div class="name" *ngIf="user">{{user.nickname}}</div>
                    <app-loader *ngIf="!user" [color]="'green'" [width]="'30px'" [height]="'30px'"></app-loader>
                </div>
            </div>
            <div class="right">
                <h2>Mi Cuenta</h2>
                <p *ngIf="user"><span class="bold">Registrado desde:</span> {{user.timestamp}}</p>
                <app-loader *ngIf="!user" [color]="'green'" [width]="'30px'" [height]="'30px'" ></app-loader>
                <div class="yc-container">
                    <img alt="" class="yeahcoin-small" src="/assets/img/yeahcoin.svg">
                    <span *ngIf="user">{{user.yeahcoins}} yeahcoins</span>
                    <app-loader *ngIf="!user" [color]="'green'" [width]="'30px'" [height]="'30px'"></app-loader>
                </div>
                <div class="legend">
                    Los yeahcoins son la moneda oficial de la plataforma, para poder descargar todos los documentos que necesites.
                </div>
            </div>
        </div>
        <div class="yc-section">
            <h2 class="question">¿Como obtengo más yeahcoins?</h2>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Ver un video
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-collapse">Una forma fácil de obtener yeahcoins es viendo videos de nuestros patrocinadores. Al termino del video obtendras los yeahcoins patrocinados. ¡Así de fácil!<span class="link mat-icon-container" routerLink="/videos"><mat-icon>videocam</mat-icon> Ver un video ahora mismo</span>.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Subir un archivo
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-collapse">Otra manera de conseguir yeahcoins es subiendo archivos, al subir un archivo obtendras yeahcoins gratis y cada vez que alguien descargue tu archivo recibiras yeahcoins por cada descarga, pero ten cuidado de subir material util porque
                        si te reportan tu cuenta podrias quedar bloqueado y perderas todos tus yeahcoins.
                        <br><br> Animate y contribuye a esta gran comunidad
                        <span class="link mat-icon-container" (click)="openFileDialog()"><mat-icon>backup</mat-icon> Subir un archivo ahora</span>.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Subir de Categoria
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-collapse ">YeahNotes tiene un sistema de categorias para clasificar a sus usuarios, actualmente existen cinco  <span routerLink="/how-ratings-work" class="link">Hierro, Bronce, Plata, Oro y Rubí</span> entre mejor sea tu categoria obtendras yeahcoins más fácil y rápido.</p>
                    <br><span routerLink="/how-ratings-work" class="link" >Descubre como funcionan las categorias</span>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="yc-section">
            <h2 class="question">¿Puedo comprar yeahcoins?</h2>
            <div class="legend">¡Claro!, si necesitas yeahcoins de forma inmediata puedes adquirir alguno de nuestros paquetes: </div>
            <div *ngIf="!packages || !packages.length" class="options-to-buy">
                <div class="item-to-buy">
                    <div class="img-container">
                        <img class="img-option" src="/assets/img/coinsOptions/option1.svg">
                    </div>
                    <div class="yc-number">100 yeahcoins</div>
                    <button disabled class="buy-yc gray">
                        <app-loader [width]="'22px'" [height]="'22px'"></app-loader>
                    </button>
                </div>
                <div class="item-to-buy">
                    <div class="img-container">
                        <img class="img-option" src="/assets/img/coinsOptions/option2.svg">
                    </div>
                    <div class="yc-number">500 yeahcoins</div>
                    <button disabled class="buy-yc gray">
                        <app-loader [width]="'22px'" [height]="'22px'"></app-loader>
                    </button>
                </div>
                <div class="item-to-buy">
                    <div class="img-container">
                        <img class="img-option gray" src="/assets/img/coinsOptions/option3.svg">
                    </div>
                    <div class="yc-number">1,200 yeahcoins</div>
                    <button disabled class="buy-yc gray">
                        <app-loader [width]="'22px'" [height]="'22px'"></app-loader>
                    </button>                </div>
                <div class="item-to-buy">
                    <div class="img-container">
                        <img class="img-option" src="/assets/img/coinsOptions/option4.svg">
                    </div>
                    <div class="yc-number">2,500 yeahcoins</div>
                    <button disabled class="buy-yc gray">
                        <app-loader [width]="'22px'" [height]="'22px'"></app-loader>
                    </button>                </div>
                <div class="item-to-buy">
                    <div class="img-container">
                        <img class="img-option gray" src="/assets/img/coinsOptions/option5.svg">
                    </div>
                    <div class="yc-number">5,200 yeahcoins</div>
                    <button disabled class="buy-yc gray">
                        <app-loader [width]="'22px'" [height]="'22px'"></app-loader>
                    </button>                </div>
                <div class="item-to-buy">
                    <div class="img-container">
                        <img class="img-option" src="/assets/img/coinsOptions/option6.svg">
                    </div>
                    <div class="yc-number">14,500 yeahcoins</div>
                    <button disabled class="buy-yc gray">
                        <app-loader [width]="'22px'" [height]="'22px'"></app-loader>
                    </button>                </div>
            </div>
            <div *ngIf="packages && packages.length && !selectedPackage" class="options-to-buy">
                <div *ngFor="let option of packages" class="item-to-buy">
                    <div class="img-container">
                        <img class="img-option" src="/assets/img/coinsOptions/{{option.img}}.svg">
                    </div>
                    <div class="yc-number">{{option.yeahcoins}} yeahcoins</div>
                    <button class="buy-yc" (click)="selectPackage(option)">Obtener por ${{option.price}}</button>
                </div>
            </div>
            <div *ngIf="selectedPackage" class="selected-package">
                <div class="container-selected">
                    <div class="img-container">
                        <img class="img-option" src="/assets/img/coinsOptions/{{selectedPackage.img}}.svg">
                    </div>
                    <div class="selected-desc">
                        Comprar YeahCoins
                        <div class="text-desc">
                            Estas a un paso de obtener <span class="highlight">{{selectedPackage.yeahcoins}} yeahcoins</span> por solo <span class="highlight">${{selectedPackage.price}}</span> pesos.
                        </div>
                        <div id="button-checkout"></div>
                        <div class="come-back" (click)="removeSelectedPackage()">
                            &lt;&lt; Regresar a los paquetes
                        </div>
                    </div>
                </div>
            </div>
            <div class="legend end-pad">Podras usar tus yeahcoins adquiridos siempre que quieras, no tienen caducidad ni limite de compra. </div>
        </div>
    </div>
</app-internal-component>