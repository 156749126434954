import { IUser } from "../../../models/user";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";

export enum PROFILE_VIEWS {
  FILES = "files",
  DOWNLOADS = "downloads",
  TRANSACTIONS = "transactions",
  NOTIFICACTIONS = "notifications"
}

@Component({
  selector: "app-header-profile",
  templateUrl: "./header-profile.component.html",
  styleUrls: ["./header-profile.component.scss"]
})
export class HeaderProfileComponent implements OnInit, OnChanges{
  @Output() onViewChange = new EventEmitter<string>();
  @Output() onSettings = new EventEmitter<string>();
  @Output() onOpenCategory = new EventEmitter<boolean>();

  @Input("currentView") parentView: string;
  @Input("identity") identity?: string;
  @Input("emoji") emoji?: string;

  currentView: string = PROFILE_VIEWS.FILES;
  navFixed: boolean = false;
  public currentUser: IUser;
  private scrollOffset: number = 205;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (this.parentView) {
      this.currentView = this.parentView;
    }
    this.currentUser = this.authService.getUser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.parentView && changes.parentView.currentValue 
      && changes.parentView.currentValue === "settings") {
        this.currentView = changes.parentView.currentValue
    }
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    this.navFixed =
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0) > this.scrollOffset;
  }

  changeView(view: string) {
    this.currentView = view;
    this.onViewChange.emit(view);
  }

  showSettings() {
    this.onSettings.emit();
  }

  goToCategory() {
    this.onOpenCategory.emit(true);
  }
}
