<div class="header-imgaes">
    <div class="actions-buttons">
        <button class="action-button-file" (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
        <button class="action-button-file" (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
        <button class="action-button-file" (click)="rotate()"><mat-icon>refresh</mat-icon></button>
        <button class="action-button-file" (click)="download()"><mat-icon>sim_card_download</mat-icon></button>
    </div>
    <div class="numberOfEelements">
        <button class="action-button-file" (click)="preview()"><mat-icon>navigate_before</mat-icon></button> {{currentImage +1}} / {{images.length}}
        <button class="action-button-file" (click)="next()"><mat-icon>navigate_next</mat-icon></button>
    </div>
    <div class="close-full-view">
        <button class="action-button-file" (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
</div>
<div class="container-img">
    <span class="helper"></span>
    <img class="full-img-container" #FullImageContainer>
</div>