import { Component, OnDestroy, OnInit } from "@angular/core";
import { SelectSchoolService } from "./select-school.service";
import { AuthService } from "../auth/auth.service";
import { FiltersService } from "../utility/file-filters/filters.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "../error/error.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-select-school",
  templateUrl: "./select-school.component.html",
  styleUrls: ["./select-school.component.scss"]
})
export class SelectSchoolComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = true;
  public institutions = [];
  public schools = [];
  public schoolsToShow = [];
  public careers = [];
  public searchParameter: string;
  public institutionSelected: string;
  public careerSelected;
  public schoolSelected;

  constructor(
    private selectSchoolService: SelectSchoolService,
    private authService: AuthService,
    private filtersService: FiltersService,
    private errorService: ErrorService,
    private title: Title
  ) {
    this.title.setTitle('Seleccionar escuela');
  }

  ngOnInit(): void {
    this.selectSchoolService.getInstitutions()
    .pipe(takeUntil(this.destroy$))
    .subscribe(catsSnapshot => {
      this.institutions = [];
      catsSnapshot.forEach((institution: any) => {
        this.institutions.push(institution.payload.doc.data());
      });
      this.isLoading = false;
    }, error => {
      this.errorService.showError(error);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  saveSchoolPreferences() {
    this.careerSelected;
    this.authService.updateCurrentUser({
      school: this.schoolSelected.uid,
      institution: this.schoolSelected.uidInstitution,
      identity: this.schoolSelected.identity,
      career: this.careerSelected.uid,
      filterPreferences: {
        school: {
          uid: this.schoolSelected.uid,
          nickname: this.schoolSelected.nickname,
          name: this.schoolSelected.name,
        },
        career: {
          uid: this.careerSelected.uid,
          name: this.careerSelected.name,
        }
      }
    });
  }

  showCareers(school: any) {
    this.careerSelected = null;
    this.schoolSelected = school;
    this.schoolsToShow = this.schoolsToShow.map(s => {
      s.selected = s.uid === school.uid;
      return s;
    });
  }

  selectSchool(school: any) {
    if (school) {
      this.careers = [];
      this.schoolSelected = school;
      this.filtersService.getCareers(school.careers)
      .pipe(takeUntil(this.destroy$))
      .subscribe(catsSnapshot => {
        this.careers = [];
        catsSnapshot.forEach((institution: any) => {
          const data = institution.data();
          this.careers.push(data);
        });
      }, error => {
        this.errorService.showError(error);
      });
    }
  }

  selectInstitution(institution: any) {
    this.institutionSelected = institution.uid;
    this.isLoading = true;
    this.selectSchoolService
      .getSchoolsByInstitution(institution.uid)
      .pipe(takeUntil(this.destroy$))
      .subscribe(catsSnapshot => {
        this.schools = [];
        catsSnapshot.forEach((school: any) => {
          this.schools.push(school.payload.doc.data());
        });
        this.changeSchoolToShow();
        this.isLoading = false;
      }, error => {
        this.errorService.showError(error);
      });
  }

  removeSelection() {
    this.institutionSelected = null;
    this.schools = [];
  }

  changeSchoolToShow() {
    this.schoolsToShow = [];
    const parameter = this.searchParameter
      ? this.cleanString(this.searchParameter)
      : "";
    if (!this.searchParameter || !parameter.length) {
      this.schoolsToShow = this.schools;
    } else {
      this.schoolsToShow = this.schools.filter(school => {
        if (
          this.cleanString(school.name).indexOf(parameter) !== -1 ||
          this.cleanString(school.nickname).indexOf(parameter) !== -1
        ) {
          return school;
        }
      });
    }
  }

  cleanString(text: string): string {
    return text
      .toLowerCase()
      .replace(/\s/g, "")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .trim();
  }
}
