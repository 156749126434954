import { Validators } from "@angular/forms";

export const FileTitleValidators = {
  title: [
    "",
    [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      Validators.pattern("^[a-zA-Z0-9áéíóúý'ÁÉÍÓÚñÑ() ',.:]*$")
    ]
  ],
  price: [
    "",
    [
      Validators.required,
      Validators.min(60),
      Validators.max(1300),
      Validators.pattern("^[0-9]*$")
    ]
  ],
  description: [
    "",
    [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(300),
      Validators.pattern("^[a-zA-Z0-9áéíóúý'ÁÉÍÓÚñÑ ',.:^\n\r]*$")
    ]
  ]
};

export const FileTitleValidatorsMessages = {
  title: {
    required: "El titulo del archivo es obligatorio.",
    minLength: "El titulo debe ser de al menos 3 caracteres.",
    maxLength: "El titulo debe ser de maximo 50 caracteres.",
    pattern:
      "El titulo solo puede tener letras, numeros y espacios, los caracteres especiales no estan permitidos."
  },
  price: {
    required: "El precio sugerido del archivo es obligatorio.",
    min: "El precio sugerido minimo es de 60 yeahcoins.",
    max: "El precio sugerido máximo es de 1300 yeahcoins.",
    pattern: "El precio debe ser numerico, entero y sin caracteres especiales."
  },
  description: {
    required:
      "La descripción es obligatoria, entre más detalles mas probabilidad hay de descarga.",
    minLength: "La descripción debe ser de al menos 3 caracteres.",
    maxLength: "La descripción debe ser de maximo 300 caracteres.",
    pattern:
      "Tu descripción tiene caracteres especiales no permitidos. Solo se aceptan letras y numeros"
  }
};
