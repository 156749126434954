import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable, from } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { tap, take, map } from "rxjs/operators";
import { IUser } from "../models/user";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: "root"
})
export class CanShowGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    return from(this.authService.getFireBaseUser()).pipe(
      map(user => {
        if (user && user.uid != null) {
          const providerData = user.providerData[0].providerId;
          if (providerData === "password") {
            if (user.emailVerified && providerData) {
              if (this.authService.getUser().school) {
                return true;
              }
              this.router.navigate(["/selectSchool"]);
              return false;
            }
            this.router.navigate(["/verifyEmail"]);
            return false;
          }
          if (
            this.authService.getUser().school &&
            this.authService.getUser().career
          ) {
            return true;
          }
          this.router.navigate(["/selectSchool"]);
          return false;
        } else {
          this.router.navigate(["/login"]);
          return false;
        }
      })
    );
  }
}

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private afsAuth: AngularFireAuth,
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.afsAuth.authState
      .pipe(take(1))
      .pipe(map(authState => !!authState))
      .pipe(
        tap(auth => {
          if (!auth) {
            this.router.navigate(["/login"]);
          }
          this.authService.getFireBaseUser().then(user => {
            if (user && user.uid != null) {
              const providerData = user.providerData[0].providerId;
              if (providerData === "password") {
                if (user.emailVerified && providerData) {
                  const currentUser = this.authService.getUser();
                  if (currentUser.school) {
                    return true;
                  }
                  if (!currentUser.uid) {
                    this.router.navigate(["/login"]);
                  }
                  this.router.navigate(["/selectSchool"]);
                  return false;
                }
                this.router.navigate(["/verifyEmail"]);
                return false;
              }
              if (this.authService.getUser().school) {
                return true;
              }
              if (!this.authService.getUser().uid) {
                this.router.navigate(["/login"]);
              }
              this.router.navigate(["/selectSchool"]);
              return false;
            } else {
              this.router.navigate(["/login"]);
              return false;
            }
          });
        })
      );
  }
}
