<section class="introduction">
    <button class="back-button mat-icon-container" routerLink="/login"> <mat-icon>navigate_before</mat-icon> Regresar</button>
    <div class="logo-central"><img src="/assets/img/logo.svg" alt=""></div>
    <div class="content-conditions">
        <h1>Terminos y Condicones para el uso de YeahNotes</h1>
        <p class="yp">YeahNotes.com es una comunidad de estudiantes donde pueden compartir sus apuntes, tareas, reportes de prácticas a fin todo material académico de los que son autores, por lo cual son recompensados con un sistema de puntos que les sirve para descargar
            más documentos de otros estudiantes, generando una relación de ganar-ganar.</p>
        <p class="yp">Al hacer uso de los servicios de YeahNotes.com y/o registrarse en la página, está aceptando su conformidad con estos términos y condiciones, los cuales pueden ser modificados sin previo aviso o ninguna notificación hacia usted. Todo cambia realizado
            a estos términos de uso y condiciones será publicado en esta web y tendrá validez inmediata. Su uso de la plataforma después de algún cambio de los términos y condiciones significa la aceptación de las modificaciones realizadas. Por lo que
            le aconsejamos revisar con cierta frecuencia nuestros términos y condiciones.</p>
        <p class="yp">Aqui te dejamos paso a paso las instrucciones de como hacerlo, para cualquiera que sea tu navegador.</p>
        <h2>Derecho de Admisión</h2>
        <p class="yp">YeahNotes.com se reserva el derecho de admisión para poder hacer uso de sus servicios, por lo que es indispensable que los usuarios cumplan con las siguientes condiciones:</p>
        <p class="yp">Condición 1: Todo usuario debe ser mayor de 16 años para registrarse.</p>
        <p class="yp">Condición 2: Solo puede registrarse una única vez en la plataforma.</p>
        <p class="yp">Condición 3: No debe utilizarse YeahNotes.com con ningún fin distinto al que fue creado.</p>
        <p class="yp">Condición 4: Está prohibido subir o crear contenidos ilegales, dañinos o con la intención de perjudicar a cualquier usuario o a la página.</p>
        <p class="yp">Condición 5: Para el registro y uso de los servicios de YeahNotes debes proporcionar datos reales.</p>
        <p class="yp">Condición 6: Queda estrictamente prohibido violar las leyes de copyright o derechos de autor, el contenido subido es responsabilidad del usuario que lo subió.</p>
        <p class="yp">Condición 7: Queda prohibido subir contenidos con virus, malware, troyanos o cualquier componente dañino que pueda afectar a YeahNotes.com o sus usuarios. Incluyendo hackeo web e inyecciones SQL, HTML o similar.</p>
        <h2>Contenidos</h2>
        <p class="yp">YeahNotes.com obtiene y mantiene el derecho de propiedad de todos los contenidos dentro de la plataforma (comentarios, valoraciones, archivos, documentos, etc.) publicados por los usuarios siempre y cuando ellos sean los autores legítimos o dueños
            de los derechos, en caso contrario no nos hacemos responsables por contenido robado ya que viola nuestros términos de uso y condiciones. Por ello nos reservamos el derecho de poder mostrar, editar, eliminar, ocultar, bloquear, publicitar o
            explotar por cualquier medio, y para cualquier fin que YeahNotes.com considere pertinente.</p>
        <p class="yp">YeahNotes.com no será responsable por ningún motivo de daños y perjuicios provocados por utilización de los servicios o contenido que afecte de algún modo a los usuarios o a terceros en general.</p>
        <p class="yp">Todo contenido subido o creado por los usuarios es exclusivamente responsabilidad de los mismos, así como las consecuencias que dé él se deriven. Por ello YeahNotes.com renuncia a toda responsabilidad por violaciones de derechos de autor que puedan
            cometer sus usuarios y se reserva el derecho a coadyuvar con las autoridades competentes para aclarar cualquier tipo de ilícito.</p>
        <p class="yp">El usuario se compromete a no utilizar lenguaje mal intencionado entendiéndose como racista, prejuicioso, insultante, ofensivo o con fin de agredir. También acepta que está prohibido subir cualquier tipo de material pornográfico, o insultante.
            Tampoco será aceptado ningún tipo de publicidad encubierta o spam. YeahNotes se reserva el derecho de modificar, borrar, ocultar o realizar cualquier acción que consideren pertinente con el contenido que no cumpla nuestros requisitos y condiciones,
            así como cancelar cuentas de usuarios que los hayan creado sin previo aviso.</p>
        <p class="yp">YeahNotes pretendes ser el factor de cambio y causar un impacto positivo en las escuelas, sabemos que las instituciones no pueden girar a la misma velocidad en que gira el mundo, pero es necesario un factor que incite a los profesores a actualizarse
            y a prepararse mejor, para brindar la mejor educación posible a las futuras generaciones. Por lo que pedimos a todos nuestros usuarios que sean conscientes con el material que suben, siendo este de calidad y útil para el resto de los usuarios,
            finalmente somos una plataforma que vive de la comunidad estudiantil y la calidad de nuestro servicio depende en gran parte de su cooperación.</p>
        <p class="yp">Como medida de seguridad hacia ataques civerneticos en contra de yeahnotes es posible que guardemos su direccion ip en caso de que la necesitemos.</p>
        <h2>Confidencialidad</h2>
        <p class="yp">La identidad de todos nuestros usuarios de YeahNotes es privada y confidencial en todo momento, por ningún motivo se dará a conocer los correos, nickname o contraseñas a terceros ajenos a la página, con la única excepción de una situación extrema
            cuando agencias de gobierno requieran nuestra cooperación (Policial, Investigaciones Federales, etc), y se identifiquen de la manera adecuada presentándonos la documentación requerida y siguiendo el protocolo establecido. Fuera de la excepción,
            los datos de todos nuestros usuarios son confidenciales y no serán brindados a personas ajenas a YeahNotes que cuenten con los permisos necesarios para conocerlos.</p>
        <p class="yp">YeahNotes.com se reserva el derecho de mantener en secreto la identidad de los creadores de la página, inversionistas o cualquiera persona involucrada en el equipo de YeahNotes que contribuya de forma directa o indirecta.</p>
        <h2>Cancelación de Cuenta</h2>
        <p class="yp">YeahNotes se reserva el derecho de cancelación de cuentas o bloquear usuarios que no cumplan con nuestros términos y condiciones, hagan mal uso de nuestros servicios o utilicen la página con fines distintos a los establecidos. En caso de que un
            usuario sea bloqueado se valorara si el contenido subido por este debe ser eliminado a criterio de YeahNotes.</p>
        <p class="yp">Dependiendo la infracción que el usuario haya cometido YeahNotes determinara la sanción pertinente que va desde el bloqueo temporal, el reinicio de los yeahcoins a cero o como última medida limitar el acceso al sitio expulsando al usuario de forma
            definitiva.</p>
        <h2>Convivencia YeahNotes</h2>
        <p class="yp">YeahNotes es una plataforma de donde se puede interactuar con otros usuarios por medio de comentarios realizados al material que los mismos usuarios suben. Por lo mismo apoyamos la idea de una comunidad que puede expresarse libre de violencia
            y agresión. Al ser usuario de la página, será el único responsable de sus interacciones con otros usuarios de YeahNotes.</p>
        <p class="yp">Nos reservamos el derecho a controlar los conflictos que tenga con otros usuarios y si es pertinente aplicar sanciones a los involucrados.</p>
        <h2>Exclusión de Responsabilidad</h2>
        <p class="yp">YeahNotes por ningún motivo se hará responsable del Contenido generado por el Usuario o Contenido de Terceros publicado en el sitio, esto va desde comentarios, documentos, material académico, nombres de instituciones o escuelas, imágenes de instituciones
            o escuelas, nombres de docentes, nombre de materias, planes académicos o cualquier tipo de información almacenada en nuestra base de datos con independencia de que haya sido publicada u organizada por los usuarios del sitio o servicios de
            terceros. YeahNotes establece normas de conducta y comportamiento para sus usuarios en este documento, sin embargo, al ser una plataforma que les permite a los usuarios subir su propio contenido no controlamos lo que los usuarios suben, comentan
            o transmiten en la plataforma, por lo que no somos ni nos haremos responsables de ningún tipo de contenido ofensivo, insultante, inapropiado, ilegal, obsceno, ilícito, o cuestionable que pueda existir en el sitio o en relación con cualquier
            contenido de usuario o de terceros. YeahNotes no es responsable de la conducta de ninguno de sus usuarios ya sea online u offline ni de ningún tercero.</p>
        <p class="yp">YeahNotes es una plataforma que necesita mantenimiento, el cual lleva tiempo, dinero y necesita de pruebas, por lo que no debe sorprender que ocasionalmente el sitio presente algún tipo de fallas o errores internos del sistema, también es posible
            que se encuentre temporalmente fuera de servicio o no disponible. YeahNotes.com no asume ningún tipo de responsabilidad por errores, omisiones, interrupciones, eliminaciones, defectos, retrasos de funcionamiento o de transmisión, fallos de
            la línea de comunicaciones, robos, destrucción, acceso no autorizado, alteración de las comunicaciones del Usuario, hackeo de cuentas, modificaciones en el sistema de puntos, perdida de yeahcoins o a fin cualquier tipo de daño que cause un
            mal funcionamiento del sistema o afecte de forma directa o indirecta al usuario. No nos hacemos responsables de ningún tipo de fallos en los servidores, congestión en tráfico de internet, lesiones o daños sobre el ordenador o cualquier otro
            dispositivo del usuario derivados de la utilización de la plataforma o descarga de materiales con relación a la plataforma o el servicio.</p>
        <p class="yp">El sitio YeahNotes.com, sus servicios y el contenido propio o de terceros se proporciona y exhibe tal cual, sin ningún tipo de filtro excluyendo expresamente todas las declaraciones y garantías, expresas o implícitas, incluyendo las garantías
            implícitas de propiedad, comerciabilidad, adecuación a un fin concreto y no infracción. No podemos garantizar ni prometemos ni nos comprometemos a ningún resultado en específico derivado del uso del sitio, el servicio ni la plataforma en general.
            Tampoco podemos garantizar que el software de la plataforma sea siempre fiable, este actualizado, libre de errores, libre de virus y en general libre de cualquier componente dañino.</p>
        <p class="yp">Las referencias a productos, servicios, imagen corporativa, logotipos, procesos u otras informaciones mediante nombres comerciales, marcas, fabricantes, proveedores u otros, no constituyen ni implican que YeahNotes apruebe, patrocine o recomiende
            tales productos, servicios, procesos o información, ni que tenga ninguna relación con éstos.</p>
        <p class="yp">Nos reservamos el derecho a la confidencialidad de nuestros colaboradores, inversionistas y todas las personas que trabajan, ayudan o apoyan de forma directa o indirecta la página. Por ningún motivo se revelará este tipo de información, con la
            única excepción de una colaboración obligatoria con autoridades gubernamentales, siempre y cuando se siga el protocolo establecido y sin rastro alguno de corrupción.</p>
        <p class="yp">YeahNotes no se hace responsable por material ilegal que infrinja leyes copyright subido por cualquiera de nuestros usuarios, el usuario es el único responsable del contenido que suba, publique o comente en la plataforma. No tenemos, ni tendremos
            la obligación y/o responsabilidad de dar cuentas a ninguna institución educativa, docentes o autoridades educativas que este en desacuerdo con la pagina y/o los servicios que ofrecemos, por lo tanto, cualquier petición de conocer información
            sobre nuestros usuarios, contenidos, material, trabajadores, colaboradores o cualquier información de nuestra base de daros será negada de forma definitiva sin posibilidad a ningún tipo de acuerdo.</p>
        <h2>Limitación de Responsabilidad</h2>
        <p class="yp">No existe, existirá o existió ningún tipo de responsabilidad de ningún tipo, ya sea directa o indirecta, tanto de YeahNotes como de sus creadores, desarrolladores, colaboradores, inversionistas, prestadores de servicios ni ninguna persona que
            ayude con la página de forma directa o indirecta referente al uso debido o indebido que cualquiera de los usuarios y/o terceros hagan sobre los contenidos publicados en YeahNotes.com, información de nuestra base de datos o el uso directo o
            indirecto del sitio o servidor.</p>
        <p class="yp">YeahNotes no garantiza la confiabilidad de ningun material disponible en la plataforma, las adquisiciones por medio de los yeahcoins es reponsabilidad de cada usuario. No garantizamos ningun tipo de resultado esperado por los usuarios, ni que
            los documentos, imagenes o material multimedia subido por los usuarios sea realmente lo que dice ser.</p>
        <p class="yp">La opinión de nuestros usuarios, prensa, medios de comunicación, prestadores de servicio, instituciones educativas o de cualquier tercero por ningún motivo refleja la opinión de YeahNotes sobre cualquier tema, no nos hacemos responsables sobre
            prejuicios, pensamientos o acciones que tomen en nombre de la página. La presente información de terminos y condiciones puede cambiar o modificarse sin previo aviso.</p>
        <h2>Procesamiento de Datos</h2>
        <p class="yp">
            Con la finalidad de brindar un servicio personalizado YeahNotes crea sesiones de usuario a parir de su nombre o nickname y su email. Ya sea que el registro se realice por medio del formulario de yeahnotes, o a traves de Google o Facebook, unicamente se guarda el nombre y correo electronico. 
        </p>
        <p class="yp">
            Una vez creada la cuenta se le solicitara la escuela a la que pertenece con el fin de ofrecer resultados personalizados que le sean de ayuda. Siempre y cuando la escuela ya pertenezca a yeahnotes.
        </p>
        <p class="yp">
            En caso de no querer continuar con yeahnotes y se desea llevar a cabo la eliminación de la información guardada, se debe escribir un mensaje solicitando la baja y eliminacion de los datos a nuestra pagina oficial de facebook https://www.facebook.com/yeahnotes. Proporcionando en el mensaje, su email, nombre de usuario, motivo de la baja y forma en que se llevo a cabo el registro.
        </p>
        <p class="yp">
            El proceso puede tardar un lapso maximo de tres dias habiles.
        </p>

        <p class="yp">Si el conocimiento no se comparte, no sirve de nada. www.yeahnotes.com "COMPARTAMOS EL CONOCIMIENTO"</p>
    </div>
</section>