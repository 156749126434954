<div class="author-container">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Autor: {{dataAuthor.emoji}} <span class="nickname">{{dataAuthor.nickname}}</span> 
                </mat-panel-title>
                <mat-panel-description>
                    <span class="star mat-icon-container " *ngIf="dataAuthor.score"><mat-icon>star</mat-icon> {{dataAuthor.score}}</span>
                    <span class="star" *ngIf="!dataAuthor.score">Sin calificación</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="time">Usuario desde <span class="minum">{{dataAuthor.timestamp}}</span></p>
            <div *ngIf="dataAuthor.score" class="rating">
                <div class="stars">
                    <mat-icon *ngFor="let fullstar of fullStars">star</mat-icon>
                    <mat-icon *ngFor="let fullstar of restStars">star_outline</mat-icon>
                </div>
                <div class="label-start">
                    {{dataAuthor.score}} estrellas de 5
                </div>
            </div>
            <div *ngIf="dataAuthor.score" class="numbeRankins">
                {{dataAuthor.countGlobalRating}} calificaciones
            </div>
            <div *ngIf="!dataAuthor.score" class="rating">
                <div class="label-start">
                    Este author aun no tiene calificacion, es probable que este sea su primer archivo.
                </div>
            </div>
            <div class="legend">
                La puntuación del author es un promedio de las calificaciones de todos sus archivos.
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>