import { Injectable, OnDestroy } from "@angular/core";
import { auth } from "firebase/app";
import { User } from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import { from, Observable, merge, Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { IUser } from "../models/user";
import { DialogService } from "../utility/dialog/dialog.service";
import { Router } from "@angular/router";
import * as firebase from "firebase";
import { ErrorService } from "../error/error.service";
import { host } from "../constant";
import { HttpClient } from "@angular/common/http";
import { ProfileService } from "../profile/profile.service";
import { IFileFiltersData } from "../utility/models/models";

const uid_key = "f_usr_uid";
const nm_key = "f_usr_nm";
const ema_key = "f_usr_ema";
const yp_key = "f_usr_yp";
const car_key = "f_usr_car";
const sch_key = "f_usr_sch";
const ev_key = "f_usr_ev";
const emo_key = "f_usr_emo";
const sco_key = "f_usr_sco";
const ide_key = "f_usr_ide";
const ins_key = "f_usr_ins";
const ts_key = "f_usr_ts";
const code_key = "f_usr_cd";
const n_file_key = "f_usr_n_f";
const n_down_key = "f_usr_n_d";
const n_pro_key = "f_usr_n_p";
const n_cust_key = "f_usr_n_c";
const fcm_key = "f_dev_fcm";
const cat_key = "f_usr_cat";
const n_filter_school_uid_key = "f_f_scl_u_k";
const n_filter_school_name_key = "f_f_scl_n_k";
const n_filter_school_nickname_key = "f_f_scl_nk_k";
const n_filter_career_uid_key = "f_f_c_u_k";
const n_filter_career_name_key = "f_f_c_n_k";
const n_filter_order_dir_key = "f_f_o_u_k";
const n_filter_order_fie_key = "f_f_o_n_k";
const n_filter_teacher_uid_key = "f_f_t_u_k";
const n_filter_teacher_name_key = "f_f_t_n_k";
const n_filter_subject_uid_key = "f_f_s_u_k";
const n_filter_subject_name_key = "f_f_s_n_k";
const n_filter_score_key = "f_f_sco_k";
const n_filter_type_key = "f_f_arr_tp_k";
const n_filter_format_key = "f_f_arr_fo_k";
const n_filter_origin_key = "f_origin_k";
const n_filter_search_text_key = "f_f_ser_txt_k";

@Injectable({ providedIn: "root" })
export class AuthService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorInLogOrRegister$: Subject<boolean> = new Subject<boolean>();
  public user: User;
  public oldUser: IUser;
  public user$: Observable<IUser>;
  public onSetUser = new Subject<IUser>();

  constructor(
    public afAuth: AngularFireAuth,
    public afStore: AngularFirestore,
    public dialogService: DialogService,
    private router: Router,
    private errorService: ErrorService,
    private http: HttpClient,
    private profileService: ProfileService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onError(): Observable<boolean>{
    return this.errorInLogOrRegister$;
  }

  login(email: string, password: string) {
    return from(this.afAuth.signInWithEmailAndPassword(email, password))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.afStore
            .doc<IUser>(`users/${data.user.uid}`)
            .valueChanges()
            .pipe(first(), takeUntil(this.destroy$))
            .subscribe(user => {
              this.clearUser();
              this.setUser(user);
              this.loadFilterOptions();
              this.router.navigate(["/searchView"]);
            }, err => this.errorService.showError(err));
        },
        error => {
          this.errorInLogOrRegister$.next(true);
          this.dialogService.manageError(error);
        }
      );
  }

  public loginFacebookUser() {
    return this.afAuth
      .signInWithPopup(new auth.FacebookAuthProvider())
      .then(credential => {
        if (credential.additionalUserInfo.isNewUser) {
          const facebookData: any = credential.additionalUserInfo.profile;
          this.updateNewUser(
            credential.user,
            facebookData.name.replace(/ /g, ""),
            "/searchView"
          );
        } else {
          const uidUser = credential.user.uid;
          this.afStore
            .doc<IUser>(`users/${uidUser}`)
            .valueChanges()
            .pipe(first(), takeUntil(this.destroy$))
            .subscribe(user => {
              this.clearUser();
              this.setUser(user);
              this.loadFilterOptions();
              this.router.navigate(["/searchView"]);
            }, err => {
              this.errorInLogOrRegister$.next(true);
              this.errorService.showError(err)
            });
        }
      }).catch(err => {
        this.errorInLogOrRegister$.next(true);
        if(err && err.message) {
          this.errorService.showError(err.message, 15);
        }
      });
  }

  public loginGoogleUser() {
    return this.afAuth
      .signInWithPopup(new auth.GoogleAuthProvider())
      .then(credential => {
        if (credential.additionalUserInfo.isNewUser) {
          const googleData: any = credential.additionalUserInfo.profile;
          this.updateNewUser(
            credential.user,
            googleData.name.replace(/ /g, ""),
            "/searchView"
          );
        } else {
          const uidUser = credential.user.uid;
          this.afStore
            .doc<IUser>(`users/${uidUser}`)
            .valueChanges()
            .pipe(first(), takeUntil(this.destroy$))
            .subscribe(user => {
              this.clearUser();
              this.setUser(user);
              this.loadFilterOptions();
              this.router.navigate(["/searchView"]);
            }, err => {
              this.errorInLogOrRegister$.next(true);
              this.errorService.showError(err)});
        }
      }).catch(err => {
        this.errorInLogOrRegister$.next(true);
        if(err && err.message) {
          this.errorService.showError(err.message, 15);
        }
      });
  }

  public signUp(email: string, password: string, nickname: string) {
    from(this.afAuth.createUserWithEmailAndPassword(email, password)).pipe(takeUntil(this.destroy$)).subscribe(
      credential => {
        this.sendVerificationEmail().then(() => {
          this.updateNewUser(credential.user, nickname, "/searchView");
        }).catch(err => {
          this.errorInLogOrRegister$.next(true);
          if(err && err.message) {
            this.errorService.showError(err.message, 15);
          }
        });;
      },
      error => {
        this.errorInLogOrRegister$.next(true);
        this.dialogService.manageError(error);
      }
    );
  }

  public recoverPassword(email: string): Observable<void> {
    return from(this.afAuth.sendPasswordResetEmail(email));
  }

  public logOut() {
    const currentFCM = this.getUser().fcm;
    if(currentFCM) {
      this.removeFCM().subscribe(() => {
        this.removeCredentials();
      }, err => {
        console.log(err);
        this.removeCredentials();
      });
    } else {
      this.removeCredentials();
    }
  }

  public removeCredentials() {
    from(this.afAuth.signOut()).pipe(takeUntil(this.destroy$)).subscribe(
      resp => {
        this.clearUser();
        this.router.navigate(["/login"]);
      },
      err => this.errorService.showError(err)
    );
  }

  async sendVerificationEmail(): Promise<void> {

    (await this.afAuth.currentUser).emailVerified

    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  async isEmailVerified(): Promise<boolean> {
    (await this.afAuth.currentUser).reload();
    (await this.afAuth.currentUser).reload();
    return (await this.afAuth.currentUser).emailVerified;
  }

  async getFireBaseUser(): Promise<User> {
    return this.afAuth.currentUser;
  }

  public getUser(): IUser {
    const user: IUser = {};
    if (localStorage.getItem(uid_key) != null) {
      user.uid = localStorage.getItem(uid_key);
      user.nickname = localStorage.getItem(nm_key);
      user.email = localStorage.getItem(ema_key);
      user.yeahcoins = +localStorage.getItem(yp_key);
      user.career = localStorage.getItem(car_key);
      user.school = localStorage.getItem(sch_key);
      user.institution = localStorage.getItem(ins_key);
      user.emoji = localStorage.getItem(emo_key);
      user.identity = localStorage.getItem(ide_key);
      user.timestamp = localStorage.getItem(ts_key);
      user.code = localStorage.getItem(code_key);
      user.fcm = localStorage.getItem(fcm_key);
      user.category = localStorage.getItem(cat_key);
      user.notifications = {
        files: localStorage.getItem(n_file_key) == "true",
        downloads: localStorage.getItem(n_down_key) == "true",
        promotions: localStorage.getItem(n_pro_key) == "true",
        custom: localStorage.getItem(n_cust_key) == "true"
      };
      user.score = +localStorage.getItem(sco_key);
      return user;
    }
    return user;
  }

  public setUser(user: IUser) {
    this.oldUser = this.getUser() || null;
    if (user != null) {
      if (user.uid != null) {
        localStorage.setItem(uid_key, user.uid);
      }
      if (user.nickname != null) {
        localStorage.setItem(nm_key, user.nickname);
      }
      if (user.email != null) {
        localStorage.setItem(ema_key, user.email);
      }
      if (user.yeahcoins != null) {
        localStorage.setItem(yp_key, user.yeahcoins.toString());
      }
      if (user.career != null) {
        localStorage.setItem(car_key, user.career);
      }
      if (user.school != null) {
        localStorage.setItem(sch_key, user.school);
      }
      if (user.institution != null) {
        localStorage.setItem(ins_key, user.institution);
      }
      if (user.identity != null) {
        localStorage.setItem(ide_key, user.identity);
      }
      if (user.emoji != null) {
        localStorage.setItem(emo_key, user.emoji);
      }
      if (user.score != null) {
        localStorage.setItem(sco_key, user.score.toString());
      }
      if (user.timestamp != null) {
        localStorage.setItem(ts_key, user.timestamp);
      }
      if (user.code != null) {
        localStorage.setItem(code_key, user.code);
      }
      if (user.category != null) {
        localStorage.setItem(cat_key, user.category);
      }
      if (user.notifications != null) {
        localStorage.setItem(n_file_key, user.notifications.files.toString());
        localStorage.setItem(
          n_down_key,
          user.notifications.downloads.toString()
        );
        localStorage.setItem(
          n_pro_key,
          user.notifications.promotions.toString()
        );
        localStorage.setItem(n_cust_key, user.notifications.custom.toString());
      }
      if(user.filterPreferences != null) {
        this.setFilterPreferences(user.filterPreferences);
      } 
      this.onSetUser.next(user);
    }
  } 

  setFilterPreferences(filters: IFileFiltersData) {
    if(filters && filters.school && filters.school.uid != null && filters.school.name != null && filters.school.nickname != null) {
      localStorage.setItem(n_filter_school_uid_key, filters.school.uid);
      localStorage.setItem(n_filter_school_name_key, filters.school.name);
      localStorage.setItem(n_filter_school_nickname_key, filters.school.nickname);
    } else {
      localStorage.removeItem(n_filter_school_uid_key);
      localStorage.removeItem(n_filter_school_name_key);
      localStorage.removeItem(n_filter_school_nickname_key);
    }
    if(filters && filters.career && filters.career.uid != null && filters.career.name != null) {
      localStorage.setItem(n_filter_career_uid_key, filters.career.uid);
      localStorage.setItem(n_filter_career_name_key, filters.career.name);
    } else {
      localStorage.removeItem(n_filter_career_uid_key);
      localStorage.removeItem(n_filter_career_name_key);
    }
    if(filters && filters.order && filters.order.orderByDir != null && filters.order.orderByField != null) {
      localStorage.setItem(n_filter_order_dir_key, filters.order.orderByDir);
      localStorage.setItem(n_filter_order_fie_key, filters.order.orderByField);
    } else {
      const currentPreferences = this.getFilterPreferences();
      if(!currentPreferences.order || !currentPreferences.order.orderByField || !currentPreferences.orderByDir) {
        localStorage.setItem(n_filter_order_dir_key, 'desc');
        localStorage.setItem(n_filter_order_fie_key, 'timestamp');
      }
    }
    if(filters && filters.search != null) {
      localStorage.setItem(n_filter_search_text_key, filters.search);
    } else {
      localStorage.removeItem(n_filter_search_text_key);
    }
    if(filters && filters.teacher && filters.teacher.uid != null && filters.teacher.name != null) {
      localStorage.setItem(n_filter_teacher_uid_key, filters.teacher.uid);
      localStorage.setItem(n_filter_teacher_name_key, filters.teacher.name);
    } else {
      localStorage.removeItem(n_filter_teacher_uid_key);
      localStorage.removeItem(n_filter_teacher_name_key);
    }
    if(filters && filters.subject && filters.subject.uid != null && filters.subject.name != null) {
      localStorage.setItem(n_filter_subject_uid_key, filters.subject.uid);
      localStorage.setItem(n_filter_subject_name_key, filters.subject.name);
    } else {
      localStorage.removeItem(n_filter_subject_uid_key);
      localStorage.removeItem(n_filter_subject_name_key);
    }
    if(filters && filters.score != null) {
      localStorage.setItem(n_filter_score_key, filters.score.toString());
    } else {
      localStorage.removeItem(n_filter_score_key);
    }
    if(filters && filters.types != null) {
      filters.types = filters.types.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.uid === thing.uid
        ))
      )
      localStorage.setItem(n_filter_type_key, JSON.stringify(filters.types));
    } else {
      localStorage.removeItem(n_filter_type_key);
    }
    if(filters && filters.formats != null) {
      filters.formats = filters.formats.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.uid === thing.uid
        ))
      )
      localStorage.setItem(n_filter_format_key, JSON.stringify(filters.formats));
    } else {
      localStorage.removeItem(n_filter_format_key);
    }
    if(filters && filters.origin != null) {
      localStorage.setItem(n_filter_origin_key, filters.origin);
    } else {
      localStorage.removeItem(n_filter_origin_key);
    }
  }

  getFilterPreferences(): IFileFiltersData {
    let filters: IFileFiltersData = {
      order: {
        orderByField: localStorage.getItem(n_filter_order_fie_key),
        orderByDir: localStorage.getItem(n_filter_order_dir_key),      
      }
    }
    if(localStorage.getItem(n_filter_school_uid_key) 
    && localStorage.getItem(n_filter_school_uid_key) != null
    && localStorage.getItem(n_filter_school_nickname_key) 
    && localStorage.getItem(n_filter_school_nickname_key) != null
    && localStorage.getItem(n_filter_school_name_key)
    && localStorage.getItem(n_filter_school_name_key) != null) {
      const school = {school: {
        uid: localStorage.getItem(n_filter_school_uid_key),
        name: localStorage.getItem(n_filter_school_name_key),
        nickname: localStorage.getItem(n_filter_school_nickname_key),
      }}
      filters = {...filters, ...school}; 
    }
    if(localStorage.getItem(n_filter_career_uid_key) 
    && localStorage.getItem(n_filter_career_uid_key) != null
    && localStorage.getItem(n_filter_career_name_key)
    && localStorage.getItem(n_filter_career_name_key) != null) {
      const career = {career: {
        uid: localStorage.getItem(n_filter_career_uid_key),
        name: localStorage.getItem(n_filter_career_name_key),
      }}
      filters = {...filters, ...career}; 
    }
    if(localStorage.getItem(n_filter_teacher_uid_key) 
    && localStorage.getItem(n_filter_teacher_uid_key) != null
    && localStorage.getItem(n_filter_teacher_name_key)
    && localStorage.getItem(n_filter_teacher_name_key) != null) {
      const teacher = {teacher: {
        uid: localStorage.getItem(n_filter_teacher_uid_key),
        name: localStorage.getItem(n_filter_teacher_name_key),
      }}
      filters = {...filters, ...teacher}; 
    }
    if(localStorage.getItem(n_filter_subject_uid_key) 
    && localStorage.getItem(n_filter_subject_uid_key) != null
    && localStorage.getItem(n_filter_subject_name_key)
    && localStorage.getItem(n_filter_subject_name_key) != null) {
      const subject = {subject: {
        uid: localStorage.getItem(n_filter_subject_uid_key),
        name: localStorage.getItem(n_filter_subject_name_key),
      }}
      filters = {...filters, ...subject}; 
    }
    if(localStorage.getItem(n_filter_score_key)) {
      filters = {...filters, ...{score: +localStorage.getItem(n_filter_score_key)}};
    }
    if(localStorage.getItem(n_filter_format_key)) {
      filters = {...filters, ...{formats: JSON.parse(localStorage.getItem(n_filter_format_key))}};
    }
    if(localStorage.getItem(n_filter_type_key)) {
      filters = {...filters, ...{types: JSON.parse(localStorage.getItem(n_filter_type_key))}};
    }
    if(localStorage.getItem(n_filter_origin_key)) {
      filters = {...filters, ...{origin: localStorage.getItem(n_filter_origin_key)}};
    }
    if(localStorage.getItem(n_filter_search_text_key)) {
      filters = {...filters, ...{search: localStorage.getItem(n_filter_search_text_key)}};
    }
    return filters;
  } 

  setFCM(fcm:string) {
    if (fcm != null) {
      localStorage.setItem(fcm_key, fcm);
    }
  }

  getOldUser() {
    return this.oldUser;
  }

  public clearUser() {
    localStorage.removeItem(fcm_key);
    localStorage.removeItem(uid_key);
    localStorage.removeItem(nm_key);
    localStorage.removeItem(ema_key);
    localStorage.removeItem(yp_key);
    localStorage.removeItem(car_key);
    localStorage.removeItem(sch_key);
    localStorage.removeItem(ev_key);
    localStorage.removeItem(emo_key);
    localStorage.removeItem(ts_key);
    localStorage.removeItem(code_key);
    localStorage.removeItem(n_file_key);
    localStorage.removeItem(n_down_key);
    localStorage.removeItem(n_pro_key);
    localStorage.removeItem(n_cust_key);
    localStorage.removeItem(cat_key);
    localStorage.removeItem(sco_key);
    localStorage.removeItem(ide_key);
    localStorage.removeItem(ins_key);
    localStorage.removeItem(n_filter_school_uid_key);
    localStorage.removeItem(n_filter_school_name_key);
    localStorage.removeItem(n_filter_school_nickname_key);
    localStorage.removeItem(n_filter_career_uid_key);
    localStorage.removeItem(n_filter_career_name_key);
    localStorage.removeItem(n_filter_order_dir_key);
    localStorage.removeItem(n_filter_order_fie_key);
    localStorage.removeItem(n_filter_teacher_uid_key);
    localStorage.removeItem(n_filter_teacher_name_key);
    localStorage.removeItem(n_filter_subject_uid_key);
    localStorage.removeItem(n_filter_subject_name_key);
    localStorage.removeItem(n_filter_score_key);
    localStorage.removeItem(n_filter_type_key);
    localStorage.removeItem(n_filter_format_key);
    localStorage.removeItem(n_filter_origin_key);
  }

  public updateCurrentUser(user: Partial<IUser>) {
    this.getFireBaseUser().then(fbUser => {
      if (fbUser !== null) {
        const userRef: AngularFirestoreDocument<any> = this.afStore.doc(
          `users/${fbUser.uid}`
        );
        const currentDataUser: IUser = this.getUser();
        userRef.set(user, { merge: true }).then(dataUser => {
          this.clearUser();
          this.setUser({ ...currentDataUser, ...user });
          this.loadFilterOptions();
          this.router.navigate(["/searchView"]);
        });
      }
    });
  }

  public loadFilterOptions() {}

  private updateNewUser(user, nickname?: string, redirect?: string) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
    const userNickname = user && user.nickname ? user.nickname : '';
    const nickNameParam = nickname ? nickname : '';
    const finalNickname = userNickname ? userNickname : nickNameParam;
    const data: IUser = {
      uid: user.uid,
      nickname: finalNickname ? finalNickname : "",
      email: user.email,
    };
    userRef.set(data, { merge: true }).then(dataUser => {
      this.profileService.getMe().subscribe(user => {
        this.clearUser();
        this.setUser(user as IUser);
        if (redirect && redirect.length) {
          this.router.navigate([redirect]);
        }
      });
    });
  }

  public getTokenHeader() {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        //console.log(token);
      });
  }

  public changeFCM(fcm: string = null): Observable<any> {
    const route = `${host}/fcm`;
    const obj = fcm ? {'fcm':fcm} : {};
    return this.http.put<any>(route, obj);
  }

  public removeFCM(): Observable<any> {
    const route = `${host}/fcm`;
    return this.http.delete<any>(route);
  }

}
