<svg *ngIf="color === 'white'" [style.width]="width" [style.height]="height" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
<circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:1;"/>
<circle fill="#fff" stroke="#43a047" stroke-width="3" cx="8" cy="54" r="6" >
    <animateTransform
    attributeName="transform"
    dur="1.5s"
    type="rotate"
    from="0 50 48"
    to="360 50 52"
    repeatCount="indefinite" />
</circle>
</svg>

<svg *ngIf="color === 'green'" [style.width]="width" [style.height]="height" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
    <circle fill="none" stroke="#43a047" stroke-width="4" cx="50" cy="50" r="44" style="opacity:1;"/>
    <circle fill="#43a047" stroke="#fff" stroke-width="3" cx="8" cy="54" r="6" >
        <animateTransform
        attributeName="transform"
        dur="1.5s"
        type="rotate"
        from="0 50 48"
        to="360 50 52"
        repeatCount="indefinite" />
    </circle>
    </svg>