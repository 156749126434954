import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { host } from "../constant";
import { IEvaluateMe, INotifications } from "../utility/models/models";

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  restartSettings$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {}

  getMe(): Observable<any> {
    const route = `${host}/me`;
    return this.http.get<any>(route);
  }

  updateMe(
    nickname?: string,
    emoji?: string,
    school?: string,
    institution?: string,
    career?: string,
    notifications?: any
  ): Observable<any> {
    let parameters = {};
    if (nickname) {
      parameters = { ...parameters, ...{ nickname: nickname } };
    }
    if (emoji) {
      parameters = { ...parameters, ...{ emoji: emoji } };
    }
    if (school && institution && career) {
      parameters = {
        ...parameters,
        ...{ institution: institution, school: school, career: career }
      };
    }
    if (notifications) {
      parameters = { ...parameters, ...notifications };
    }
    const route = `${host}/me`;
    return this.http.put<any>(route, parameters);
  }

  getNotificationsSettings(): Observable<any> {
    const route = `${host}/notificationsSettings`;
    return this.http.get<any>(route);
  }

  setNotificationsSettings(preferences: any): Observable<any> {
    const route = `${host}/notificationsSettings`;
    return this.http.put<any>(route, preferences);
  }

  getMovements(last?: string): Observable<any> {
    let route = `${host}/movements`;
    if (last) {
      route = route + "?last=" + last;
    }
    return this.http.get<any>(route);
  }

  getDownloads(last?: string): Observable<any> {
    let route = `${host}/downloads`;
    if (last) {
      route = route + "?last=" + last;
    }
    return this.http.get<any>(route);
  }

  getMyFiles(last?: string): Observable<any> {
    let route = `${host}/me/files`;
    if (last) {
      route = route + "?last=" + last;
    }
    return this.http.get<any>(route);
  }

  getNotifications(last?): Observable<any> {
    let route = `${host}/notifications`;
    return this.http.get<any>(route);
  }

  onRestartSettings() {
    return this.restartSettings$;
  }

  restartSettings() {
    this.restartSettings$.next();
  }

  redeemCode(code: string): Observable<any> {
    const route = `${host}/redeem-code`;
    return this.http.post<any>(route, {"code":code});
  }

  evaluateMe(): Observable<IEvaluateMe> {
    const route = `${host}/evaluate-me`;
    return this.http.get<IEvaluateMe>(route);
  }
}
