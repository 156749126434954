<div class="header-imgaes">
    <div class="actions-buttons">
        <button class="action-button-file" (click)="download()"><mat-icon>sim_card_download</mat-icon></button>
    </div>
    <div class="numberOfEelements">
        <button class="action-button-file" (click)="previewImage()"><mat-icon>navigate_before</mat-icon></button> {{currentImage +1}} / {{images.length}}
        <button class="action-button-file" (click)="nextImage()"><mat-icon>navigate_next</mat-icon></button>
    </div>
    <div class="close-full-view">
        <button class="action-button-file" (click)="openFileDialog()"><mat-icon>zoom_out_map</mat-icon></button>
    </div>
</div>
<div class="container-img">
    <span class="helper"></span>
    <img class="img-container" #myImage>
</div>