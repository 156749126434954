import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  @Input("message") message: string = "Hola!";
  @Input("duration") duration: number = 5;


  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this._snackBar.open(this.message, null, {
      duration: this.duration,
    });
  }

}
