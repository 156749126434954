import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FileService } from "src/app/file/file.service";
import { IChipOption } from "src/app/file/file.types";
import { FiltersService } from "./filters.service";
import { IUser } from "src/app/models/user";
import { AuthService } from "src/app/auth/auth.service";
import {
  IFileFiltersData,
  IFullCareer,
  IFullSchool,
  IFullSubject,
  ILiteTeacher
} from "../models/models";
import { Subject } from "rxjs";
import { ErrorService } from "src/app/error/error.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-file-filters",
  templateUrl: "./file-filters.component.html",
  styleUrls: ["./file-filters.component.scss"]
})
export class FileFiltersComponent implements OnInit, OnDestroy{
  @Input() mobileVersion: boolean = true;
  @Output() onClickSearch = new EventEmitter<IFileFiltersData>();
  @Output() onClickCancel = new EventEmitter<string>();
  @Output() onClose = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  public orderOptions: IChipOption[] = [];
  public typeOptions: IChipOption[] = [];
  public formatOptions: IChipOption[] = [];
  public startOptions: IChipOption[] = [];

  public schoolSelected: IFullSchool;
  public careerSelected: IFullCareer;
  public subjectSelected: IFullSubject;
  public teacherSelected: ILiteTeacher;
  public scoreSelected: number;
  public formatSelected: IChipOption[];
  public typeSelected: IChipOption[];
  public formatSelectedValue: IChipOption[];
  public typeSelectedValue: IChipOption[];

  public orderSelected: IChipOption;

  public selectedFilterSrarus: boolean = false;

  public schools = [];
  public careers = [];
  public subjects = [];
  public teachers = [];

  public canEditSchool: boolean = false;
  public schoolToShow: string;
  public careerToShow: string;
  public currentUser: IUser;

  public currentFilters: IFileFiltersData;
  public preselectedOrderOption: any[] = [];

  constructor(
    protected readonly fileService: FileService,
    private filtersService: FiltersService,
    private authService: AuthService,
    private errorService: ErrorService,
    private ref: ChangeDetectorRef,
  ) {
    this.currentUser = this.authService.getUser();
    this.getSchools();
    this.setOrderOptions();
    this.setTypeOptions();
    this.setFormatOptions();
    this.setStartOptions();
    this.onOrderChange(this.orderOptions[0]);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.filtersService.onFiltersUpdate()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.currentFilters = this.authService.getFilterPreferences();
      if(this.currentFilters && this.currentFilters.origin != 'file-filters') {
        this.setFiltersDataOnFields();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async setFiltersDataOnFields() {
    // load orderOption
    if(this.currentFilters && this.currentFilters.order) {
      const optionPreselected =  this.orderOptions.find(elm => 
        elm.orderByField === this.currentFilters.order.orderByField 
        && elm.orderByDir === this.currentFilters.order.orderByDir);
        this.preselectedOrderOption = optionPreselected ? [optionPreselected] : this.orderOptions && this.orderOptions[0] ? [this.orderOptions[0]] : [];
        this.orderSelected = optionPreselected ? optionPreselected : null;
    }

    // load School
    if(this.currentFilters && this.currentFilters.school) {
      if (this.currentFilters.school.uid) {
        if(!this.schoolSelected || this.schoolSelected.uid != this.currentFilters.school.uid) {
          this.schoolSelected = this.schools.find(
            school => school.uid === this.currentFilters.school.uid
          );
          if(this.schoolSelected) {
            this.schoolToShow = `${this.schoolSelected.nickname}: ${this.schoolSelected.name}`;
            await this.onSchoolSelected(this.schoolSelected);
          }
        }

        // load Career
        if (this.currentFilters && this.currentFilters.career && this.currentFilters.career.uid) {
          if(!this.careerSelected || this.careerSelected.uid != this.currentFilters.career.uid) {
            this.careerSelected = this.careers.find(
              career => career.uid === this.currentFilters.career.uid
            );
            if (this.careerSelected) {
              this.careerToShow = `${this.careerSelected.name}`;
              this.onCareerSelected(this.careerSelected);
            }
          }
        } else {
          this.careerSelected = undefined;
        }
        // load Subject
        if(this.currentFilters && this.currentFilters.subject) {
          if(!this.subjectSelected || this.subjectSelected.uid != this.currentFilters.subject.uid) {
            this.subjectSelected = this.subjects.find(
              subject => subject.uid === this.currentFilters.subject.uid
            );
          }
        } else {
          this.subjectSelected = undefined;
        }
        // load Teacher
        if(this.currentFilters && this.currentFilters.teacher) {
          if(!this.teacherSelected || this.teacherSelected.uid != this.currentFilters.teacher.uid) {
            this.teacherSelected = this.teachers.find(
              teacher => teacher.uid === this.currentFilters.teacher.uid
            );
          }
        } else {
          this.teacherSelected = undefined;
        }
      }
    }
    // load School
    this.scoreSelected = this.currentFilters && this.currentFilters.score ? this.currentFilters.score : 1;

    // load Types
    const typesSelected = [];
    if(this.currentFilters && this.currentFilters.types 
      && this.currentFilters.types.length) {
      this.currentFilters.types.forEach(elm => {
        const chipOption: IChipOption = {
          status: true,
          label: elm.name,
          idElement: elm.uid,
        }
        typesSelected.push(chipOption);
      });
    }
    this.typeSelected = typesSelected;
    this.typeSelectedValue = typesSelected;
    this.ref.detectChanges();
    
    // load Formats
    const formatsSelected = [];
    if(this.currentFilters && this.currentFilters.formats 
      && this.currentFilters.formats.length) {
      this.currentFilters.formats.forEach(elm => {
        const chipOption: IChipOption = {
          status: true,
          label: elm.name,
          idElement: elm.uid,
        }
        formatsSelected.push(chipOption);
      });
    }
    this.formatSelected = formatsSelected;
    this.formatSelectedValue = formatsSelected;
    this.ref.detectChanges();
  }

  loadSelectedFilter() {
    if (!this.selectedFilterSrarus) {
      const selectedFilter = this.filtersService.getSelectedFilterOption();
      if (selectedFilter && selectedFilter.type) {
        switch (selectedFilter.type) {
          case "teacher":
            this.teacherSelected = this.teachers.find(
              t => t.uid === selectedFilter.id
            );
            this.selectedFilterSrarus = this.teacherSelected ? true : false;
            break;
          case "subject":
            this.subjectSelected = this.subjects.find(
              t => t.uid === selectedFilter.id
            );
            this.selectedFilterSrarus = this.subjectSelected ? true : false;
            break;
          case "school":
            this.schoolSelected = this.schools.find(
              t => t.uid === selectedFilter.id
            );
            this.selectedFilterSrarus = this.schoolToShow ? true : false;
            this.schoolToShow = this.schoolSelected.label;
            this.onSchoolSelected(this.schoolSelected);
            break;
        }
      }
    }
  }

  cancel() {
    this.onClickCancel.emit();
  }

  setOrderOptions() {
    this.orderOptions = this.fileService.getOrderOptions().map(option => {
      const filterOption = option as IChipOption;
      filterOption.status = true;
      return filterOption;
    });
  }

  setTypeOptions() {
    this.typeOptions = [];
    this.fileService.getFileTypes()
    .pipe(takeUntil(this.destroy$))
    .subscribe(items => {
      items.forEach((fileData: any) => {
        const fileType = fileData.payload.doc.data();
        const option: IChipOption = {
          idElement: fileType.uid,
          label: fileType.name,
          status: true
        };
        this.typeOptions.push(option);
      });
    }, error => { this.errorService.showError(error)});
  }

  setFormatOptions() {
    this.formatOptions = this.fileService.getFileFormats().map(option => {
      const filterOption = option as IChipOption;
      filterOption.status = true;
      return filterOption;
    });
  }

  setStartOptions() {
    this.startOptions = this.fileService.getFileRatings().map(option => {
      const filterOption = option as IChipOption;
      filterOption.status = true;
      return filterOption;
    });
  }

  close() {
    this.onClose.emit();
  }

  clean() {
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    // por hacer
  }

  getSchools() {
    this.filtersService.getSchools()
    .pipe(takeUntil(this.destroy$))
    .subscribe(catsSnapshot => {
      this.schools = [];
      catsSnapshot.forEach((institution: any) => {
        const data = institution.payload.doc.data();
        this.schools.push({
          ...data,
          ...{ label: `${data.nickname} ${data.name}` }
        });
      });
      this.getCurrentFilters();
      this.loadSelectedFilter();
    }, error => { this.errorService.showError(error)});
  }

  getCurrentFilters() {
    this.currentFilters = this.authService.getFilterPreferences();
    this.setFiltersDataOnFields();
  }

  async onSchoolSelected(school: IFullSchool) {
    if (school) {
      const careers = school ? school.careers : undefined;
      await this.getCareers(careers);
      await this.getTeachers(school.uid);
      await this.getSubjects(school.uid);
    }
    this.careerSelected = undefined;
    this.subjectSelected = undefined;
    this.teacherSelected = undefined;
  }

  async getCareers(careers: string[] = []) {
    this.careers = [];
    const careersToShow = [];
    careers = careers ? careers : this.schoolSelected.careers;
    const careersSnapshat = await this.filtersService.getCareers(careers).pipe(takeUntil(this.destroy$)).toPromise();
    careersSnapshat.forEach((institution: any) => {
      const data = institution.data();
      careersToShow.push(data);
    });
    this.careers = careersToShow;
  }

  async getTeachers(uid: string) {
    this.teachers = [];
    uid = uid ? uid : this.schoolSelected.uid;
    const teacherSnapshat = await this.filtersService.getTeachersBySchoolServer(uid)
    .pipe(takeUntil(this.destroy$)).toPromise();
    this.teachers = teacherSnapshat;
  }

  async getSubjects(uid: string) {
    this.subjects = [];
    uid = uid ? uid : this.schoolSelected.uid;
    const subjectSnapshat = await this.filtersService.getSubjectsBySchoolServer(uid)
    .pipe(takeUntil(this.destroy$)).toPromise();
    this.subjects = subjectSnapshat;
  }

  onCareerSelected(career: IFullCareer) {
    if (career) {
      this.loadSelectedFilter();
    } 
  }

  changeCanEditSchool() {
    this.canEditSchool = !this.canEditSchool;
  }

  async onFilterClick() {
    const selectedSchool = this.schoolSelected
      ? this.schoolSelected.uid
      : undefined;
    const selectedCareer = this.careerSelected
      ? this.careerSelected.uid
      : undefined;

    if(!this.orderSelected) {
      this.orderSelected = this.orderOptions[0];
    }
  
    const formatFilters = this.formatSelectedValue && this.formatSelectedValue.length && this.formatSelectedValue.length != this.formatOptions.length ? this.formatSelectedValue : [];
    const typeFilters = this.typeSelectedValue && this.typeSelectedValue.length && this.typeSelectedValue.length != this.typeOptions.length ? this.typeSelectedValue : [];
    let filters: IFileFiltersData = {
      uidSchool: selectedSchool,
      uidCareer: selectedCareer,
      uidSubject: this.subjectSelected ? this.subjectSelected.uid : undefined,
      uidTeacher: this.teacherSelected ? this.teacherSelected.uid : undefined,
      format: formatFilters,
      type: typeFilters,
      orderByField: this.orderSelected.orderByField,
      orderByDir: this.orderSelected.orderByDir,
    };

    if(this.scoreSelected && this.scoreSelected != 1) {
      filters = {...filters,...{score:this.scoreSelected}};
    }

    let filterPreferences = {
      origin: 'file-filters',
      order: {
        orderByDir: this.orderSelected.orderByDir,
        orderByField: this.orderSelected.orderByField,
      },
      school: {
        uid: this.schoolSelected ? this.schoolSelected.uid : null,
        nickname: this.schoolSelected ? this.schoolSelected.nickname : null,
        name: this.schoolSelected ? this.schoolSelected.name : null,
      },
      career: {
        uid: this.careerSelected ? this.careerSelected.uid : null,
        name: this.careerSelected ? this.careerSelected.name : null
      },
      teacher: {
        uid: this.teacherSelected ? this.teacherSelected.uid : null,
        name: this.teacherSelected ? this.teacherSelected.teacher : null
      },
      subject: {
        uid: this.subjectSelected ? this.subjectSelected.uid : null,
        name: this.subjectSelected ? this.subjectSelected.name : null
      },
      score: this.scoreSelected, 
    }

    if(this.typeSelectedValue && this.typeSelectedValue.length && this.typeSelectedValue.length != this.typeOptions.length) {
      const types = this.typeSelectedValue.map(elm => {
        return {uid:elm.idElement, name:elm.label}
      }) || [];
      const typeObject = {types: types};
      if(types && types.length) {
        filterPreferences = {...filterPreferences, ...typeObject}
      }
    }

    if(this.formatSelectedValue && this.formatSelectedValue.length && this.formatSelectedValue.length != this.formatOptions.length) {
      const formats = this.formatSelectedValue.map(elm => {
        return {uid:elm.idElement, name:elm.label}
      }) || [];
      const formatObject = {formats: formats};
      if(formats && formats.length) {
        filterPreferences = {...filterPreferences, ...formatObject}
      }
    }

    if(!this.teachers || !this.teachers.length) {
      await this.getTeachers(this.schoolSelected.uid);
    } 

    if(!this.subjects || !this.subjects.length) {
      await this.getSubjects(this.schoolSelected.uid);
    }

    this.filtersService.setSavedSearchOptions(this.schoolSelected, this.schools, this.teachers, this.subjects);
    this.authService.setFilterPreferences(filterPreferences);
    this.onClickSearch.emit(filters);
  }

  onTypeSelected(event) {
    this.typeSelectedValue = event;
  }

  onScoreSelected(event) {
    this.scoreSelected = event;
  }

  onFormatSelected(event) {
    this.formatSelectedValue = event;
  }

  onOrderChange(event) {
    this.orderSelected = event;
  }
}
