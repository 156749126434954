import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ISearchElement } from "./search.types";
import { SearchService } from "./search.service";
import { IElementFile, IFileFiltersData } from "../utility/models/models";
import { AuthService } from "../auth/auth.service";
import { FiltersService } from "../utility/file-filters/filters.service";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "../error/error.service";
import { MessagingService } from "../utility/messaging/messaging.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-search-view",
  templateUrl: "./search-view.component.html",
  styleUrls: ["./search-view.component.scss"]
})
export class SearchViewComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dataSearch: ISearchElement[];
  isLoading: boolean = true;
  public searchItems: IElementFile[] = [];
  public files = new BehaviorSubject([]);
  public batch: number = 20;
  public lastKey: string = "";
  public finished: boolean = false;

  constructor(
    private router: Router,
    private searchService: SearchService,
    private authService: AuthService,
    private filtersService: FiltersService,
    private errorService: ErrorService,
    private messagingService: MessagingService,
    private title: Title
  ) {
    this.title.setTitle('YeahNotes Busqueda');
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.isLoading = false;
    this.filtersService.loadFirstData();
    this.authService.getTokenHeader();
    this.filtersService.onFiltersUpdate()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.files = new BehaviorSubject([]);
      this.finished = false;
      this.lastKey = null;
      this.getFiles();
    }, err => this.errorService.showError(err));
    this.getFiles();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getFiles() {
    if (this.finished) {
      return;
    }
    this.searchService
      .getFilesByFilters(null, this.lastKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe(files => {
        this.searchItems = [];
        if (files && files.length) {
          const lastItem = files[files.length - 1];
          if (lastItem) {
            this.lastKey = lastItem.uid;
          }
          const currentFiles = this.files.getValue();
          const lastCurrentItem = currentFiles[currentFiles.length - 1];
          if (lastCurrentItem && lastCurrentItem.uid === this.lastKey) {
            this.finished = true;
            return;
          }
          if (files.length < this.batch) {
            this.finished = true;
          }
          this.files.next(currentFiles.concat(files));
          this.searchItems = this.files.getValue();
        } else {
          this.finished = true;
          this.searchItems = this.files.getValue();
        }
      });
  }

  onScroll() {
    this.getFiles();
  }

  onClickElement(id: string) {
    this.router.navigate([`/file/${id}`]);
  }

  onClickSearch(filters: IFileFiltersData) {
    this.filtersService.setFilters(filters);
  }

  cleanFilters() {
    this.filtersService.cleanFilters();
  }
}
