import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import PDFDocument from "pdf-lib/cjs/api/PDFDocument";
import { auth } from "firebase/app";
import { INewFile } from "../models/models";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { host } from "../../constant";
import { Observable } from "rxjs";

export enum TYPES_TO_VALIDATE {
  ALL = "ALL",
  IMG = "IMG",
  PNG = "image/png",
  JPG = "image/jpeg",
  PDF = "application/pdf",
  HEIC = "image/heic",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  DOC = "application/msword",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ZIP = "application/zip",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
}

export enum FORMATS_FILE_KEY {
  IMG = "Imagen",
  IMGS = "Imagenes",
  PDF = "PDF"
}

export const validFileTypes: string[] = [
  TYPES_TO_VALIDATE.PNG,
  TYPES_TO_VALIDATE.JPG,
  TYPES_TO_VALIDATE.PDF,
  TYPES_TO_VALIDATE.HEIC,
  TYPES_TO_VALIDATE.DOCX,
  TYPES_TO_VALIDATE.DOC,
  TYPES_TO_VALIDATE.PPTX,
  TYPES_TO_VALIDATE.XLSX,
  TYPES_TO_VALIDATE.ZIP,
];

export const validImagesTypes: string[] = [
  TYPES_TO_VALIDATE.PNG,
  TYPES_TO_VALIDATE.JPG,
  TYPES_TO_VALIDATE.HEIC
];

@Injectable({
  providedIn: "root"
})
export class ModalUpFileService {
  private host: string = host;

  constructor(private firestore: AngularFirestore, private http: HttpClient) {}

  getFileFormat(files: File[]): string {
    if (files.length > 1) {
      return FORMATS_FILE_KEY.IMGS;
    }
    switch (files[0].type) {
      case TYPES_TO_VALIDATE.PNG:
        return FORMATS_FILE_KEY.IMG;
      case TYPES_TO_VALIDATE.JPG:
        return FORMATS_FILE_KEY.IMG;
      case TYPES_TO_VALIDATE.HEIC:
        return FORMATS_FILE_KEY.IMG;
      case TYPES_TO_VALIDATE.PDF:
        return FORMATS_FILE_KEY.PDF;
    }
  }

  getFileTypesById(id: string) {
    return this.firestore
      .collection("fileTypes")
      .doc(id)
      .ref.get();
  }

  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }

  bytesToSize(bytes) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = 2 < 0 ? 0 : 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  addFile(data: INewFile, files: File[]): Observable<any> {
    const route = `${host}/files`;
    const fd = new FormData();
    files.map(item => fd.append("file", item));
    fd.append("uidType", data.uidType);
    fd.append("uidSchool", data.uidSchool);
    fd.append("uidCareer", data.uidCareer);
    fd.append("description", data.description);
    fd.append("title", data.title);
    fd.append("price", data.price.toString());
    if(data.uidSubject) {
      fd.append("uidSubject", data.uidSubject);
    }
    if(data.teacher) {
      fd.append("teacher", data.teacher);
    }
    if(data.subject) {
      fd.append("subject", data.subject);
    }
    if(data.uidTeacher) {
      fd.append("uidTeacher", data.uidTeacher);
    }
    const headers = new HttpHeaders({ enctype: "multipart/form-data" });
    return this.http.post<any>(route, fd, { headers });
  }
}
