import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { FiltersService } from 'src/app/utility/file-filters/filters.service';
import { IFileFiltersData } from 'src/app/utility/models/models';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentFilters: IFileFiltersData;
  chips: any[] = [];
  constructor(
    private filtersService: FiltersService,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.filtersService.onFiltersUpdate()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.currentFilters = this.authService.getFilterPreferences();
      if(this.currentFilters && this.currentFilters.origin != 'chips') {
        this.buildChips();
      }
    });
    this.currentFilters = this.authService.getFilterPreferences();
    this.buildChips();
  }

  buildChips() {
    const chips = [];
    if(this.currentFilters.school) {
      const schoolChip = {
        type: 'school', 
        displayName: this.currentFilters.school.nickname,
        uid: this.currentFilters.school.uid 
      }
      chips.push(schoolChip);
    }
    if(this.currentFilters.career) {
      const careerChip = {
        type: 'career',
        displayName: this.currentFilters.career.name,
        uid: this.currentFilters.career.uid
      }
      chips.push(careerChip);
    }
    if(this.currentFilters.subject) {
      const subjectChip = {
        type: 'subject',
        displayName: this.currentFilters.subject.name,
        uid: this.currentFilters.subject.uid
      }
      chips.push(subjectChip);
    }
    if(this.currentFilters.teacher) {
      const teacherChip = {
        type: 'teacher',
        displayName: this.currentFilters.teacher.name,
        uid: this.currentFilters.teacher.uid
      }
      chips.push(teacherChip);
    }

    if(this.currentFilters.types && this.currentFilters.types.length) {
      this.currentFilters.types.forEach(elm => {
        const typeChip = {
          type: 'type',
          displayName: elm.name,
          uid: elm.uid
        }
        chips.push(typeChip);
      });
    }

    if(this.currentFilters.formats && this.currentFilters.formats.length) {
      this.currentFilters.formats.forEach(elm => {
        const formatChip = {
          type: 'format',
          displayName: elm.name,
          uid: elm.uid
        }
        chips.push(formatChip);
      });
    }

    if(this.currentFilters.score && this.currentFilters.score != 1) {
      const scoreChip = {
        type: 'score',
        displayName: `☆ ${this.currentFilters.score} - 5`,
        uid: this.currentFilters.score
      }
      chips.push(scoreChip);
    }

    if(this.currentFilters.search && this.currentFilters.search.length) {
      const scoreChip = {
        type: 'search',
        displayName: `"${this.currentFilters.search}"`,
        uid: this.currentFilters.search
      }
      chips.push(scoreChip);
    }

    this.chips = chips;
  }

  removeFilter(elm) {
    if(elm && elm.uid) {
      const currentFilters = this.filtersService.getCurrentFilters();
      const filterPreference = this.authService.getFilterPreferences();
      switch(elm.type) {
        case 'career': 
          const chips = this.chips.filter(chip => chip.uid != elm.uid);
          const newCF = {...currentFilters, ...{uidCareer:undefined}};
          const newFP = {...filterPreference, ...{career: undefined, origin: 'chips'}};
          this.authService.setFilterPreferences(newFP);
          this.filtersService.setFilters(newCF);
          this.chips = chips;
          this.ref.detectChanges();
          break;
        case 'subject': 
          const chips1 = this.chips.filter(chip => chip.uid != elm.uid);
          const newCF1 = {...currentFilters, ...{uidSubject:undefined}};
          const newFP1 = {...filterPreference, ...{subject: undefined, origin: 'chips'}};
          this.authService.setFilterPreferences(newFP1);
          this.filtersService.setFilters(newCF1);
          this.chips = chips1;
          this.ref.detectChanges();
          break;
        case 'teacher': 
          const chips2 = this.chips.filter(chip => chip.uid != elm.uid);
          const newCF2 = {...currentFilters, ...{uidTeacher:undefined}};
          const newFP2 = {...filterPreference, ...{teacher: undefined, origin: 'chips'}};
          this.authService.setFilterPreferences(newFP2);
          this.filtersService.setFilters(newCF2);
          this.chips = chips2;
          this.ref.detectChanges();
          break;
        case 'score': 
          const chips3 = this.chips.filter(chip => chip.uid != elm.uid);
          const newCF3 = {...currentFilters, ...{score:undefined}};
          const newFP3 = {...filterPreference, ...{score: 1, origin: 'chips'}};
          this.authService.setFilterPreferences(newFP3);
          this.filtersService.setFilters(newCF3);
          this.chips = chips3;
          this.ref.detectChanges();
          break;
        case 'search': 
          const chipsSea = this.chips.filter(chip => chip.uid != elm.uid);
          const newCFsea = {...currentFilters, ...{search:undefined}};
          const newFPsea = {...filterPreference, ...{search: undefined, origin: 'chips'}};
          this.authService.setFilterPreferences(newFPsea);
          this.filtersService.setFilters(newCFsea);
          this.chips = chipsSea;
          this.ref.detectChanges();
          break;
        case 'format': 
          const chips4 = this.chips.filter(chip => chip.uid != elm.uid);
          const format = currentFilters.format ? currentFilters.format : [];
          const formats = filterPreference.formats ? filterPreference.formats : [];
          const newFormat = format.filter(eleFor => eleFor.idElement != elm.uid);
          const newFormats = formats.filter(eleFor => eleFor.uid != elm.uid);
          const newCF4 = {...currentFilters, ...{format:newFormat}};
          const newFP4 = {...filterPreference, ...{formats: newFormats, origin: 'chips'}};
          this.authService.setFilterPreferences(newFP4);
          this.filtersService.setFilters(newCF4);
          this.chips = chips4;
          this.ref.detectChanges();
          break;
        case 'type': 
          const chips5 = this.chips.filter(chip => chip.uid != elm.uid);
          const type = currentFilters.type ? currentFilters.type : [];
          const types = filterPreference.types ? filterPreference.types : [];
          const newType = type.filter(eleFor => eleFor.idElement != elm.uid);
          const newTypes = types.filter(eleFor => eleFor.uid != elm.uid);
          const newCF5 = {...currentFilters, ...{type:newType}};
          const newFP5 = {...filterPreference, ...{types: newTypes, origin: 'chips'}};
          this.authService.setFilterPreferences(newFP5);
          this.filtersService.setFilters(newCF5);
          this.chips = chips5;
          this.ref.detectChanges();
          break;
        default:
          break;
      }
    }
  }

}
