import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AuthService } from "src/app/auth/auth.service";
import { IChipOption } from "src/app/file/file.types";
import { IUser } from "src/app/models/user";
import { SelectSchoolService } from "src/app/select-school/select-school.service";
import { DialogButtonsComponent } from "src/app/utility/dialogs/dialog-buttons/dialog-buttons.component";
import {
  trigger,
  transition,
  animate,
  style,
  state
} from "@angular/animations";
import { Observable, Subject } from "rxjs";
import { ProfileService } from "../../profile.service";
import { debounceTime, takeUntil } from "rxjs/operators";
import { FiltersService } from "src/app/utility/file-filters/filters.service";
import { ErrorService } from "src/app/error/error.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-settings",
  animations: [
    trigger("return", [
      state("*", style({ opacity: 1, width: "*" })),
      transition(":enter", [
        style({ opacity: 0, width: 0 }),
        animate("6.5s ease-in")
      ])
    ]),
    trigger("enter", [
      state("*", style({ opacity: 1, width: "*" })),
      transition(":enter", [
        style({ opacity: 0, width: 0 }),
        animate("0.5s ease-in")
      ])
    ])
  ],
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, OnDestroy{
  chips: IChipOption[] = [
    { idElement: "1", label: "😀", status: true },
    { idElement: "2", label: "😃", status: false },
    { idElement: "3", label: "😄", status: false },
    { idElement: "4", label: "😁", status: false },
    { idElement: "5", label: "😆", status: false },
    { idElement: "6", label: "😅", status: false },
    { idElement: "7", label: "😊", status: false },
    { idElement: "8", label: "😇", status: false },
    { idElement: "9", label: "🙂", status: false },
    { idElement: "10", label: "🙃", status: false },
    { idElement: "11", label: "😉", status: false },
    { idElement: "12", label: "😌", status: false },
    { idElement: "13", label: "😗", status: false },
    { idElement: "14", label: "😜", status: false },
    { idElement: "15", label: "🤪", status: false },
    { idElement: "16", label: "🤓", status: false },
    { idElement: "17", label: "😎", status: false },
    { idElement: "18", label: "🤩", status: false },
    { idElement: "19", label: "🥳", status: false },
    { idElement: "20", label: "😈", status: false },
    { idElement: "21", label: "💩", status: false },
    { idElement: "22", label: "👻", status: false },
    { idElement: "23", label: "👽", status: false },
    { idElement: "24", label: "🤖", status: false },
    { idElement: "25", label: "🎃", status: false },
    { idElement: "26", label: "😺", status: false },
    { idElement: "27", label: "🐶", status: false },
    { idElement: "28", label: "🐱", status: false },
    { idElement: "29", label: "🐭", status: false },
    { idElement: "30", label: "🐹", status: false },
    { idElement: "31", label: "🐰", status: false },
    { idElement: "32", label: "🦊", status: false },
    { idElement: "33", label: "🐻", status: false },
    { idElement: "34", label: "🐼", status: false },
    { idElement: "35", label: "🐨", status: false },
    { idElement: "36", label: "🐯", status: false },
    { idElement: "37", label: "🦁", status: false },
    { idElement: "38", label: "🐮", status: false },
    { idElement: "39", label: "🐷", status: false },
    { idElement: "40", label: "🐸", status: false },
    { idElement: "41", label: "🐵", status: false }
  ];
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  public institutions = [];
  public schools = [];
  public careers = [];
  public subjects = [];
  public teachers = [];
  public savedSubjects = [];
  public savedTeachers = [];
  public subjectsSelected: any[] = [];
  public teachersSelected: any[] = [];
  public careerSelected: any;
  public schoolSelected: any;
  public institutionSelected: any;
  public currentEmoji: IChipOption;
  public preselectedEmoji: Array<IChipOption> = [];
  public selectedEmoji: IChipOption;
  public viewLevel: number = 0;
  public view: string;
  public loader: boolean = false;
  isOpen = true;
  public nickname: string;
  public hasNicknameError: boolean = false;
  public code: string;
  public hasCodeError: boolean = false;
  public isDataTouched: boolean = false;
  public copySavedSubject = [];
  public copySavedTeachers = [];

  public nFiles: boolean = true;
  public nDown: boolean = true;
  public nProm: boolean = true;
  public nCust: boolean = true;

  @Output() onCloseSettings = new EventEmitter<void>();
  @Input("showCloseButton") showCloseButton: boolean = true;

  selectedPeople = [{ name: "Karyn Wright" }];
  people = [
    { name: "Karyn Wright" },
    { name: "Dan Wright" },
    { name: "Youn Wright" },
    { name: "Vieny Wright" }
  ];

  constructor(
    private selectSchoolService: SelectSchoolService,
    private authService: AuthService,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private filtersService: FiltersService,
    private errorService: ErrorService,
    private router: Router,
  ) {}

  closeSettings() {
    this.onCloseSettings.emit();
  }

  ngOnInit(): void {
    this.profileService.onRestartSettings().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.viewLevel = 0;
      this.view = null;
      this.findCurrentInstitution();
      this.findNotifications();
    },error => {
      this.errorService.showError(error);
    });
    this.selectSchoolService.getInstitutions().subscribe(catsSnapshot => {
      this.institutions = [];
      catsSnapshot.forEach((institution: any) => {
        const data = institution.payload.doc.data();
        this.institutions.push({
          ...data,
          ...{ label: `${data.nickname} ${data.name}` }
        });
        this.findCurrentInstitution();
      });
    });
    this.profileService.getNotificationsSettings().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.savedTeachers = data.teachers || [];
        this.savedSubjects = data.subjects || [];
      }
    }, error => {
      this.errorService.showError(error);
    });
    this.setSaveValues();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setSaveValues() {
    this.findCurrentEmoji();
    this.findCurrentNickname();
    this.findNotifications();
    this.code = "@";
  }

  findCurrentEmoji() {
    const sesionEmoji = this.authService.getUser().emoji;
    this.currentEmoji =
      this.chips.find(chip => chip.label === sesionEmoji) || undefined;
    this.preselectedEmoji = [this.currentEmoji];
  }

  findNotifications() {
    const notificatitons = this.authService.getUser()
      ? this.authService.getUser().notifications
      : null;
    if (notificatitons) {
      this.nFiles = notificatitons.files || false;
      this.nDown = notificatitons.downloads || false;
      this.nProm = notificatitons.promotions || false;
      this.nCust = notificatitons.custom || false;
    }
  }

  setSubjectNotifications() {
    const subjectsSelected = [];
    this.savedSubjects.forEach(element => {
      const elementMatch = this.subjects.find(e => e.uid === element.subject);
      if (elementMatch) {
        subjectsSelected.push(elementMatch);
      }
    });
    this.subjectsSelected = subjectsSelected;
    this.copySavedSubject = this.subjectsSelected;
  }

  setTeachersNotifications() {
    const teachersSelected = [];
    this.savedTeachers.forEach(element => {
      const elementMatch = this.teachers.find(e => e.uid === element.teacher);
      if (elementMatch) {
        teachersSelected.push(elementMatch);
      }
    });
    this.teachersSelected = teachersSelected;
    this.copySavedTeachers = this.teachersSelected;
  }

  findCurrentNickname() {
    this.nickname = this.authService.getUser().nickname || "";
  }

  findCurrentInstitution() {
    const currentInstitution = this.authService.getUser().institution;
    if (currentInstitution) {
      this.institutionSelected = this.institutions.find(
        institution => institution.uid === currentInstitution
      );
      if (this.institutionSelected) {
        this.onInstitutionSelected(this.institutionSelected, true);
      }
    }
  }

  recoverSchool() {
    const currentSchool = this.authService.getUser().school;
    if (currentSchool) {
      this.schoolSelected = this.schools.find(
        school => school.uid === currentSchool
      );
      if (this.schoolSelected) {
        this.onSchoolSelected(this.schoolSelected, true);
      }
    }
  }

  onInstitutionSelected(institution: any, recover: boolean = false) {
    this.schools = [];
    this.schoolSelected = undefined;
    if (institution && institution.uid) {
      this.selectSchoolService
        .getSchoolsByInstitution(institution.uid)
        .pipe(takeUntil(this.destroy$))
        .subscribe(institution => {
          const schools = [];
          institution.forEach((school: any) => {
            const data = school.payload.doc.data();
            schools.push({
              ...data,
              ...{ label: `${data.nickname} ${data.name}` }
            });
          });
          this.schools = schools;
          if (recover) {
            this.recoverSchool();
          }
        }, error => {
          this.errorService.showError(error);
        });
    }
  }

  onSchoolSelected(school: any, recover: boolean = false) {
    this.careers = [];
    this.careerSelected = undefined;
    if (school && school.careers) {
      this.getTeachers(school.uid);
      const careers = school ? school.careers : undefined;
      this.filtersService.getCareers(careers).pipe(takeUntil(this.destroy$)).subscribe(catsSnapshot => {
        this.careers = [];
        catsSnapshot.forEach((institution: any) => {
          const data = institution.data();
          this.careers.push(data);
        });
        if (recover) {
          const currentCareer = this.authService.getUser().career;
          if (currentCareer) {
            this.careerSelected = this.careers.find(
              career => career.uid === currentCareer
            );
          }
          this.subjects = this.careerSelected.subjects;
        }
      }, error => {
        this.errorService.showError(error);
      });
    }
  }

  getTeachers(uid: string) {
    this.filtersService.getTeachersBySchool(uid).pipe(takeUntil(this.destroy$)).subscribe(catsSnapshot => {
      this.teachers = [];
      catsSnapshot.forEach((teacher: any) => {
        this.teachers.push(teacher.payload.doc.data());
      });
    }, error => {
      this.errorService.showError(error);
    });
  }

  saveSchool() {
    if (!this.loader) {
      this.isDataTouched = true;
      if (
        this.schoolSelected &&
        this.institutionSelected &&
        this.careerSelected
      ) {
        this.loader = true;
        this.profileService
          .updateMe(
            null,
            null,
            this.schoolSelected.uid,
            this.institutionSelected.uid,
            this.careerSelected.uid
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            const user: IUser = data;
            this.authService.setUser(user);
            this.loader = false;
          }, error => {
            this.errorService.showError(error);
            this.loader = false;
          });
      }
    }
  }

  saveCode() {
    if (!this.loader) {
      const regex = /^[a-zA-Z0-9@áéíñóúüÁÉÍÑÓÚÜñÑ]*$/g;
      if (this.code) {
        if (this.code.match(regex)) {
          this.hasCodeError = false;
          this.loader = true;
          this.profileService.redeemCode(this.code)
          .pipe(takeUntil(this.destroy$)).subscribe(data => {
            if(data) {
              const user:IUser = this.authService.getUser();
              user.yeahcoins = user.yeahcoins + data.yeahcoins
              this.authService.setUser(user);
            }
            this.loader = false;
            this.router.navigate(["/profile/transactions"]);
          }, err => {
            this.errorService.showError(err);
            this.loader = false;
          });
        } else {
          this.hasCodeError = true;
        }
      }
    }
  }

  saveNickname() {
    if (!this.loader) {
      const regex = /^[a-zA-Z0-9áéíñóúüÁÉÍÑÓÚÜñÑ]*$/g;
      if (this.nickname) {
        if (
          this.nickname.length > 3 &&
          this.nickname.match(regex) &&
          this.nickname.length < 16
        ) {
          this.hasNicknameError = false;
          this.loader = true;
          this.profileService.updateMe(this.nickname)
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            const user: IUser = data;
            this.authService.setUser(user);
            this.loader = false;
          }, error => {
            this.loader = false;
            this.errorService.showError(error);
          });
        } else {
          this.hasNicknameError = true;
        }
      }
    }
  }

  onIconSelected(selected: IChipOption) {
    if (selected && selected.label) {
      this.selectedEmoji = selected;
    }
  }

  saveIcon() {
    if (!this.loader) {
      if (this.currentEmoji !== this.selectedEmoji) {
        this.loader = true;
        this.currentEmoji = this.selectedEmoji;
        this.profileService
          .updateMe(null, this.currentEmoji.label)
          .pipe(debounceTime(500), takeUntil(this.destroy$))
          .subscribe(data => {
            const user: IUser = data;
            this.authService.setUser(user);
            this.loader = false;
          }, error => {
            this.loader = false;
            this.errorService.showError(error);
          });
      }
    }
  }

  changeView(level: number = 0, view?: string) {
    this.viewLevel = level;
    this.view = view;
    this.isOpen = !this.isOpen;
    if (level === 0) {
      this.findCurrentInstitution();
      this.findNotifications();
    }
    if (view === "notifications") {
      this.setSubjectNotifications();
      this.setTeachersNotifications();
    }
  }

  closeAccount() {
    const reportDialog = this.dialog.open(DialogButtonsComponent);
    const options: MatDialogConfig = {
      id: "closeAccount",
      data: {
        title: "Dar de baja cuenta",
        textBody:
          "Para dar de baja tu cuenta envia un correo a la administracion de yeahnotes desde el correo que quieres dar de baja."
      }
    };
    reportDialog.componentInstance.dialogConfig = options;
    reportDialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value && value !== 0) {
        // Inicia el proceso de cerrar la cuenta
      }
    }, error => {
      this.errorService.showError(error);
    });
  }

  savePreferences() {
    this.loader = true;
    const teachersToSave = [];
    this.teachersSelected.forEach(elm => {
      const foundIt = this.savedTeachers.find(e => e.teacher === elm.uid);
      if (foundIt) {
        teachersToSave.push(foundIt);
      } else {
        teachersToSave.push({
          type: "teacher",
          status: true,
          teacher: elm.uid,
          teacherName: elm.teacher
        });
      }
    });
    const subjectsToSave = [];
    this.subjectsSelected.forEach(elm => {
      const foundIt = this.savedSubjects.find(e => e.subject === elm.uid);
      if (foundIt) {
        subjectsToSave.push(foundIt);
      } else {
        subjectsToSave.push({
          type: "subject",
          status: true,
          subject: elm.uid,
          subjectName: elm.name
        });
      }
    });
    const preferences = {
      teachers: teachersToSave,
      subjects: subjectsToSave
    };
    this.profileService
      .setNotificationsSettings(preferences)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.savedTeachers = data.teachers || [];
          this.savedSubjects = data.subjects || [];
        }
        this.loader = false;
      }, error => {
        this.errorService.showError(error);
      });
    const flags = {
      notifications: {
        custom: this.nCust,
        downloads: this.nDown,
        files: this.nFiles,
        promotions: this.nProm
      }
    };
    this.profileService
      .updateMe(null, null, null, null, null, flags)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const user: IUser = data;
        this.authService.setUser(user);
      }, error => {
        this.errorService.showError(error);
      });
  }
}
