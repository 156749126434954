<div class="author-container">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Calificación del Archivo:
                </mat-panel-title>
                <mat-panel-description>
                    <span class="star mat-icon-container" *ngIf="ratings.average"><mat-icon>star</mat-icon> {{ratings.average}}</span>
                    <span class="star" *ngIf="!ratings.average">Sin calificación</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="ratings.average" class="rating">
                <div class="stars">
                    <mat-icon *ngFor="let fullstar of fullStars">star</mat-icon>
                    <mat-icon *ngFor="let fullstar of restStars">star_outline</mat-icon>
                </div>
                <div class="label-start">
                    {{ratings.average}} estrellas de 5
                </div>
            </div>
            <div *ngIf="ratings.average" class="numbeRankins">
                {{ratings.countRatings}} calificaciones
            </div>
            <div *ngIf="ratings.average" class="progress-porcentage">
                <div class="porcentage">
                    <div class="measures">
                        <div class="stars">
                            <div class="number">5</div>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                        </div>
                        <div class="number-percentage">
                            {{ratings.percentage5}}%
                        </div>
                    </div>
                    <mat-progress-bar mode="determinate" value="{{ratings.percentage5}}"></mat-progress-bar>
                </div>
                <div class="porcentage">
                    <div class="measures">
                        <div class="stars">
                            <div class="number">4</div>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                        </div>
                        <div class="number-percentage">
                            {{ratings.percentage4}}%
                        </div>
                    </div>
                    <mat-progress-bar mode="determinate" value="{{ratings.percentage4}}"></mat-progress-bar>
                </div>
                <div class="porcentage">
                    <div class="measures">
                        <div class="stars">
                            <div class="number">3</div>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                        </div>
                        <div class="number-percentage">
                            {{ratings.percentage3}}%
                        </div>
                    </div>
                    <mat-progress-bar mode="determinate" value="{{ratings.percentage3}}"></mat-progress-bar>
                </div>
                <div class="porcentage">
                    <div class="measures">
                        <div class="stars">
                            <div class="number">2</div>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                        </div>
                        <div class="number-percentage">
                            {{ratings.percentage2}}%
                        </div>
                    </div>
                    <mat-progress-bar mode="determinate" value="{{ratings.percentage2}}"></mat-progress-bar>
                </div>
                <div class="porcentage">
                    <div class="measures">
                        <div class="stars">
                            <div class="number">1</div>
                            <mat-icon>star</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                            <mat-icon>star_outline</mat-icon>
                        </div>
                        <div class="number-percentage">
                            {{ratings.percentage1}}%
                        </div>
                    </div>
                    <mat-progress-bar mode="determinate" value="{{ratings.percentage1}}"></mat-progress-bar>
                </div>
            </div>
            <div *ngIf="!ratings.average" class="noAverage">
                Este archivo aun no ha sido calificado por nadie. ☹️
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>