import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  AfterContentInit
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-full-img",
  templateUrl: "./full-img.component.html",
  styleUrls: ["./full-img.component.scss"]
})
export class FullImgComponent implements OnInit, AfterContentInit {
  @ViewChild("FullImageContainer", { static: false }) image: ElementRef;
  currentImage: number = 0;
  images: Blob[] = [];
  rotationAmount: number = 0;
  scale: number = 1;
  public currentObject: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FullImgComponent>
  ) {}

  ngOnInit(): void {
    this.currentImage = this.data.currentIndex;
    this.images = this.data.images;
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.showImage(this.currentImage);
    }, 1000);
  }

  showImage(index: number = 0) {
    this.cleanTransformStyle();
    this.image.nativeElement.src = window.URL.createObjectURL(
      this.images[this.currentImage]
    );
    this.currentObject = window.URL.createObjectURL(
      this.images[this.currentImage]
    );
  }

  zoomIn() {
    if (this.scale < 15) {
      this.scale = this.scale + 0.5;
    }
    this.applyScaleAndRoute(true);
  }

  zoomOut() {
    if (this.scale > 1) {
      this.scale = this.scale - 0.5;
    }
    this.applyScaleAndRoute(true);
  }

  rotate() {
    if (this.rotationAmount < 360) {
      this.rotationAmount = this.rotationAmount + 90;
    } else {
      this.rotationAmount = 90;
    }
    this.applyScaleAndRoute();
  }

  applyScaleAndRoute(zoom: boolean = false) {
    if (zoom) {
      switch (this.rotationAmount) {
        case 0:
        case 360:
          this.image.nativeElement.style["transform-origin"] = "top";
          break;
        case 90:
        case 180:
        case 270:
          this.image.nativeElement.style["transform-origin"] = "center";
          break;
      }
    } else {
      this.image.nativeElement.style["transform-origin"] = "center";
    }

    this.image.nativeElement.style.transform = `rotate(${this.rotationAmount}deg) scale(${this.scale})`;
  }

  download() {
    window.open(this.currentObject);
  }

  close() {
    this.dialogRef.close(this.currentImage);
  }

  cleanTransformStyle() {
    this.rotationAmount = 0;
    this.scale = 1;
  }

  preview() {
    if (1 <= this.currentImage) {
      this.currentImage = this.currentImage - 1;
      this.showImage(this.currentImage);
    } else {
      this.currentImage = this.images.length - 1;
      this.showImage(this.currentImage);
    }
  }

  next() {
    const newIndex = this.currentImage + 1;
    if (this.images.length > newIndex) {
      this.currentImage = newIndex;
      this.showImage(this.currentImage);
    } else {
      this.currentImage = 0;
      this.showImage(this.currentImage);
    }
  }
}
