<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="isLoading">
    <div class="header">
        <div class="title-header">Videos disponibles</div>
    </div>
    <div class="videos-container">
        <div class="videos">

            <div *ngFor="let video of videos" class="video">
                <div *ngIf="!video.urlImg" class="play-gray">
                    <mat-icon>play_arrow</mat-icon>
                </div>
                <div *ngIf="video.urlImg" class="img">
                    <img src="{{video.urlImg}}" alt="">
                </div>
                <div class="desc">
                    <div class="title">{{video.title}}</div>
                    <div class="time">{{video.duration}}</div>
                </div>
                <div class="points">
                    <button class="green" (click)="watchVideo(video)">Ver video por {{video.yeahcoins}} yeahcoins</button>
                </div>
            </div>
        </div>
    </div>
</app-internal-component>