import { Component, OnInit, Output, EventEmitter, Inject, ChangeDetectorRef, OnDestroy } from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from "@angular/material/bottom-sheet";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "src/app/error/error.service";
import { YeahcoinsService } from "src/app/yeahcoins/yeahcoins.service";

@Component({
  selector: "app-bottom-sheet-pay",
  templateUrl: "./bottom-sheet-pay.component.html",
  styleUrls: ["./bottom-sheet-pay.component.scss"]
})
export class BottomSheetPayComponent implements OnInit, OnDestroy {
  @Output() onClickObtainFile = new EventEmitter<string>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private ref: ChangeDetectorRef,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetPayComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private yeahcoinsService: YeahcoinsService,
    private errorService: ErrorService,
  ) {}

  public credit: number;
  public price: number;
  public dateToShow: string;
  public loading:boolean;

  ngOnInit(): void {
    this.loading = true;
    if (this.data) {
      this.yeahcoinsService.getUserData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res && res.yeahcoins) {
          this.credit = res.yeahcoins;
          this.price = this.data.price;
          this.getToday();
          this.loading = false;
          this.ref.detectChanges();
        }
      }, error => {
        this.errorService.showError(error);
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCloseBSPay() {
    this._bottomSheetRef.dismiss();
  }

  obtainFile() {
    this._bottomSheetRef.dismiss("obtain");
  }

  getMoreCoins() {
    this._bottomSheetRef.dismiss("coins");
  }

  getToday() {
    const meses = new Array(
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    );
    const diasSemana = new Array(
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    );
    const f = new Date();
    this.dateToShow = `Hoy ${diasSemana[f.getDay()]}, ${f.getDate()} de ${
      meses[f.getMonth()]
    }`;
  }
}
