import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { APP_ROUTING } from "./app.routes";
import { ConditionsComponent } from "./conditions/conditions.component";
import { SearchViewComponent } from "./search-view/search-view.component";
import { NavbarComponent } from "./utility/navbar/navbar.component";
import { InternalComponentComponent } from "./utility/internal-component/internal-component.component";
import { FooterComponent } from "./utility/footer/footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DialogFilterOptionsComponent } from "./utility/dialogs/dialog-filter-options/dialog-filter-options.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { CommentsComponent } from "./utility/comments/comments.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { AuthorComponent } from "./utility/author/author.component";
import { DialogConfirmPurchaseComponent } from "./utility/dialogs/dialog-confirm-purchase/dialog-confirm-purchase.component";
import { FileComponent } from "./file/file.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { BottomSheetPayComponent } from "./utility/bottoms-sheet/bottom-sheet-pay/bottom-sheet-pay.component";
import { ProfileComponent } from "./profile/profile.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDividerModule } from "@angular/material/divider";
import { MatTableModule } from "@angular/material/table";
import { MatChipsModule } from "@angular/material/chips";
import { FilesListComponent } from "./utility/files-list/files-list.component";
import { SearchItemsListComponent } from "./utility/search-items-list/search-items-list.component";
import { FullLoaderComponent } from "./utility/full-loader/full-loader.component";
import { MatMenuModule } from "@angular/material/menu";
import { FileFiltersComponent } from "./utility/file-filters/file-filters.component";
import { CheckboxChipsListComponent } from "./utility/checkbox-chips-list/checkbox-chips-list.component";
import { MatStepperModule } from "@angular/material/stepper";
import { ActionIconComponent } from "./utility/action-icon/action-icon.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment, environmentTest } from "./../environments/environment";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthService } from "./auth/auth.service";
import { ErrorDialogComponent } from "./utility/dialog/error-dialog/error-dialog.component";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { CanShowGuard, AuthGuard } from "./guards/can-show.guard";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { SelectSchoolComponent } from "./select-school/select-school.component";
import { FiltersService } from "./utility/file-filters/filters.service";
import { ModalUpFileComponent } from "./utility/modal-up-file/modal-up-file.component";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { DragDropDirective } from "./utility/drag-drop.directive";
import { SizeFilePipe } from "./pipes/size-file.pipe";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { TokenInterceptorService } from "./interceptors/token-interceptor.service";
import { DialogButtonsComponent } from "./utility/dialogs/dialog-buttons/dialog-buttons.component";
import { ImgGalleryComponent } from "./utility/img-gallery/img-gallery.component";
import { FullImgComponent } from "./utility/full-img/full-img.component";
import { PdfViewerComponent } from "./utility/pdf-viewer/pdf-viewer.component";
import { PdfViewerFullComponent } from "./utility/pdf-viewer-full/pdf-viewer-full.component";
import { FileViewerComponent } from "./utility/file-viewer/file-viewer.component";
import { ToBuyViewerComponent } from "./utility/to-buy-viewer/to-buy-viewer.component";
import { YeahcoinsComponent } from "./yeahcoins/yeahcoins.component";
import { HeaderProfileComponent } from "./profile/components/header-profile/header-profile.component";
import { ListItemsComponent } from "./utility/list-items/list-items.component";
import { SettingsComponent } from "./profile/components/settings/settings.component";
import { VideoComponent } from "./video/video.component";
import { MatListModule } from "@angular/material/list";
import { LoaderComponent } from "./utility/loader/loader.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MovementsComponent } from "./profile/components/movements/movements.component";
import { NotificationsComponent } from "./profile/components/notifications/notifications.component";
import { DownloadsComponent } from "./profile/components/downloads/downloads.component";
import { FilesComponent } from "./profile/components/files/files.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SnackbarComponent } from "./utility/snackbar/snackbar.component";
import { HistogramComponent } from "./utility/histogram/histogram.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { VideosComponent } from './videos/videos.component';
import { SafePipe } from './utility/pipes/safe.pipe';
import { RateFileComponent } from './utility/rate-file/rate-file.component';
import { ReportFileComponent } from './utility/report-file/report-file.component';
import {MatRadioModule} from '@angular/material/radio';
import { MessagingService } from "./utility/messaging/messaging.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { HowRatingsWorkComponent } from './how-ratings-work/how-ratings-work.component';
import { ValueMeComponent } from './utility/value-me/value-me.component';
import { PayModalComponent } from './utility/pay-modal/pay-modal.component';
import { ChipsComponent } from './search-view/components/chips/chips.component';
import { StarsComponent } from './utility/file-filters/components/stars/stars.component';
import { SpinAndWinComponent } from './utility/spin-and-win/spin-and-win.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConditionsComponent,
    SearchViewComponent,
    InternalComponentComponent,
    NavbarComponent,
    FooterComponent,
    DialogFilterOptionsComponent,
    CommentsComponent,
    AuthorComponent,
    DialogConfirmPurchaseComponent,
    DialogButtonsComponent,
    FileComponent,
    BottomSheetPayComponent,
    ProfileComponent,
    FilesListComponent,
    SearchItemsListComponent,
    FullLoaderComponent,
    FileFiltersComponent,
    CheckboxChipsListComponent,
    ActionIconComponent,
    ErrorDialogComponent,
    VerifyEmailComponent,
    SelectSchoolComponent,
    ModalUpFileComponent,
    DragDropDirective,
    SizeFilePipe,
    DialogButtonsComponent,
    ImgGalleryComponent,
    FullImgComponent,
    PdfViewerComponent,
    PdfViewerFullComponent,
    FileViewerComponent,
    ToBuyViewerComponent,
    YeahcoinsComponent,
    HeaderProfileComponent,
    ListItemsComponent,
    SettingsComponent,
    VideoComponent,
    LoaderComponent,
    MovementsComponent,
    NotificationsComponent,
    DownloadsComponent,
    FilesComponent,
    SnackbarComponent,
    HistogramComponent,
    VideosComponent,
    SafePipe,
    RateFileComponent,
    ReportFileComponent,
    HowRatingsWorkComponent,
    ValueMeComponent,
    PayModalComponent,
    ChipsComponent,
    StarsComponent,
    SpinAndWinComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    APP_ROUTING,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatTabsModule,
    MatDividerModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatMenuModule,
    MatStepperModule,
    MatListModule,
    MatRadioModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    PdfViewerModule,
    InfiniteScrollModule,
    MatProgressBarModule,
    AngularFireMessagingModule,
  ],
  entryComponents: [
    DialogFilterOptionsComponent,
    DialogConfirmPurchaseComponent,
    DialogButtonsComponent,
    ErrorDialogComponent,
    FileFiltersComponent,
    BottomSheetPayComponent,
    ModalUpFileComponent,
    ValueMeComponent,
    FullImgComponent,
    PdfViewerFullComponent,
    SnackbarComponent,
    RateFileComponent,
    ReportFileComponent,
    PayModalComponent,
  ],
  providers: [
    AuthService,
    AngularFireAuthGuard,
    CanShowGuard,
    AuthGuard,
    FiltersService,
    MessagingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    DialogFilterOptionsComponent,
    DialogConfirmPurchaseComponent,
    DialogButtonsComponent,
    ErrorDialogComponent,
    BottomSheetPayComponent,
    FileFiltersComponent,
    FilesListComponent,
    ModalUpFileComponent,
    ValueMeComponent,
    FullImgComponent,
    PdfViewerFullComponent
  ]
})
export class AppModule {}
