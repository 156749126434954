<div class="pay-body">

    <div class="container">
        <div class="head">
            <mat-icon *ngIf="type === 'approved'" class="icon approved">check_circle</mat-icon>
            <mat-icon *ngIf="type === 'in_process' || type === 'pending'" class="icon process">pending</mat-icon>
            <mat-icon *ngIf="type === 'rejected' || type === 'possibly-rejected'" class="icon rejected">dangerous</mat-icon>

            <p class="legeng">
                <span *ngIf="type === 'approved'">¡Transacción realizada con exito!</span>
                <span *ngIf="type === 'in_process' || type === 'pending'">¡Transacción Pendiente!</span>
                <span *ngIf="type === 'rejected' || type === 'possibly-rejected'">¡Transacción Rechazada!</span>
            </p>
        </div>
        <div class="body">
            <p class="text" *ngIf="type === 'approved'">El pago ha sido recibido con exito, tus yeahcoins se depositaran en algunos segundos. Revisa tus movimientos para confirmar la transacción.</p>
            <p class="text" *ngIf="type === 'pending'">Genial ya estas a punto de obtener tus yeahcoins, cuando realices la transacción nosotros te avisaremos cuando esten listos.</p>
            <p class="text" *ngIf="type === 'in_process'">La transacción se ha realizado pero el pago esta pendiente, tus yeahcoins se depositaran en cuanto se confirme el pago. Revisa tus movimientos para confirmar la transacción.</p>
            <p class="text" *ngIf="type === 'rejected'">El pago ha sido rechazado, si crees que es un error ponte en contacto con nosotros a traves de nuestros medios oficiales.</p>
            <p class="text" *ngIf="type === 'possibly-rejected'">Parece que la transacción ya fue aplicada o los datos fueron alterados, si crees que es un error ponte en contacto con nosotros a traves de nuestros medios oficiales.</p>
        </div>
        <div class="button">
            <button (click)="goToTransactions()" *ngIf="type === 'approved' || type === 'in_process'" class="button-yn">Revisar mis movimientos.</button>
            <button (click)="close()" *ngIf="type === 'rejected' || type === 'possibly-rejected'" class="button-yn rejected">Cerrar</button>
        </div>
    </div>

</div>