import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private _snackBar: MatSnackBar) { }

  getMessageError(error:string) {
    switch(error) {
      case 'Error 001: Access denied invalid token':
      case 'Error 002: Access denied you do not have permission to do this':
        return 'Accedo denegado, no tienes permiso de realizar esta acción.';
      case "Error 003: You don't have enough yeahcoins":
        return "No tienes suficientes yeahcoins para realizar esta operación.";
      case "Error 004: User not found":
        return "Usuario no encontrado.";
      case "Error 005: Cannot rate files that you have not obtained, check if the file is correct":
        return "Ya has obtenido este archivo antes."
      case "Error 006: Star must be a number between 0 and 5":
        return "La calificación del archivo debe ser de entre una y cinco estrellas."
      case "Error 007: User already rated this file previously":
        return "Ya has calificado este archivo previamente."
      case "Error 008: Video not found":
        return "Video no encontrado, intentalo nuevamente."
      case "Error 009: Video not played":
        return "El video no se reproducio de forma correcta."
      case "Error 010: You cannot modify finished views":
        return "No se registro la vista de forma correcta, intentalo de nuevo."
      case "Error 011: User cannot modify third-party views":
        return "No se registro la vista de forma correcta, intentalo de nuevo."
      case "Error 012: View not found":
        return "No se registro la vista de forma correcta, intentalo de nuevo."
      case "Error 013: User does not support video":
        return "El video no es compatible, intentalo con otro video."
      case "Error 014: The previous video is still valid":
        return "No se pueden reproducir varios videos simultaneamente. Espera algunos minutos e intentalo de nuevo."
      case "Error 015: User not has a career":
      case "Error 035: The user does not have a selected career":
        return "No tienes una carrera seleccionada, ve a tu perfil y configura tu carrera."
      case "Error 016: Both reactions cannot be recorded":
        return "No puedes dar me gusta y no me gusta al mismo tiempo."
      case "Error 017: Comment not found":
        return "Algo salio mal, no se encontro el comentario."
      case "Error 018: User has already reported this file before":
        return "Ya has reportado el archivo anteriormente."
      case "Error 019: Code report option not found":
        return "Opción de reporte invalida, intentalo de nuevo."
      case "Error 020: You have already downloaded this file before":
        return "Ya has descargado este archivo anteriormente."
      case "Error 021: Subject not found":
        return "Algo salio mal, no se encontro la asignatura."
      case "Error 022: Chosen career does not belong to the chosen school":
        return "La carrera seleccionada no pertenece a la escuela seleccionada."
      case "Error 023: Career not found":
        return "Algo salio mal, no se encontro la carrera seleccionada."
      case "Error 024: Teacher not found":
        return "Algo salio mal, no se encontro el profesor seleccionado."
      case "Error 025: Type not found":
        return "Algo salio mal, no se encontro el tipo de archivo seleccionado."
      case "Error 026: School not found":
        return "Algo salio mal, no se encontro la escuela seleccionada."
      case "Error 027: Author not found":
        return "Algo salio mal, el autor del archivo no fue encontrado."
      case "Error 028: Projects can only be compressed files":
        return "Los proyectos deben estar compuetos por varios archivos comprimidos."
      case "Error 029: To upload multiple files all must be images":
        return "Para subir varios archivos a la vez todos deben ser imagenes."
      case "Error 030: At least one file is required":
        return "Algo salio mal, no se recibio ningun archivo."
      case "Error 031: The file type is not accepted in yeahnotes":
        return "El formato de este archivo no es aceptado en yeahnotes."
      case "Error 032: Sort parameters are invalid":
        return "El parametro de ordenamiento es invalido."
      case "Error 033: The career was not found or does not belong to the institution":
        return "La carrera no fue encontrada o no pertenece a la escuela."
      case "Error 034: The school does not belong to the institution":
        return "La escuela no fue encontrada o no pertenece a la institución."
      case "Error 036: File not found":
        return "No se encontro el archivo."
      case "Error 037: Invalid query, incorrect headers or parameters":
        return "Algo salio mal en la busqueda, intentalo de nuevo."
      case "Error 038: Code previously redeemed":
        return "El código promocional ya fue canjeado anteriormente."
      case "Error 039: Code not supported by user":
        return "El código promocional no es compatible con tu usuario."
      case "Error 040: Code has expired":
        return "El código promocional ha expirado."
      case "Error 041: Code not found":
        return "Código promocional invalido."
      case "Error 042: Daily views limit reached":
        return "Ya has superado el limite diario de vistas en este video, prueba con otro."
      case "Error 043: Waiting time for the next video playback":
        return "Parece que recientemente viste este video, vuelve a intentarlo más tarde."
      case "Error 044: The FCM has already been added previously":
        return "El FCM ya se agrego previamente."
      case "Error 045: To modify the school, career or institution, the three parameters must be entered":
        return "Para modificar la escuela, carrera o institución, se deben ingresar los tres parámetros"
      case "Error 046: el emoji no está dentro de las opciones aceptadas":
        return "El emoji seleccionado no está dentro de las opciones aceptadas"
      case "Error 047: The uid of the payment is necessary":
        return "El uid del pago es necesario"
      case "Error 048: An error occurred when consulting with MercadoPago":
        return "Ocurrió un error al consultar con MercadoPago"
      case "Error 049: Package not found":
        return "Paquete no encontrado"
      case "Error 050: Uid order it's required":
        return "Se requiere el uid del pedido"
      case "Error 051: The order does not match the user":
        return "El pedido no coincide con el usuario"
      case "Error 052: The payment date cannot be before the preferred date":
        return "la fecha de pago no puede ser anterior a la fecha de preferencia"
      case "Error 053: Can't delete third-party documents":
        return "No se pueden eliminar documentos de terceros"
      case "Error 054: Movement not found":
        return "Movimiento no encontrado"   
      case "Error 055: The yeahcoins in the file have already been spent, cannot be removed":
        return "Los yeahcoins en el archivo ya se han gastado, no se pueden eliminar el archivo"           
      case "Error 056: Cannot delete files that have been downloaded":
        return "No se pueden eliminar los archivos que han recibido descargas"   
      case "Error 057: Category not found":
        return "Categoria no encontrada"   
      case "Error 058: The maximum number of files per day was reached":
        return "Se alcanzó el número máximo de archivos por día"          
      case "Error 059: The subject does not belong to the school":
        return "La materia no pertenece a la escuela";
      
      case "Field Error 001: Title can only be a string":
        return "El titulo solo puede contener texto";
      case "Field Error 002: Title must have a minimum of 3 characters":
        return "El titulo debe tener al menos 3 caracteres";
      case "Field Error 003: Title must have a maximum of 50 characters":
        return "El titulo debe tener maximo 50 caracteres";
      case "Field Error 004: Title can only have letters, numbers, and spaces, special characters are not allowed":
        return "El titulo debe tener solo letras, no se permiten caracteres especiales";
      case "Field Error 005: Title is required":
        return "El titulo es obligatorio";
      case "Field Error 006: Price can only be a number":
        return "El precio solo puede ser un numero";
      case "Field Error 007: Price must be a integer number":
        return "El precio solo puede ser un numero";
      case "Field Error 008: Price must be a positive number":
        return "El precio debe ser un numero positivo y entero";
      case "Field Error 009: Price must be between 60 and 1300 yeahpoints":
        return "El precio debe ser un numero entre 60 y 1300";
      case "Field Error 010: Price is required":
        return "El precio es obligatorio";
      case "Field Error 011: Description can only be a string":
        return "La descripcion solo puede contener texto y numeros";
      case "Field Error 012: Description must have a minimum of 3 characters":
        return "La descripcion debe tener minimo 3 caracteres";
      case "Field Error 013: Description must have a maximum of 300 characters":
        return "La descripcion debe tener minimo 300 caracteres";
      case "Field Error 014: Description can only have letters, numbers, and spaces, special characters are not allowed":
        return "La descripcion solo puede tener letras y numeros, los caracteres especiales no estan permitidos";
      case "Field Error 015: Description is required":
        return "La descripcion es obligatoria";
      case "Field Error 016: uidType can only be a string":
        return "El tipo solo puede ser texto";
      case "Field Error 017: uidType lenght not valid":
        return "Longitud de tipo no valida";
      case "Field Error 018: uidType is required":
        return "El tipo es obligatorio";
      case "Field Error 019: uidTeacher can only be a string":
        return "El profesor solo puede ser texto";
      case "Field Error 020: uidTeacher lenght not valid":
        return "El longitud del profesor no es valida";
      case "Field Error 021: uidTeacher is required":
        return "El profesor es obligatorio";
      case "Field Error 022: uidSchool can only be a string":
        return "La escuela solo puede ser texto";
      case "Field Error 023: uidSchool lenght not valid":
        return "La longitud de la escuela no es valida";
      case "Field Error 024: uidSchool is required":
        return "La escuela es obligatoria";
      case "Field Error 025: uidCareer can only be a string":
        return "La carrera solo puede ser un texto";
      case "Field Error 026: uidCareer lenght not valid":
        return "La longitud de la carrera no es valida";
      case "Field Error 027: uidCareer is required":
        return "La carrera es obligatoria";
      case "Field Error 028: uidSubject can only be a string":
        return "La materia solo puede ser un texto";
      case "Field Error 029: uidSubject lenght not valid":
        return "La longitud de la materia no es valida";
      case "Field Error 030: uidSubject is required":
        return "La materia es obligatoria";
      case "Field Error 031: uid can only be a string":
        return "La identificador solo puede ser un texto";
      case "Field Error 032: uid lenght not valid":
        return "La longitud del indentificador no es valida";
      case "Field Error 033: uid is required":
        return "El identificador es obligatorio";
      case "Field Error 034: Uid can only have letters and numbers, special characters are not allowed":
        return "La identificador solo puede ser un texto";
      case "Field Error 035: code can only be a string":
        return "La codigo solo puede ser un texto";
      case "Field Error 036: code lenght not valid":
        return "La longitud del codigo no es valida";
      case "Field Error 037: code is required":
        return "El codigo es obligatorio";
      case "Field Error 038: code can only have letters and numbers, special characters are not allowed":
        return "La codigo solo puede ser un texto";
      case "Field Error 039: FCM can only be a string":
        return "FCM solo puede ser un texto";
      case "Field Error 040: FCM lenght not valid":
      case "Field Error 041: FCM lenght not valid":
        return "La longitud del FCM no es valida";
      case "Field Error 042: Star only can be a number":
        return "La calificacion solo puede ser numerica";
      case "Field Error 043: Star is required":
        return "La calificacion es obligatoria";
      case "Field Error 044: Like can only be a boolean":
        return "El me gusta solo puede ser un estado";
      case "Field Error 045: Dislike can only be a boolean":
        return "El no me gusta solo puede ser un estado";
      case "Field Error 046: Message only can be a string":
        return "Los mensajes solo pueden ser texto";
      case "Field Error 047: Comment can only be a string":
        return "El comentario solo puede ser un texto";
      case "Field Error 048: Comment must have a minimum of 1 characters":
        return "El comentario debe tener al menos 1 caracter";
      case "Field Error 049: Comment must have a maximum of 280 characters":
        return "El comentario debe tener maximo 280 caracteres";
      case "Field Error 050: Comments can only have letters, numbers, and spaces, special characters are not allowed":
        return "El comentario solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 051: Comment is required":
        return "El comentario es obligatorio";
      case "Field Error 052: Emoji file can only be a string":
        return "El emoji solo puede ser texto";
      case "Field Error 053: Emoji lenght not valid":
        return "El emoji no es valido";
      case "Field Error 054: Nickname can only be a string":
        return "El nickname solo puede ser texto";
      case "Field Error 055: Nickname must have a minimum of 1 characters":
        return "El nickname debe tener minimo un caracter";
      case "Field Error 056: Nickname must have a maximum of 25 characters":
        return "El nickname debe tener maximo 25 caracteres";
      case "Field Error 057: Nickname can only have letters, numbers, and spaces, special characters are not allowed":
        return "El nickname solo puede tener texto, caracteres especiales no son permitidos";
      case "Field Error 058: School can only have letters and numbers, special characters are not allowed":
        return "La escuela puede tener solo letas y numeros los caracteres especiales no estan permitidos";
      case "Field Error 059: Institution file can only be a string":
        return "La institucion solo puede tener un texto";
      case "Field Error 060: Institution file lenght not valid":
        return "La longitud de la institucion no es valida";
      case "Field Error 061: Institution can only have letters and numbers, special characters are not allowed":
        return "La institucion solo puede tener letras y numeros, los caracteres especiales no son permitidos";
      case "Field Error 062: Career can only have letters and numbers, special characters are not allowed":
        return "La carrera solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 063: Subject can only have letters and numbers, special characters are not allowed":
        return "La materia solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 064: Teacher can only have letters and numbers, special characters are not allowed":
        return "El profesor solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 065: OrderByField can only be a string":
        return "El campo de orden solo puede ser un texto";
      case "Field Error 066: OrderByField must have a minimum of 1 characters":
        return "El campo del orden debe tener al menos un caracter";
      case "Field Error 067: OrderByField must have a maximum of 30 characters":
        return "El campo del orden debe tener maximo 30 caracteres";
      case "Field Error 068: OrderByField can only have letters, numbers, and spaces, special characters are not allowed":
        return "El campo de orden solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 069: OrderByDir can only be a string":
        return "La direccion de orden solo puede ser un texto";
      case "Field Error 070: OrderByDir must have a minimum of 3 characters":
        return "La direccion del orden debe tener al menos 3 caracteres";
      case "Field Error 071: OrderByDir must have a maximum of 4 characters":
        return "La direccion del orden debe tener maximo 4 caracteres";
      case "Field Error 072: OrderByDir can only have letters, numbers, and spaces, special characters are not allowed":
        return "La direccion de orden solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 073: Score can only be a number":
        return "La calificacion solo puede ser un numero";
      case "Field Error 074: Score lenght not valid":
        return "La longitud de la calificaion no es valida";
      case "Field Error 075: Format can only be a string":
        return "El formato solo puede ser un texto";
      case "Field Error 076: Format lenght not valid":
        return "La longitud del formato no es valida";
      case "Field Error 077: Format can only have letters, numbers, and spaces, special characters are not allowed":
        return "El formato solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 078: Search can only be a string":
        return "El campo de busqueda solo puede tener texto";
      case "Field Error 079: Search lenght not valid":
        return "La longitud del campo de busqueda no es valida";
      case "Field Error 080: Search can only have letters, numbers, and spaces, special characters are not allowed":
        return "El campo de busqueda solo puede tener letras y numeros, caracteres especiales no son permitidos";
      case "Field Error 081: Subject can only be a string":
        return "La materia solo puede ser un texto";
      case "Field Error 082: subject length not valid":
        return "La longitud de la materia no es valida";
      case "Field Error 083: Teacher can only be a string":
        return "El profesor solo puede ser un texto";
      case "Field Error 084: teacher length not valid":
        return "La longitud del profesor no es valida ";

      case "The user account has been disabled by an administrator.":
        return "Tu cuenta se encuentra temporalmente fuera de servicio por incumplir las politicas o terminos y condiciones de yeahnotes, si consideras que esto es un error por favor ponte en contacto por medio de nuestros medios oficiales."
      default:
        console.log(error);
        return "Algo salio mal, intentalo de nuevo."
    }
  }

  showError(error:string, duration:number = 5) {
    const message = this.getMessageError(error);
    this._snackBar.open(message, null, {
      duration: duration * 1000
    });
  }

}