<div class="files-list-container">
    <div class="files-list-table">
        <div class="files-items" *ngIf="dataSource && dataSource.length">
            <div class="file-item" (click)="clickElement(element.uid)" *ngFor="let element of dataSource">
                <div class="flex-container">
                    <div class="img-container">
                        <img class="format-img" src="{{getImage(element.format)}}" alt="">
                    </div>
                    <div class="data-container">
                        <div class="file-name ellipsis">{{element.title}} </div>
                        <div class="file-subject ellipsis">{{element.subject.name}} </div>
                        <div class="file-teacher ellipsis">{{element.teacher.name}} </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="author">
                        <div class="author-name">
                            De {{element.author.nickname}}
                        </div>
                        <div class="btn-score mat-icon-container" *ngIf="element.score">
                            <mat-icon>star</mat-icon> {{element.score}}
                        </div>
                    </div>
                    <div class="score-btns">
                        <div class="btn-score mat-icon-container">
                            <mat-icon class="score-icon">thumb_up</mat-icon> {{element.likes}}
                        </div>
                        <div class="btn-score mat-icon-container">
                            <mat-icon class="score-icon">thumb_down</mat-icon> {{element.dislikes}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>