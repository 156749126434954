<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="false">
    <div class="container">
        <div class="header">
            <div class="title">
                ¿Cómo funciona la calificación de estrellas?
            </div>
            <p class="paragraph">
                YeahNotes utiliza un sistema de calificaciones por estrellas donde los usuarios califican los archivos que descargaron segun su calidad y utilidad. Tu calificación es un promedio de todas las calificaciones recibidas de tus archivos subidos a la plataforma.
            </p>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            ¿Cuándo verás por primera vez tu calificación promedio?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-collapse">Comenzarás a ver tu calificación una vez que recibas valoraciones de tus archivos.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            ¿Para que sirve la calificación de estrellas?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-collapse">
                        La calificación de estrellas nos ayuda a entender que tan utiles son los archivos que subes para la comunidad. 
                        Cada vez que alguien vea alguno de tus archivos podra ver cual es tu calificación promedio para ayudarlo a decidir si vale la pena descargar tu archivo. 
                        Adicionalmente tu calificación de estrellas es un factor importante para subir de categoria y tener mejores beneficios como el obtener yeahcoins de manera más fácil y rapida.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            ¿Qué pasá si recibo una calificación baja?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-collapse ">
                        Al inicio será normal ver que tu calificación sube o baja debido a que tendras pocas calificaciones de tus archivos, asi que no te preocupes una calificación baja no afectara tu promedio de manera significativa.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            ¿Quien puede calificarme?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-collapse ">
                        Solo los usuarios que hayan descargado alguno de tus archivos podran emitir una calificación sobre dicho archivo, la cual será tomada en cuenta para tu promedio de calificacion de estrellas.
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="body">
            <div class="title">
                ¿Cómo funcionan las categorias?
            </div>
            <p class="paragraph">
                YeahNotes utiliza un sistema de categorias con el cual clasifica a los usuarios. Entre mejor sea tu categoria más yeahcoins ganaras al subir archivos y al recibir descargas.
                Actualmente existen 5 tipos de categorias diferentes en yeahnotes.
            </p>
            <div class="square-elements">
                <div class="square">
                    <img class="img-category" src="/assets/img/categories/hierro.svg" alt="">
                    <div class="text-category">
                        Hierro
                    </div>
                </div>
                <div class="square">
                    <img class="img-category" src="/assets/img/categories/bronce.svg" alt="">
                    <div class="text-category">
                        Bronce
                    </div>
                </div>
                <div class="square">
                    <img class="img-category" src="/assets/img/categories/plata.svg" alt="">
                    <div class="text-category">
                        Plata
                    </div>
                </div>
                <div class="square">
                    <img class="img-category" src="/assets/img/categories/oro.svg" alt="">
                    <div class="text-category">
                        Oro
                    </div>
                </div>
                <div class="square">
                    <img class="img-category" src="/assets/img/categories/rubi.svg" alt="">
                    <div class="text-category">
                        Rubí
                    </div>
                </div>
            </div>
            <div class="title top30">
                ¿Quieres subir de categoria?
            </div>
            <p class="paragraph">
                Para subir de categoria debes cumplir con varios requisitos dependiendo de la categoria a la que quieras subir. <br>
                <span class="link" (click)="valueMySelf()">Realiza una valoración para saber los requisitos que te faltan por cumplir.</span>
            </p>
        </div>
    </div>
</app-internal-component>