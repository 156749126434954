import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";

@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    constructor(
        private angularFirebaseMessaging: AngularFireMessaging,
        private authService: AuthService) 
    {
        this.angularFirebaseMessaging.messages.subscribe(
            (_messaging: AngularFireMessaging) => {
            //console.log("new message received. ", _messaging);
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        });
    }

    requestPerm() {
        this.angularFirebaseMessaging.requestToken.subscribe((token) => {
            const currentFCM = this.authService.getUser().fcm || null;
            if(token && (!currentFCM || currentFCM !== token)) {
                this.authService.changeFCM(token).subscribe(() => {
                    this.authService.setFCM(token);
                });
            }
        },
        (err) => {
            //console.log("No Permission " + err);
        });
    }

    receiveMessage() {
        this.angularFirebaseMessaging.messages.subscribe(
            (payload) => {
              // console.log("new message received. ", payload);
              this.currentMessage.next(payload);
        });
    }
}