<div class="download-file">

    <div *ngIf="format === 'PP'" (click)="downloadFile()" class="fileContainer pp">
        <img src="/assets/img/downloadPP.svg" alt="file to buy">
    </div>

    <div *ngIf="format === 'DOC'" (click)="downloadFile()" class="fileContainer word">
        <img src="/assets/img/downloadWord.svg" alt="file to buy">
    </div>

    <div *ngIf="format === 'EXCEL'" (click)="downloadFile()" class="fileContainer excel">
        <img src="/assets/img/downloadE.svg" alt="file to buy">
    </div>

    <div *ngIf="format === 'ZIP'" (click)="downloadFile()" class="fileContainer zip">
        <img src="/assets/img/downloadZip.svg" alt="file to buy">
    </div>
    
</div>