import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-buttons",
  templateUrl: "./dialog-buttons.component.html",
  styleUrls: ["./dialog-buttons.component.scss"]
})
export class DialogButtonsComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DialogButtonsComponent>) {}

  dialogConfig: MatDialogConfig;
  buttonOptions: [] = [];
  title: string;
  textBody: string;
  textButton: string;

  ngOnInit(): void {
    if (this.dialogConfig && this.dialogConfig.data) {
      const data = this.dialogConfig.data;
      this.buttonOptions = data.options;
      this.title = data.title || undefined;
      this.textBody = data.textBody || undefined;
    }
  }

  onButtonClicked(value: any) {
    this.dialogRef.close(value);
  }
}
