<div class="filters" [ngClass]="{'all-height': !mobileVersion}">
    <div *ngIf="mobileVersion" class="filters-header">
        <button class="hfilters-header-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <button class="hfilters-header-button" (click)="clean()">Resetear</button>
    </div>
    <div class="filters-body" [ngClass]="{'padding-desktop': !mobileVersion}">
        <div class="order-content" *ngIf="preselectedOrderOption && preselectedOrderOption.length">
            <h3 class="no-mg">Ordernar por: </h3>
            <app-checkbox-chips-list [chips]="orderOptions" [preSelected]="preselectedOrderOption" (onSelectionChange)="onOrderChange($event)"></app-checkbox-chips-list>
        </div>
        <mat-divider></mat-divider>
        <div class="filter-section">
            <h3 class="no-mg">Escuela: </h3>
            <ng-select *ngIf="canEditSchool" placeholder="Mi escuela" class="custom" [items]="schools" [(ngModel)]="schoolSelected" (ngModelChange)="onSchoolSelected($event)" bindLabel="label"></ng-select>
            <div *ngIf="!canEditSchool" class="row">
                <p>{{schoolToShow}}</p>
                <button class="edit-button" (click)="changeCanEditSchool()">            
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
        <div class="filter-section">
            <h3 class="no-mg">Carrera: </h3>
            <ng-select placeholder="Todas las materias" class="custom" [items]="careers" [(ngModel)]="careerSelected" (ngModelChange)="onCareerSelected($event)" notFoundText="Carrera no encontrada" bindLabel="name"></ng-select>
        </div>
        <div class="filter-section">
            <h3 class="no-mg">Materia: </h3>
            <ng-select placeholder="Todas las materias" class="custom" [items]="subjects" [(ngModel)]="subjectSelected" [addTag]="false" addTagText="Agregar Materia:" bindLabel="name"></ng-select>
        </div>
        <div class="filter-section">
            <h3 class="no-mg">Profesor: </h3>
            <ng-select placeholder="Todos los profesores" class="custom" [items]="teachers" [(ngModel)]="teacherSelected" [addTag]="false" addTagText="Agregar Docente:" bindLabel="teacher"></ng-select>
        </div>
        <div class="filter-section" *ngIf="typeSelected != undefined && typeOptions && typeOptions.length">
            <h3 class="no-mg">Tipo: </h3>
            <app-checkbox-chips-list *ngIf="typeOptions" [preSelected]="typeSelected" [chips]="typeOptions" [multipleChoice]="true" (onSelectionChange)="onTypeSelected($event)"></app-checkbox-chips-list>
        </div>
        <div class="filter-section last-section" *ngIf="formatSelected != undefined && formatOptions && formatOptions.length">
            <h3 class="no-mg">Formato: </h3>
            <app-checkbox-chips-list *ngIf="formatOptions" [chips]="formatOptions" [preSelected]="formatSelected" [multipleChoice]="true" (onSelectionChange)="onFormatSelected($event)"></app-checkbox-chips-list>
        </div>
        <div class="filter-section last-section">
            <div *ngIf="false">
                <h3 class="no-mg">Estrellas: <span *ngIf="scoreSelected">{{scoreSelected}} - 5</span></h3>
                <app-stars [preselectedStar]="scoreSelected" (onValueChange)="onScoreSelected($event)"></app-stars>    
            </div>
         </div>
        <div class="loader-container" *ngIf="!scoreSelected || formatSelected === undefined || typeSelected === undefined">
            <app-loader [color]="'green'"></app-loader>
        </div>
    </div>
    <div *ngIf="mobileVersion" class="filters-buttons">
        <button mat-raised-button [disabled]="!scoreSelected || formatSelected === undefined || typeSelected === undefined" class="acition-button" color="primary" (click)="onFilterClick()">Buscar</button>
    </div>

    <div *ngIf="!mobileVersion" class="button-desktop">
        <button mat-raised-button [disabled]="!scoreSelected || formatSelected === undefined || typeSelected === undefined" class="button-search" color="primary" (click)="onFilterClick()">Buscar</button>
    </div>
</div>