<app-internal-component [showFooter]="false" [showHeader]="true" [isLoading]="isLoading">
    <div class="main-container-profile" *ngIf="!settings">
        <app-header-profile (onViewChange)="onViewChange($event)" (onSettings)="showSettings()" (onOpenCategory)="valueMySelf()" [currentView]="currentView" [identity]="currentUser.identity" [emoji]="currentEmoji"></app-header-profile>
        <div class="body-container-parts">
            <div class="profile-left-menu">
                <div class="emoji-profile"> {{currentEmoji}} </div>
                <div class="data-row">
                    <div class="nickname">
                        {{currentUser.nickname}}
                    </div>
                    <div (click)="valueMySelf()" *ngIf="currentUser.category" class="gategory">
                        <img class="category-img" src="/assets/img/categories/{{currentUser.category}}.svg" alt="">
                        <span class="cat-text">{{currentUser.category}}</span>
                    </div>
                    <div routerLink="/how-ratings-work" *ngIf="currentUser.score" class="stars">
                        <span class="black pointer">Calificación: </span> 
                        <mat-icon class="right-icon pointer">star</mat-icon> {{currentUser.score}}
                    </div>
                    <div class="yc" routerLink="/yeahcoins"><img class="yeahcoin-icon-profile" src="/assets/img/yeahcoin.svg" alt="">{{currentUser.yeahcoins}} YeahCoins</div>
                    <div class="edit-button">
                        <button class="edit-proile-button" [ngClass]="{'ipnBG': currentUser.identity === 'IPN', 'unamBG': currentUser.identity === 'UNAM'}" routerLink="/profile/settings">
                            Configurar Perfil
                        </button>
                    </div>
                </div>
            </div>
            <div class="profile-right-menu">
                <div class="files-profile" *ngIf="currentView === 'files'">
                    <h2 class="subtitle-settings">Archivos Subidos</h2>
                    <app-files (onGoToFile)="goToFile($event)"></app-files>
                </div>
                <div class="downloads-profile" *ngIf="currentView === 'downloads'">
                    <h2 class="subtitle-settings">Archivos Descargados</h2>
                    <app-downloads (onGoToFile)="goToFile($event)"></app-downloads>
                </div>
                <div class="transactions" *ngIf="currentView === 'transactions'">
                    <h2 class="subtitle-settings">Movimientos y Transacciones</h2>
                    <app-movements></app-movements>
                </div>
                <div class="notifications-profile" *ngIf="currentView === 'notifications'">
                    <h2 class="subtitle-settings">Notificaciones</h2>
                    <app-notifications></app-notifications>
                </div>
                <div class="settings-profile" *ngIf="currentView === 'settings'">
                    <h2 class="subtitle-settings">Configurar Perfil</h2>
                    <app-settings class="d-version" [showCloseButton]='false'></app-settings>
                </div>
            </div>
        </div>
    </div>
    <div class="main-container-profile" *ngIf="settings">
        <app-settings (onCloseSettings)="closeSettings()"></app-settings>
    </div>
</app-internal-component>