import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/profile/profile.service';
import { IEvaluateMe } from '../models/models';

@Component({
  selector: 'app-pay-modal',
  templateUrl: './pay-modal.component.html',
  styleUrls: ['./pay-modal.component.scss']
})
export class PayModalComponent implements OnInit {

  public type:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PayModalComponent>,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if(this.data && this.data.type) {
      this.type = this.data.type;
    } 
  }

  goToTransactions() {
    this.router.navigate(["/profile/transactions"]);
    this.dialogRef.close()
  }

  close() {
    this.dialogRef.close()
  }

}
