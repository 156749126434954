<div class="item" *ngFor="let item of files | async" (click)="goToFile(item)">
    <div class="body-item">
        <div class="flex-container">
            <div class="img-container">
                <img class="format-img" src="{{getImage(item)}}" alt="">
            </div>
            <div class="data-container">
                <div class="file-name ellipsis">{{item.title}} </div>
                <div class="file-subject ellipsis">{{item.subject.name}} </div>
                <div class="file-teacher ellipsis">{{item.teacher.name}} </div>
            </div>
        </div>
        <div class="footer">
            <div class="author">
                <span class="date">{{item.timestamp}}</span>
                <div class="pleca">|</div>
                <div class="downloads">
                    <mat-icon class="gray-icon">cloud_download</mat-icon> {{item.downloadsNumber}}
                </div>
                <div *ngIf="item.score" class="pleca">|</div>
                <div *ngIf="item.score" class="rate">
                    <mat-icon class="green-icon">star</mat-icon> 
                    <span class="mat-text">{{item.score}}</span>
                </div>

            </div>
            <div class="data">
                <p class="price">En venta por: {{item.price}} yeahcoins</p>
                <div class="score-btns">
                    <div class="btn-score"><mat-icon class="thumb-icon">thumb_up</mat-icon> {{item.likes}}</div>
                    <div class="btn-score"><mat-icon class="thumb-icon">thumb_down</mat-icon>  {{item.dislikes}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()"></div>
<div *ngIf="!finished" class="item noMore">
    <app-loader [width]="'auto'" [height]="'40px'" [color]="'green'"></app-loader>
</div>
<div class="item noMore" *ngIf="finished">
    <div class="body-item">
        No hay más archivos por ahora.
    </div>
</div>