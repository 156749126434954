import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent implements OnInit {
  @Input("width") width: string = "100px";
  @Input("height") height: string = "100px";
  @Input("color") color: string = "white";

  constructor() {}

  ngOnInit(): void {}
}
