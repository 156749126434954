<div class="settings-body-content">
    <div class="menu-settings-content">
        <div class="body-settings" *ngIf="viewLevel===0">
            <mat-list>
                <div mat-subheader class="header-settings">Configuraciones
                    <button *ngIf="showCloseButton" class="close-btn" (click)="closeSettings()">
                        <mat-icon class="right-icon">close</mat-icon>
                    </button>
                </div>
                <mat-list-item (click)="changeView(1,'emoji')">
                    <mat-icon mat-list-icon>emoji_emotions</mat-icon>
                    <div mat-line>Cambiar emoji</div>
                    <mat-icon class="right-icon">arrow_forward_ios</mat-icon>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="changeView(1,'school')">
                    <mat-icon mat-list-icon>school</mat-icon>
                    <div mat-line>Cambiar escuela o Carrera</div>
                    <mat-icon class="right-icon">arrow_forward_ios</mat-icon>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="changeView(1,'nickname')">
                    <mat-icon mat-list-icon>drive_file_rename_outline</mat-icon>
                    <div mat-line>Editar nickname</div>
                    <mat-icon class="right-icon">arrow_forward_ios</mat-icon>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="changeView(1,'code')">
                    <mat-icon mat-list-icon>local_offer</mat-icon>
                    <div mat-line>Canjear Código Promocional</div>
                    <mat-icon class="right-icon">arrow_forward_ios</mat-icon>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="changeView(1,'notifications')">
                    <mat-icon mat-list-icon>notifications</mat-icon>
                    <div mat-line>Configurar notificaciones</div>
                    <mat-icon class="right-icon">arrow_forward_ios</mat-icon>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="changeView(1,'faqs')">
                    <mat-icon mat-list-icon>help_center</mat-icon>
                    <div mat-line>FAQ's</div>
                    <mat-icon class="right-icon">arrow_forward_ios</mat-icon>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item (click)="changeView(1,'cancelAccount')">
                    <mat-icon mat-list-icon>delete</mat-icon>
                    <div mat-line>Dar cuenta de baja</div>
                    <mat-icon class="right-icon">arrow_forward_ios</mat-icon>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="body-settings slide" *ngIf="viewLevel===1" style="display: block" [@return]>
            <mat-list>
                <div mat-subheader class="header-settings">Configuraciones
                    <button class="close-btn" (click)="changeView(0)">
                        <mat-icon class="right-icon">chevron_left</mat-icon> Regresar
                    </button>
                </div>
            </mat-list>
            <div class="settings-body-content" *ngIf="view === 'emoji'">
                <p class="instruction">Elige tu emoji favorito</p>
                <app-checkbox-chips-list [chips]="chips" [isEmoji]="true" [preSelected]="preselectedEmoji" (onSelectionChange)="onIconSelected($event)"></app-checkbox-chips-list>
                <button class="ok" (click)="saveIcon()">
                    <app-loader *ngIf="loader" [width]="'auto'" [height]="'20px'"></app-loader>
                    <span *ngIf="!loader">Guardar</span>    
                </button>
            </div>
            <div class="settings-body-content" *ngIf="view === 'school'">
                <div class="select-content">
                    <h3 class="no-mg">Institución: </h3>
                    <ng-select placeholder="Seleccionar Institución" class="custom" [items]="institutions" [(ngModel)]="institutionSelected" notFoundText="Institución no encontrada" (ngModelChange)="onInstitutionSelected($event)" bindLabel="label"></ng-select>
                    <div *ngIf="isDataTouched && !institutionSelected" class="error-message">
                        <p class="error">Debes indicar la institución a la que pertenece.</p>
                    </div>
                </div>
                <div *ngIf="institutionSelected" class="select-content">
                    <h3 class="no-mg">Escuela: </h3>
                    <ng-select placeholder="Seleccionar Escuela" class="custom" [items]="schools" [(ngModel)]="schoolSelected" bindLabel="label" notFoundText="Escuela no encontrada" (ngModelChange)="onSchoolSelected($event)"></ng-select>
                    <div *ngIf="isDataTouched && !schoolSelected" class="error-message">
                        <p class="error">Debes indicar la escuela a la que pertenece.</p>
                    </div>
                </div>
                <div *ngIf="schoolSelected" class="select-content">
                    <h3 class="no-mg">Carrera: </h3>
                    <ng-select placeholder="Seleccionar Carrera" class="custom" [items]="careers" [(ngModel)]="careerSelected" bindLabel="name" notFoundText="Carrera no encontrada"></ng-select>
                    <div *ngIf="isDataTouched && !careerSelected" class="error-message">
                        <p class="error">Debes indicar la carrera a la que pertenece.</p>
                    </div>
                </div>
                <button class="ok padding-max" (click)="saveSchool()">
                    <app-loader *ngIf="loader" [width]="'auto'" [height]="'20px'"></app-loader>
                    <span *ngIf="!loader">Guardar</span>    
                </button>
            </div>
            <div class="settings-body-content" *ngIf="view === 'nickname'">
                <input type="text" class="input--block" [(ngModel)]="nickname" placeholder="Nickname">
                <p *ngIf="!nickname || !nickname.length" class="error">El nickname es obligatorio</p>
                <p *ngIf="hasNicknameError" class="error">El nickname no puede tener caracteres especiales y debe tener una longitud entre 4 y 15 caracteres.</p>
                <button class="ok" (click)="saveNickname()">
                    <app-loader *ngIf="loader" [width]="'auto'" [height]="'20px'"></app-loader>
                    <span *ngIf="!loader">Guardar</span>  
                </button>
            </div>
            <div class="settings-body-content" *ngIf="view === 'code'">
                <input type="text" class="input--block" [(ngModel)]="code" placeholder="Canjear Código">
                <p *ngIf="!code || !code.length" class="error">El código es obligatorio</p>
                <p *ngIf="hasCodeError" class="error">El código no puede tener caracteres especiales a excepción del @.</p>
                <button class="ok" (click)="saveCode()">
                    <app-loader *ngIf="loader" [width]="'auto'" [height]="'20px'"></app-loader>
                    <span *ngIf="!loader">Canjear</span>  
                </button>
            </div>
            <div class="settings-body-content" *ngIf="view === 'notifications'">
                <div class="notifications-body">
                    <div class="row-notification">
                        <div class="text">
                            Recibir notificaciones de mis archivos.
                        </div>
                        <div class="switch">
                            <mat-slide-toggle [color]="'primary'" [(ngModel)]="nFiles"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="notifications-body">
                    <div class="row-notification">
                        <div class="text">
                            Recibir notificaciones de mis descargas.
                        </div>
                        <div class="switch">
                            <mat-slide-toggle [color]="'primary'" [(ngModel)]="nDown"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="notifications-body">
                    <div class="row-notification">
                        <div class="text">
                            Recibir notificaciones de promociones y eventos especiales.
                        </div>
                        <div class="switch">
                            <mat-slide-toggle [color]="'primary'" [(ngModel)]="nProm"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="notifications-body">
                    <div class="row-notification">
                        <div class="text">
                            Recibir notificaciones personalizadas:
                        </div>
                        <div class="switch">
                            <mat-slide-toggle [color]="'primary'" [(ngModel)]="nCust"></mat-slide-toggle>
                        </div>
                    </div>
                    <p class="instructions">
                        Selecciona a tus profesores y/o asignaturas y te enviaremos una notificación cuando se suba algun archivo relacionado para que seas el primero en tenerlo.
                    </p>
                    <label class="labelSelect">Selecciona Profesores</label>
                    <p *ngIf="!schoolSelected" class="error">Debes configurar tu escuela para poder ver los profesores</p>
                    <ng-select class="multiselect-notifications" [items]="teachers" [disabled]="!schoolSelected" [multiple]="true" [hideSelected]="true" bindLabel="teacher" placeholder="Seleccionar docentes" notFoundText="Docente no encontrado" [(ngModel)]="teachersSelected">
                    </ng-select>
                    <label class="labelSelect">Selecciona Asignaturas</label>
                    <p *ngIf="!careerSelected" class="error">Debes configurar tu carrera para poder ver las asignaturas</p>
                    <ng-select class="multiselect-notifications" [items]="subjects" [disabled]="!careerSelected" [multiple]="true" [hideSelected]="true" bindLabel="name" placeholder="Seleccionar docentes" notFoundText="Docente no encontrado" [(ngModel)]="subjectsSelected">
                    </ng-select>
                    <button class="ok" (click)="savePreferences()">
                        <app-loader *ngIf="loader" [width]="'auto'" [height]="'20px'"></app-loader>
                        <span *ngIf="!loader">Guardar</span>  
                    </button>
                </div>
            </div>
            <div class="settings-body-content" *ngIf="view === 'faqs'">
                <div class="faqs-section">
                    <p class="question">¿Qué son los yeahcoins?</p>
                    <p class="answer">Son la moneda oficial de la plataforma, con ellos podras descargar los archivos disponibles.</p>
                    <p class="question">¿Qué puedo hacer si el archivo que descargue no es lo que promete?</p>
                    <p class="answer">Todos los archivos en la plataforma son subidos por estudiantes como tú, es reponsabilidad de cada estudiante subir archivos utiles que den valor a la plataforma, si te encontraste algun archivo que no corresponde con su descripción
                        puedes reportarlo. Revisaremos el caso y si es necesario te devolveremos tus yeahcoins invertidos. </p>
                    <p class="question">¿Puedo pedir una devolución de yeahcoins?</p>
                    <p class="answer">No existe un apartado de devoluciones, sin embargo si no estas conforme con algun archivo puedes reportarlo explicando las razones y en caso de ser necesario realizaremos una devolucion de tus yeahcoins.</p>
                    <p class="question">¿Los yeahcoins tiene caducidad?</p>
                    <p class="answer">Los yeahcoins no tienen ninguna caducidad ni limite de compra.</p>
                    <p class="question">¿Puedo cambiar mis yeahcoins por dinero real?</p>
                    <p class="answer">Puedes comprar yeahcoins con dinero real sin embargo no puedes intercambiar yeahcoins por dinero.</p>
                    <p class="question">¿Como recibo yeahcoins por mis archivos?</p>
                    <p class="answer">Al subir un archivo la plataforma se te depositaran los yeahcoins asignados como precio de forma automatica.</p>
                    <p class="question">¿Recibo yeahcoins cuando descargan mis archivos?</p>
                    <p class="answer">¡Claro!, cada vez que alguien descargue tu archivo recibiras un porcentage de yeahcoins de forma automatica.</p>
                    <p class="question">¿Como se calcula el precio de mis archivos?</p>
                    <p class="answer">Al subir un archivo a la plataforma se te pregunta un precio sugerido, dependiendo de las caracteristicas del archivo, tu antiguedad, numero de archivos, descargas y calificaciones, la plataforma asigna un precio a tu archivo y te
                        deposita el numero de yeahcoins correspondientes a tu cuenta.</p>
                </div>
            </div>
            <div class="settings-body-content" *ngIf="view === 'cancelAccount'">
                <p>El dar de baja tu cuenta quedara inactiva, no podras entrar con tu usuario sin embargo los archivos que hayas subido permaneceran disponibles, a menos que sean bloqueados por yeahnotes.</p>
                <button class="ok" (click)="closeAccount()">Cerrar cuenta</button>
            </div>
        </div>
    </div>
</div>