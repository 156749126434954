import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toHexStringOfMinLength } from 'pdf-lib/cjs/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorService } from 'src/app/error/error.service';
import { FileService } from 'src/app/file/file.service';
import { ReportFileComponent } from '../report-file/report-file.component';

@Component({
  selector: 'app-rate-file',
  templateUrl: './rate-file.component.html',
  styleUrls: ['./rate-file.component.scss']
})
export class RateFileComponent implements OnInit, OnDestroy {

  public currentRate:number;
  public stars = [
    {id:1, selected:false},
    {id:2, selected:false},
    {id:3, selected:false},
    {id:4, selected:false},
    {id:5, selected:false},
  ];
  public loader:boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private fileService: FileService, 
    private dialogRef: MatDialogRef<ReportFileComponent>,  
    @Inject(MAT_DIALOG_DATA) public data: any,
    private errorService: ErrorService) { }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  selectStar(index:number) {
    this.currentRate = index;
    this.stars.forEach(star => {
      if(index >= star.id) {
        star.selected = true;
      } else {
        star.selected = false;
      }
    });
    console.log(this.currentRate);
  }

  sendRate() {
    if(this.currentRate) {
      this.loader = true;
      this.fileService.addRate(this.data.uidFile, this.currentRate)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loader = false;
        this.dialogRef.close(true);
      }, err=> { 
        console.log(err);
        this.loader = false;
        this.dialogRef.close();
      });
    }
  }

}
