import { FormControl, Validators } from "@angular/forms";

export const emailsAccepted = [
  'outlook.com',
  'yahoo.com.mx',
  'yahoo.com',
  'gmail.com',
  'hotmail.com',
  'icloud.com',
  'aol.com',
  'microsoft.com',
  'live.com.mx'
]

export const LoginValidators = {
  email: [
    "",
    [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ]
  ],
  password: [
    "",
    [Validators.required, Validators.minLength(6), Validators.maxLength(30)]
  ]
};

export const LoginValidatorsMessages = {
  email: {
    required: "Tu correo electronico es obligatorio",
    pattern: "Correo Electronico invalido",
  },
  password: {
    required: "Tu contraseña es obligatoria",
    minLength: "Tu contraseña debe tener minimo 6 caracteres"
  }
};

export const SignUpValidators = {
  email: [
    "",
    [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      isAcceptedMail
    ]
  ],
  nickname: [
    "",
    [Validators.required, Validators.minLength(4), Validators.maxLength(12), removeSpaces]
  ],
  password: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
};

export const SignUpValidatorsMessages = {
  email: {
    required: "Tu correo electronico es obligatorio",
    pattern: "Correo Electronico invalido",
    custom: "El correo ingresado no es valido en yeahnotes, prueba con otro"
  },
  nickname: {
    required: "Tu nickname es obligatorio",
    minLength: "Tu nickname debe tener entre 4 y 12 caracteres",
    maxLength: "Tu nickname debe tener entre 4 y 12 caracteres",
    whitespace: "Tu nickname es obligatorio"
  },
  password: {
    required: "Tu contraseña es obligatoria",
    minLength: "La contraseña debe tener al menos 6 caracteres",
    maxLength: "Tu nickname debe tener entre 6 y 20 caracteres"
  }
};

export const RecoverPasswordValidators = {
  email: [
    "",
    [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ]
  ]
};

export const RecoverPasswordValidatorsMessages = {
  email: {
    required: "Tu correo electronico es obligatorio",
    pattern: "Correo Electronico invalido"
  }
};

export function removeSpaces(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

export function isAcceptedMail(control: FormControl) {
  const email = (control.value || '').trim();
  const arrayMail =  email.split("@");
  if(!arrayMail || !arrayMail[1]) {
    return false;
  } 
  const domain = arrayMail[1];
  if(!emailsAccepted.includes(domain)) {
    return false;
  }
  return true;
}