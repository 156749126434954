import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { host } from '../constant';
import { IVideo, IView } from '../utility/models/models';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  constructor(private http: HttpClient) { }

  currentVideo: IVideo;

  public getVideos(): Observable<Array<IVideo>> {
    const route = `${host}/videos`;
    return this.http.get<Array<IVideo>>(route);
  }

  public addView(videoId:string): Observable<IView> {
    if(videoId) {
      const route = `${host}/views`;
      return this.http.post<IView>(route, { uidVideo: videoId });
    }
  }

  public editView(viewId:string): Observable<Array<IVideo>> {
    if(viewId) {
      const route = `${host}/views/${viewId}`;
      return this.http.put<Array<IVideo>>(route, { uidView: viewId });
    }
  }

  public setCurrentVideo(video:IVideo) {
    this.currentVideo = video;
  }

  public getCurrentVideo() {
    return this.currentVideo;
  }

}