import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-action-icon",
  templateUrl: "./action-icon.component.html",
  styleUrls: ["./action-icon.component.scss"]
})
export class ActionIconComponent implements OnInit {
  @Input("icon") icon: string;
  @Input("status") status: boolean = false;
  @Input("label") label?: string;
  @Input("clickable") clickable?: boolean = true;
  @Input("direction") direction?: string = "vertical";
  @Input("restrictionMessage") restrictionMessage?: string;
  @Input("snackBarTime") durationInSeconds?: number = 2;

  @Output() onStatusChange = new EventEmitter<boolean>();

  constructor(private _snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  clickButton() {
    if (this.clickable) {
      this.status = !this.status;
      this.onStatusChange.emit(this.status);
    } else {
      if (this.restrictionMessage) {
        this._snackBar.open(this.restrictionMessage, null, {
          duration: this.durationInSeconds * 1000
        });
      }
    }
  }
}
