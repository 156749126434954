import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sizeFile"
})
export class SizeFilePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return this.bytesToSize(value);
  }

  bytesToSize(bytes) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    bytes = bytes + 293601.28;
    const k = 1024;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
